import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Logo from "../../../images/logo-dark.svg";
import List from "@mui/material/List";
import * as React from "react";
import SideToolbarListItem from "../../atoms/side-toolbar-item-list/SideToolbarListItem";
import { Auth } from 'aws-amplify';
import { signOutAction } from "../../../redux/features/business/businessActions";
import { useDispatch } from "react-redux";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import WebRoundedIcon from '@mui/icons-material/WebRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Stack from '@mui/material/Stack';

function TopMenuList(props: { layout: 'left' | 'top' }) {
    const navigate = useNavigate();
    const location = useLocation();
    return <Stack
        alignItems="center"
    >
        <Stack mb={props.layout === 'left' ? 10 : ''}>
            <img src={Logo} alt={"Evergrow"} width={'130px'} />
        </Stack>
        <List>
            <SideToolbarListItem
                text='Overview'
                icon={<HomeRoundedIcon />}
                selected={location.pathname === '/dashboard/overview'}
                onClick={() => navigate('/dashboard/overview')}
            />
            <SideToolbarListItem
                text='Business'
                icon={<BusinessRoundedIcon />}
                selected={location.pathname === '/dashboard/business'}
                onClick={() => navigate('/dashboard/business')}
            />
            <SideToolbarListItem
                text='Site'
                icon={<WebRoundedIcon />}
                selected={location.pathname === '/dashboard/site'}
                onClick={() => navigate('/dashboard/site')}
            />
            <SideToolbarListItem
                text='Customers'
                icon={<PersonRoundedIcon />}
                selected={location.pathname === '/dashboard/customers'}
                onClick={() => navigate('/dashboard/customers')}
            />
        </List>
    </Stack>;
}

function BottomMenuList(props: { layout: 'left' | 'top' }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function refreshPage() {
        window.location.reload();
    }

    return <Stack>
        <List>
            <SideToolbarListItem
                text='Help'
                icon={<HelpOutlineRoundedIcon />}
                selected={false}
                onClick={() => window.open("https://askevergrow.notion.site/askevergrow/Evergrow-Help-Center-371947896df9464fa062693468f60465", "_blank")}
            />
            <SideToolbarListItem
                text='Sign out'
                icon={<LogoutRoundedIcon />}
                selected={false}
                onClick={() => Auth.signOut({ global: true })
                    .then(() => {
                        dispatch(signOutAction())
                        navigate('/')
                    })
                    .then(() => refreshPage())}
            />
        </List>
    </Stack>;
}

const SideToolbar = (props: { fullHeight: boolean }) => {
    return <>
        {(props.fullHeight && <Drawer variant='permanent' sx={{
            width: '232px',
        }}>
            <Stack
                justifyContent="space-between"

                sx={{
                    height: '100vh',
                    padding: '24px 40px 40px 40px',
                }}>
                <TopMenuList layout={"left"} />
                <BottomMenuList layout={"left"} />
            </Stack>
        </Drawer>)
            ||
            (<Stack
                alignItems="center"
                spacing={2}
                sx={{
                    backgroundColor: 'common.white',
                    padding: '16px',
                }}>
                <Stack>
                    <TopMenuList layout={"top"} />
                    <BottomMenuList layout={"top"} />
                </Stack>
            </Stack>)
        }

    </>
}

export default SideToolbar