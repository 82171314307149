// @ts-nocheck
import {Field} from "formik";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";
import * as React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CancelIcon from "@mui/icons-material/Cancel";
import {FieldAttributes} from "formik/dist/Field";


const OptionField = (props: {
    name: string,
    text: string,
    multiselect?: boolean,
    placeholder?: string,
    options: string[],
    onValueChange?: (value: string) => void,
    disabled?: boolean
}) => {

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
            component={
                ({
                     children,
                     form,
                     field
                 }: FieldAttributes<any>) => {
                    const {name, value} = field;

                    const handleChange = (event: SelectChangeEvent) => {
                        form.setFieldValue(name, event.target.value)
                        if (props.onValueChange !== undefined)
                            props.onValueChange(event.target.value)
                    };
                    const handleDelete = (event: React.MouseEvent, value: string) => {
                        // event.preventDefault();
                        var newArray = Object.assign([], field.value)
                        var index = field.value.indexOf(value);
                        if (index > -1) {
                            newArray.splice(index, 1);
                        }
                        form.setFieldValue(field.name, newArray)
                        if (props.onValueChange !== undefined)
                            props.onValueChange(value)
                    };

                    return (
                        <Select
                            fullWidth={true}
                            disabled={props.disabled}
                            name={name}
                            multiple={props.multiselect}
                            value={value || []}
                            onChange={handleChange}
                            input={<OutlinedInput
                                size={"small"}
                                sx={{
                                    backgroundColor: 'white',
                                    justifySelf: 'center',
                                }}
                            />}
                            renderValue={(selected) => (
                                <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                    {props.multiselect ?
                                        selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                clickable
                                                onDelete={(e) => handleDelete(e, value)}
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }/>
                                        ))
                                        : <Chip
                                            key={selected}
                                            label={selected}
                                            clickable
                                            onDelete={(e) => handleDelete(e, value)}
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                        />
                                    }
                                </Box>
                            )}


                        >{children}</Select>
                    );
                }
            }
        >
            {props.options.map((name) => (
                <MenuItem
                    key={name}
                    value={name}
                >
                    {name}
                </MenuItem>
            ))}
        </Field>
    </FieldHorizontalWrapper>
}

export default OptionField;