import {Box} from "@mui/material";
import * as React from 'react';
import Modal from '@mui/material/Modal';
import ButtonHolder from "../button-holder/ButtonHolder";
import errorMapping from "../../errors/Errors";
import style from "./ModalStyle";

const ErrorModal = (props: {
    open: boolean,
    errorCode: string,
    closeModal: () => void
}) => {
    return <Modal
        open={props.open}
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Box sx={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <Box id="modal-modal-description" sx={{
                        mt: 2,
                        fontSize: 'h6.fontSize',
                        fontFamily: 'h6.fontFamily',
                        textAlign: 'center'
                    }}>
                        {errorMapping.get(props.errorCode) || 'There has been an error, please contact administrator at darius@tryevergrow.com'}
                    </Box>
                </Box>
                <Box>
                    <Box sx={{mb: 2}}/>
                    <ButtonHolder
                        justify={'center'}
                        textRight={"Close"}
                        submitRight={props.closeModal}
                    /></Box>
            </Box>
        </Box>
    </Modal>
}

export default ErrorModal