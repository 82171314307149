// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BusinessSolution} from "../../../API";
import {RootState} from "../../store";

interface BusinessSolutionState {
    isFetching: boolean,
    businessSolutions?: [BusinessSolution],
    selected?: string
}

const initialState: BusinessSolutionState = {
    isFetching: false,
}

const sort = (first, second) => first.order - second.order

export const businessSolutionSlice = createSlice({
    name: 'businessSolution',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[BusinessSolution]>) => {
            state.businessSolutions = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<BusinessSolution>) => {
            state.businessSolutions = state.businessSolutions
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<BusinessSolution>) => {
            state.businessSolutions.push(action.payload)
            state.businessSolutions.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.businessSolutions = state.businessSolutions
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = businessSolutionSlice.actions
export const selectedBusinessSolution = (state: RootState) => state.businessSolution.selected
export const allBusinessSolutions = (state: RootState) => state.businessSolution.businessSolutions
export const businessSolutionsIsFetching = (state: RootState) => state.businessSolution.isFetching

export default businessSolutionSlice.reducer