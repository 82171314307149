import OnBoardingToolbar from "../atoms/on-boarding-toolbar/OnBoardingToolbar";
import {Box} from "@mui/system";
import {Outlet} from "react-router-dom";


const OnBoardingLayout = () => {
    return <Box sx={{flexGrow: 1}}>
        <OnBoardingToolbar/>
        <Outlet/>
    </Box>
}

export default OnBoardingLayout;