// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BusinessStep} from "../../../API";
import {RootState} from "../../store";

interface BusinessStepState {
    isFetching: boolean,
    businessSteps?: [BusinessStep],
    selected?: string
}

const initialState: BusinessStepState = {
    isFetching: false,
}

const sort = (first, second) => first.order - second.order

export const businessStepSlice = createSlice({
    name: 'businessStep',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[BusinessStep]>) => {
            state.businessSteps = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<BusinessStep>) => {
            state.businessSteps = state.businessSteps
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<BusinessStep>) => {
            state.businessSteps.push(action.payload)
            state.businessSteps.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.businessSteps = state.businessSteps
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = businessStepSlice.actions
export const selectedBusinessStep = (state: RootState) => state.businessStep.selected
export const allBusinessSteps = (state: RootState) => state.businessStep.businessSteps
export const businessStepsIsFetching = (state: RootState) => state.businessStep.isFetching

export default businessStepSlice.reducer