// @ts-nocheck
import {addOne, createdRun, fetched, isCreatingRun, isFetching, select, updateAll} from "./marketingCampaign";
import {API, graphqlOperation} from "aws-amplify";
import {v4 as uuidv4} from "uuid";
import {createMarketingCampaign} from "../../../graphql/mutations";
import {MarketingCampaign} from "../../../API";
import {listMarketingCampaignsEager} from "../../../graphql/custom_queries";

export const getMarketingCampaigns = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listMarketingCampaignsEager, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listMarketingCampaigns.items))
                const {marketingCampaign} = getState();
                if (!marketingCampaign.selected && marketingCampaign.marketingCampaigns.size !== 0) {
                    dispatch(select(marketingCampaign.marketingCampaigns[0]?.id))
                }
            })
    }
}

// export const updateMarketingCampaignAction = (
//     newMarketingCampaign: MarketingCampaign) => {
//     return async (dispatch, getState) => {
//         const {marketingCampaign} = await getState();
//         if (!marketingCampaign.isFetching) {
//             dispatch(isFetching())
//             try {
//                 delete newMarketingCampaign.createdAt
//                 delete newMarketingCampaign.updatedAt
//                 await API.graphql(graphqlOperation(updateMarketingCampaign, {
//                     input: newMarketingCampaign
//                 }))
//                 await dispatch(updateOne(newMarketingCampaign))
//             } finally {
//                 dispatch(fetched())
//             }
//         }
//     }
// }

export const createMarketingCampaignAction = (
    businessId: string,
    newMarketingCampaign: MarketingCampaign,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {marketingCampaign} = await getState();
        if (!marketingCampaign.isFetching || force) {
            dispatch(isFetching())
            try {
                newMarketingCampaign.id = uuidv4();
                newMarketingCampaign.businessId = businessId;
                newMarketingCampaign.marketingCampaignCustomerPersonaId = newMarketingCampaign.customerPersona.id
                newMarketingCampaign.marketingCampaignSiteId = newMarketingCampaign.site.id
                delete newMarketingCampaign.customerPersona
                delete newMarketingCampaign.site
                const fromApi = await API.graphql(graphqlOperation(createMarketingCampaign, {
                    input: newMarketingCampaign
                }))
                await dispatch(addOne(newMarketingCampaign))
                if (!marketingCampaign.selected)
                    dispatch(select(fromApi?.data?.createMarketingCampaign.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export interface MarketingCampaignStart {
    businessId: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
}

export const start = (start: MarketingCampaignStart) => {
    return async (dispatch, getState) => {
        const {marketingCampaign} = await getState();
        if (!marketingCampaign.isCreatingRun) {
            await dispatch(isCreatingRun())
            try {
                const newStart = Object.assign({}, start)
                newStart.dailyBudget = start.dailyBudget * 100
                return await API.post('marketingCampaigns', '/start', {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: newStart
                })
            } catch (e) {
                throw e.response.data.errors
            } finally {
                await dispatch(createdRun())
            }
        }
    }
}