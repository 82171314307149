// @ts-nocheck
import {clear, fetched, isFetching, select, updateAll, updateBusinessOnboardingStatus} from "./business";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinesses} from "../../../graphql/queries";
import {createBusiness, updateBusiness} from "../../../graphql/mutations";
import {v4 as uuidv4} from "uuid";
import {OnboardingStatus} from "../../../API";

export const getBusinessAction = () => {
    return async (dispatch, getState) => {
        const {business} = await getState();
        if (!business.business && !business.isFetching) {
            dispatch(isFetching())

            const fromApi = await API.graphql(graphqlOperation(listBusinesses))
            const items = fromApi?.data?.listBusinesses?.items;
            if (items && items.length > 0) {
                dispatch(updateAll(items))
                dispatch(select(items[0].id))
            } else {
                await API.graphql(graphqlOperation(createBusiness,
                    {
                        input: {
                            id: uuidv4(),
                            onboardingStatus: OnboardingStatus.Welcome,
                            tutorial: {
                                closed: false
                            }
                        }
                    }));
                const fromApi = await API.graphql(graphqlOperation(listBusinesses))
                const items = fromApi?.data?.listBusinesses?.items;
                if (items && items.length > 0) {
                    await dispatch(updateAll(items))
                    await dispatch(select(items[0].id))
                }
            }
            dispatch(fetched())
        } else {
            console.log("do not read")
        }
    }
}

export const updateBusinessAction = (businessId: string, onboardingStatus: OnboardingStatus) => {
    return async (dispatch, getState) => {
        API.graphql(graphqlOperation(updateBusiness, {
            input: {
                id: businessId,
                onboardingStatus
            }
        })).then((fromApi) => {
            dispatch(updateBusinessOnboardingStatus({
                businessId: fromApi?.data?.updateBusiness.id,
                status: onboardingStatus
            }))
            dispatch(select(fromApi?.data?.updateBusiness.id))
        })
    }
}

export const updateBusinessNameAction = (businessId: string, name: string) => {
    return async (dispatch, getState) => {
        API.graphql(graphqlOperation(updateBusiness, {
            input: {
                id: businessId,
                name
            }
        })).then((fromApi) => {
            dispatch(select(fromApi?.data?.updateBusiness.id))
        })
    }
}

export const signOutAction = () => {
    return async (dispatch, getState) => {
        dispatch(isFetching())
        dispatch(clear())
        dispatch(fetched())
    }
}