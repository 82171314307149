import * as React from "react";
import {Box} from "@mui/material";

type Props = {
    cards: JSX.Element[],
    xsColumns: number,
    smColumns: number,
    mdColumns: number,
    lgColumns: number,
};

const CardContainer = ({
                           cards,
                           xsColumns,
                           smColumns,
                           mdColumns,
                           lgColumns,
                       }: Props) => {
    return <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
            xs: 'repeat(' + xsColumns + ', 1fr)',
            sm: 'repeat(' + smColumns + ', 1fr)',
            md: 'repeat(' + mdColumns + ', 1fr)',
            lg: 'repeat(' + lgColumns + ', 1fr)',
        },
        columnGap: 3,
        rowGap: 1,
    }}>
        {cards.map((card, index) => {
            return <Box sx={{mb: 2}} key={'card-' + index}>
                {card}
            </Box>
        })}
    </Box>

}

export default CardContainer