import { createBrandAction, updateBrandAction } from "../../../redux/features/brand/brandActions";
import { Brand } from "../../../API";
import { Form, Formik, useFormikContext } from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../redux/features/business/business";
import { updateBusinessNameAction } from "../../../redux/features/business/businessActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import { FormikErrors } from "formik/dist/types";
import SelectWithAddition from "../../atoms/option-field/SelectWithAddition";

const validationSchema = Yup.object({
    name: Yup.string().required("This is a required field"),
    briefDescription: Yup.string().required("This is a required field"),
    longDescription: Yup.string().required("This is a required field"),
    keywords: Yup.array().min(3, "Add more keywords."),
})

type Props = {
    brand?: Brand,
    afterSubmit: () => void
};


// @ts-ignore
const initialValues: Brand = {
    businessId: "",
    id: "",
    name: '',
    logo: '',
    icon: '',
    briefDescription: '',
    longDescription: '',
    keywords: []
}

const BrandBaseFields = (props: { businessId?: string, afterSubmitNext: () => void }) => {
    const { submitForm, setFieldTouched, validateForm } = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['name', 'briefDescription', 'longDescription', 'keywords'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }

    return <Box sx={{ maxWidth: '380px', flexDirection: "column", margin: 'auto' }}>
        <LabeledField key='name' name='name' text='Brand' placeholder='ex. Acme Inc.' />
        <LabeledFieldWithMaxLength key='briefDescription' name='briefDescription' text='Tagline'
            placeholder='ex. A Company Making Everything'
            helperText={() => 'What your brand is all about in one sentence.'}
            maxLength={30} />
        <LabeledFieldWithMaxLength key='longDescription'
            name='longDescription' text='Description'
            placeholder='ex. We sell hammers, tools, anvils, rockets, and TNT...'
            helperText={() => 'Describe what you do or sell.'}
            maxLength={90} />
        <SelectWithAddition key='keywords'
            name='keywords' text='Keywords'
            helperText={() => 'Enter 3 keywords or more to help people find you.'}
        />
        <ButtonHolder
            submitRight={
                () => {
                    handleSubmit()
                        .then((errors) => {
                            if (!errors)
                                props.afterSubmitNext()
                        })
                }}
            textRight="Go to Customers"
            justify={"center"}
        />
    </Box>
}


const OnBoardingBrandForm = ({ brand, afterSubmit }: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            brand ?
                brand
                : initialValues
        }
        initialTouched={{
            briefDescription: true,
            longDescription: true
        }}

        validationSchema={validationSchema}
        onSubmit={async (values: Brand) => {
            if (brand?.id) {
                dispatch(updateBrandAction(values))
            } else {
                if (business) {
                    dispatch(createBrandAction(
                        business.id,
                        values))
                    if (values.name)
                        dispatch(updateBusinessNameAction(
                            business.id,
                            values.name))
                }
            }
        }}>
        <Container sx={{ pb: 4 }}>
            <Form>
            <Stack spacing={1} mb={3}>
                    <Typography variant="h1">Tell us about your brand</Typography>
                    <Typography variant="body1" color="gray">Describe your idea or business in a few words.</Typography>
                </Stack>
                <BrandBaseFields businessId={business?.id} afterSubmitNext={afterSubmit} />
            </Form>
        </Container>

    </Formik>
}

export default OnBoardingBrandForm