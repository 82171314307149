import {ReactComponent as DarkLogo} from "../../../images/logo-dark.svg";
import {Box} from "@mui/material";

const LoginHeader = () => {
    return <Box
        sx={{
            textAlign: 'center',
            margin: '20px'
        }}>
        <Box>
            <DarkLogo/>
        </Box>
    </Box>
}

export default LoginHeader