import * as React from 'react';
import { CardContent, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Props = {
    title: string,
    subtitle: string,
    link?: string,
    child: JSX.Element
};
const OverviewCard = ({
    title,
    subtitle,
    link,
    child
}: Props
) => {

    return <Card sx={{
        minWidth: '200px',
        height: '100%',
        borderRadius: 4,
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#DADADA',
        boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)',
        boxSizing: 'border-box',
        padding: 1
    }}
    >
        <CardContent>
                <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent={{ lg: 'space-between' }} alignItems={{ xs: "flex-start", lg: "center" }}>
                    <Typography variant="h2">
                        {title}
                    </Typography>
                        {link ?
                            <Button
                                sx={{ borderRadius: 16, textTransform: "none" }}
                                variant="text"
                                color="secondary"
                                href={link}
                                endIcon={<NavigateNextIcon />}
                            >
                                <Typography variant="button" fontWeight="bold">Add funds</Typography></Button> : ""}
                    
                </Stack>
            <Typography variant="subtitle1" align='left' color={"#71717A"} mb={4}>
                {subtitle}
            </Typography>
                {child}
        </CardContent>
    </Card>
}


export default OverviewCard;
