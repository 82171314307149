import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';

let theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
        common: {
            black: '#18181B',
            white: '#ffffff',
        },
        primary: {
             main: '#18181B',
 },
        secondary: {
            main: '#A855F7',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
            letterSpacing: 0.2
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 700,
            letterSpacing: 0.2
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 700,
            letterSpacing: 0.2
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: 0.2
        },
        h5: {
            fontSize: '1.125rem',
            fontWeight: 700,
            letterSpacing: 0.2
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 700,
            letterSpacing: 0.2

        }
    }
});

theme = responsiveFontSizes(theme);


const EvergrowTheme = (props: { children: JSX.Element[] }) => {
    return <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
}

export default EvergrowTheme