// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Business} from "../../../API";
import {RootState} from "../../store";
import {TutorialUpdate} from "./tutorialType";
import {OnboardingUpdate} from "./businessTypes";

interface BusinessState {
    isFetching: boolean,
    businesses?: [Business],
    selected?: string
}

const initialState: BusinessState = {
    isFetching: false,
}


export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[Business]>) => {
            state.businesses = action.payload
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        },
        clear: (state) => {
            state.businesses = undefined
            state.selected = undefined
        },
        updateTutorial: (state, action: PayloadAction<TutorialUpdate>) => {
            if (state?.businesses)
                state.businesses = state.businesses
                    .map((entry) => {
                        if (entry.id !== action.payload.businessId) {
                            return entry
                        } else {
                            entry.tutorial = action.payload.tutorial
                            return entry
                        }
                    })
        },
        updateBusinessOnboardingStatus: (state, action: PayloadAction<OnboardingUpdate>) => {
            if (state?.businesses)
                state.businesses = state.businesses
                    .map((entry) => {
                        if (entry.id !== action.payload.businessId) {
                            return entry
                        } else {
                            entry.onboardingStatus = action.payload.status
                            return entry
                        }
                    })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    updateAll,
    select,
    isFetching,
    fetched,
    clear,
    updateTutorial,
    updateBusinessOnboardingStatus
} = businessSlice.actions
export const selectedBusiness = (state: RootState) =>
    state.business.businesses?.find((b) => b.id === state.business.selected)

export const selectedBusinessTutorial = (state: RootState) =>
    state.business.businesses?.find((b) => b.id === state.business.selected)?.tutorial

export const selectedBusinessOnboardingState = (state: RootState) => state.business.selected

export default businessSlice.reducer