import CardWrapper from "../card/CardWrapper";
import CloseIcon from '@mui/icons-material/Close';
import * as React from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export interface InfoCardProps {
    header: string,
    items: string[],
    backgroundColor?: string,
    textColor?: string,
    image?: string,
    buttonLink?: string,
    buttonText?: string,
    closeInfo: () => void
}

const InfoCard = ({ header, items, backgroundColor, textColor, image, buttonLink, buttonText, closeInfo }: InfoCardProps) => {
    return <CardWrapper>
        <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            justifyContent={{ xs: "center", md: "space-between" }}
            alignItems={{ xs: "stretch", md: "center" }}
            p={2}
            sx={{ background: backgroundColor, color: textColor }}
        >
            <Stack
                alignItems={{ xs: "center", md: "flex-start" }}
                spacing={2}
                textAlign={{ xs: "center", md: "left" }}
                p={1} width={{ xs: "100%", md: "60%", lg: "50%" }}>
                <Typography variant="h1">
                    {header}
                </Typography>
                <Stack spacing={2}>
                    {items.map((item, index) => (
                        <span key={'info-card-' + index}>
                            <Typography variant="body1" key={'info-card-' + index}>{item}</Typography>
                        </span>
                    ))}
                </Stack>
               {buttonLink && buttonText ? <Button
                    sx={{
                        borderRadius: 100,
                        backgroundColor: "black",
                        fontWeight: 700
                    }}
                    variant={"contained"}
                    size={"large"}
                    href={buttonLink}
                >
                    {buttonText}
                </Button> : ""}
            </Stack>
            <Stack direction={{ xs: "column-reverse", md: "row" }}>
                <Box>
                    <img
                        src={image}
                        alt={'Site'}
                        width="300px"
                    />
                </Box>
                <IconButton onClick={closeInfo} sx={{ p: 2, alignSelf: { xs: "flex-end", md: "flex-start" } }}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Stack>
    </CardWrapper>
}

export default InfoCard