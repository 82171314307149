import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MarketingCampaign} from "../../../API";
import {RootState} from "../../store";

interface MarketingCampaignState {
    isFetching: boolean,
    marketingCampaigns?: MarketingCampaign[],
    selected?: string,
    isCreatingRun: boolean
}

const initialState: MarketingCampaignState = {
    isFetching: false,
    isCreatingRun: false
}

export const marketingCampaignSlice = createSlice({
    name: 'marketingCampaign',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[MarketingCampaign]>) => {
            state.marketingCampaigns = action.payload
                .sort() || []
        },
        updateOne: (state, action: PayloadAction<MarketingCampaign>) => {
            if (state.marketingCampaigns) {
                state.marketingCampaigns = state.marketingCampaigns
                    .map((entry) => {
                        if (entry.id !== action.payload.id) {
                            return entry
                        } else {
                            return action.payload
                        }
                    }).sort()
            }

        },
        addOne: (state, action: PayloadAction<MarketingCampaign>) => {
            if (!state.marketingCampaigns) {
                state.marketingCampaigns = []
            }
            state.marketingCampaigns.push(action.payload)
            state.marketingCampaigns.sort()
        },
        remove: (state, action: PayloadAction<string>) => {
            if (!state.marketingCampaigns) {
                state.marketingCampaigns = []
            }
            state.marketingCampaigns = state.marketingCampaigns
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort()
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        },
        isCreatingRun: (state) => {
            state.isCreatingRun = true
        },
        createdRun: (state) => {
            state.isCreatingRun = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched, isCreatingRun, createdRun} = marketingCampaignSlice.actions
export const selectedMarketingCampaign = (state: RootState) => state.marketingCampaign.marketingCampaigns?.find((b) => b.id === state.business.selected)
export const allMarketingCampaigns = (state: RootState) => state.marketingCampaign.marketingCampaigns
export const marketingCampaignsIsFetching = (state: RootState) => state.marketingCampaign.isFetching
export const isCreatingRunSelector = (state: RootState) => state.marketingCampaign.isCreatingRun

export default marketingCampaignSlice.reducer