import * as React from 'react';
import {Container} from "@mui/material";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import CustomerPersonaForm from "../../../molecules/customer-persona-form/CustomerPersonaForm";
import {allCustomerPersonas} from "../../../../redux/features/customerPersona/customerPersona";


const EditCustomerPersonaPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const customerPersonas = useSelector(allCustomerPersonas);

    return <Container>
        <DashboardHeader title={"Customers / Customer Persona"}
                         backTo={"/dashboard/customer-personas"}
        />

        <CardFormWrapper>
            <CustomerPersonaForm customerPersona={
                customerPersonas?.find((customerPersona) => customerPersona.id === id)
            }
                                afterSubmit={() => navigate('/dashboard/customer-personas')}
                                />
        </CardFormWrapper>
    </Container>
}


export default EditCustomerPersonaPage