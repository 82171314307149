// @ts-nocheck
import {ClosedInfoState, fetched, isFetching, updateAll} from "./closeInfo";
import {API, graphqlOperation} from "aws-amplify";
import {listClosedInfos} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {createClosedInfo} from "../../../graphql/mutations";
import {ClosedInfo} from "../../../API";
import {Dispatch} from "react";

export const getInfos = () => {
    return async (
        dispatch: Dispatch<any>
    ) => {
        const got = await API.graphql(graphqlOperation(listClosedInfos))
        dispatch(updateAll(got?.data?.listClosedInfos.items))
    }
}

export const closeInfoAction = (
    code: string) => {
    return async (
        dispatch: Dispatch<any>,
        getState: () => PromiseLike<{ closedInfo: ClosedInfoState }>) => {
        const {closedInfo} = await getState();
        if (!closedInfo.isFetching) {
            dispatch(isFetching())
            try {
                // @ts-ignore
                const newInfo: ClosedInfo = {
                    id: uuidv4(),
                    code: code,
                    closed: moment().toISOString()
                }
                await API.graphql(graphqlOperation(createClosedInfo, {
                    input: newInfo
                }))
                const newAll: ClosedInfo[] = closedInfo.closedInfos ? [...closedInfo.closedInfos] : []
                newAll.push(newInfo)
                dispatch(updateAll(newAll))
            } finally {
                dispatch(fetched())
            }
        }
    }
}