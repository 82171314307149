import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useEffect} from "react";
import {allCustomerPersonas, selectedCustomerPersona} from "../../../../redux/features/customerPersona/customerPersona";
import {getCustomerPersonasAction} from "../../../../redux/features/customerPersona/customerPersonaActions";
import {useNavigate} from "react-router-dom";
import {updateBusinessAction} from "../../../../redux/features/business/businessActions";
import {OnboardingStatus} from "../../../../API";
import OnBoardingCustomerForm from "../../../molecules/customer-persona-form/OnBoardingCustomerForm";


const OnBoardingCustomers = () => {
    const navigate = useNavigate();
    const customerPersonas = useSelector(allCustomerPersonas);
    const selected = useSelector(selectedCustomerPersona);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business)
            dispatch(getCustomerPersonasAction(business.id))
    }, [dispatch, business])

    const afterSubmitNext = () => {
        if (business) {
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Site))
            navigate('/onboarding/site')
        }
    }

    const afterSubmitBack = () => {
        if (business) {
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Brand))
            navigate('/onboarding/brand')
        }
    }

    return <OnBoardingCustomerForm customerPersona={customerPersonas?.find((tc => tc.id === selected))}
                                   afterSubmitNext={afterSubmitNext}
                                   afterSubmitBack={afterSubmitBack}/>
}

export default OnBoardingCustomers;