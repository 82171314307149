import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useEffect} from "react";
import {getBusinessAction, updateBusinessAction} from "../../../../redux/features/business/businessActions";
import {OnboardingStatus} from "../../../../API";
import {Container, Stack} from "@mui/material";
import ButtonHolder from "../../../atoms/button-holder/ButtonHolder";
import Typography from '@mui/material/Typography';

const WelcomePage = () => {
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!business)
            dispatch(getBusinessAction())
    }, [dispatch, business])

    const submit = () => {
        if (business)
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Brand))
        navigate('/onboarding/brand')
    }

    return <Container maxWidth="sm">
        <Stack sx={{pt: 12, pb: 2}} spacing={3}>
            <Stack spacing={1}>
                <Typography component="span" sx={{fontSize:"80px"}}>🚀</Typography>
                <Typography variant="h1" component="h1">Ready to be discovered?</Typography>
                <Typography variant="body1" component="h1" color="gray">In just a few steps, get a
                    site and promote it to get results faster than ever before.</Typography>
            </Stack>
            <ButtonHolder
                submitRight={submit}
                textRight="Get Started"
                justify={"right"}
                nextIcon
            />
        </Stack>
    </Container>
}

export default WelcomePage;