import {Site} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {FormikErrors} from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import {updateSiteAction} from "../../../redux/features/site/siteActions";
import {useState} from "react";
import WaitingModal from "../../atoms/modal/WaitingModal";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import {initialValues} from "../../../redux/features/site/initialValues";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LabeledCheckboxField from "../../atoms/labeled-field/LabeledCheckboxField";


const validationSchema = Yup.object({
    live: Yup.object().shape({
        functions: Yup.object().shape({
            phone: Yup.object().shape({
                buttonText: Yup.string().required("Button text is required"),
                redirectTo: Yup.string()
                .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "Enter a valid phone number")
            })
        })
    })
})

type Props = {
    site?: Site,
    afterSubmit: () => void
};


const SitePhoneFields = (props: { businessId?: string, afterSubmit: () => void }) => {

    const {submitForm, setFieldTouched, validateForm} = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['live',].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <Stack>
        <Typography variant="h3" textAlign="left">Phone</Typography>
        <Typography variant="body1" textAlign="left" color="gray">Use this action to get people to call you.</Typography>
        <Box sx={{width: '100%', maxWidth: '380px', margin: 'auto', pt: 2}}>
        <LabeledCheckboxField
                    key='disabled'
                    name='live.functions.phone.enabled' text='Enable'
                    placeholder=''
                />
            <LabeledFieldWithMaxLength
                key='buttonText'
                name='live.functions.phone.buttonText' text='Button text'
                placeholder='ex. Call us at 1800 ...'
                maxLength={30}/>
            <LabeledField
                key='redirectTo'
                name='live.functions.phone.redirectTo' text='Phone number'
                placeholder='1800 123 456'/>
            <ButtonHolder
                textRight={"Save"}
                textLeft={"Delete"}
                justify={'right'}
                submitRight={
                    () => {
                        handleSubmit()
                            .then((errors) => {
                                if (!errors)
                                    props.afterSubmit()
                            })
                    }}/></Box>

    </Stack>

}


const SitePhoneForm = ({site, afterSubmit}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => {
        setOpen(false);
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={site || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values: Site) => {
                openModal()
                if (business) {
                    try {
                        await dispatch(updateSiteAction(business.id, values))
                    } finally {
                        closeModal()
                        afterSubmit()
                    }
                }
            }}>
            <Form>
                <SitePhoneFields businessId={business?.id} afterSubmit={afterSubmit}/>
            </Form>
        </Formik>
        {open && <WaitingModal text={'Please wait we are updating the site'}/>}
    </>
}

export default SitePhoneForm