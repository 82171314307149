import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Site, SiteDeployment} from "../../../API";
import {RootState} from "../../store";

interface SitesState {
    isFetching: boolean,
    sites?: Site[],
    selected?: string,
}

const initialState: SitesState = {
    isFetching: false,
}

export const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[Site]>) => {
            state.sites = action.payload
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        },
        updateLastDeployment: (state, action: PayloadAction<SiteDeployment>) => {
            state.sites = state.sites?.map((b) => {
                if(b.id === state.selected){
                    b.lastDeployment = action.payload
                }
                return b;
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, select, isFetching, fetched, updateLastDeployment} = sitesSlice.actions
export const selectedSite = (state: RootState) => state.site.sites?.find((b) => b.id === state.site.selected)
export const allSites = (state: RootState) => state.site.sites as Site[]
export const siteIsFetching = (state: RootState) => state.site.isFetching
export const lastDeployment = (state: RootState) => state.site.sites?.find((b) => b.id === state.site.selected)?.lastDeployment

export default sitesSlice.reducer