import * as React from 'react';
import {useEffect} from 'react';
import {Container} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {selectedSite} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import SiteHeader from "../../../molecules/site-header/SiteHeader";
import {useNavigate} from "react-router-dom";
import CardContainer from "../../../atoms/card/CardContainer";


const SitePage = () => {

    const site = useSelector(selectedSite);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (business) {
            if (!site)
                dispatch(getSites(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business, site])


    return <Container>
        <SiteHeader title={"Site actions"}  backTo={'/dashboard/site/'}/>
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                [
                    <ClickableCard
                        title={"📩  Form"}
                        text={"Let visitors contact you by adding a simple form on your site."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/contact-form')}/>,
                        <ClickableCard
                        title={"📅 Bookings"}
                        text={"Use a calendar service like Calendly to get bookings faster."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/calendly')}/>,
                        <ClickableCard
                        title={"📞 Phone"}
                        text={"Add a click-to-call button on your site."}
                        status={CardState.New}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/phone')}/>,
                    <ClickableCard
                        title={"🔗 Link"}
                        text={"Send visitors to their next destination by adding a custom link."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/link')}/>
                    ,
                ]}/>
    </Container>
}


export default SitePage