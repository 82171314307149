import OverviewCard from "../../atoms/card/OverviewCard";
import {Box} from "@mui/system";
import ScoreCard from "../../atoms/card/ScoreCard";
import CardContainer from "../../atoms/card/CardContainer";
import * as React from "react";
import {useEffect} from "react";
//import {getLeads} from "../../../redux/features/leads/_zleadsAction";
import {useDispatch, useSelector} from "react-redux";
import {selectedSite} from "../../../redux/features/site/site";
import {currentLeadStatistics} from "../../../redux/features/leads/leads";

const OverviewStatistics = () => {
    const site = useSelector(selectedSite);
    const statistics = useSelector(currentLeadStatistics);

    const dispatch = useDispatch();

/*
    useEffect(() => {
            if (site)
                dispatch(getLeads(site.id))
        }
        , [site, dispatch])*/

    return <CardContainer
        xsColumns={1}
        smColumns={1}
        mdColumns={1}
        lgColumns={1}
        cards={[
            <OverviewCard
                title={"Performance"}
                subtitle={'Quickly check how your site and campaigns are working.'}
                child={
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(3, 1fr)',
                            md: 'repeat(3, 1fr)'
                        },
                        columnGap: 3,
                        rowGap: 2,
                    }}>
                        {/*Temporary labels until Leads counter API is fixed*/}
                        <ScoreCard value={statistics?.leadsTotal || '0'} label={'Active campaigns'}/>
                        <ScoreCard value={statistics?.leadsLast7Days || '$0'} label={'Total spend'}/>
                        <ScoreCard value={statistics?.costPerLead || '-'} label={'Cost per lead'}/>
                    </Box>
                }
            />]}
    />
}

export default OverviewStatistics