import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {getCustomerPersonasAction} from "../../../../redux/features/customerPersona/customerPersonaActions";
import {allCustomerPersonas} from "../../../../redux/features/customerPersona/customerPersona";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import AddNewCard from "../../../atoms/card/AddNewCard";
import CardContainer from "../../../atoms/card/CardContainer";
import ClosableInfoCard from "../../../molecules/info-card/ClosableInfoCard";
import image from "../../../../images/illustrations/customer.png";

const tips = ['You can target multiple audiences in separate campaigns, and see which targeting brings the most conversions',]

const CustomerPersonaPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business = useSelector(selectedBusiness)
    const customerPersonas = useSelector(allCustomerPersonas);

    useEffect(() => {
        if (business)
            dispatch(getCustomerPersonasAction(business.id))
    }, [dispatch, business])

    return <Container>
        <DashboardHeader title={"Customer personas"}
                         backTo={"/dashboard/customers"}
                         underTitle={
                             <ClosableInfoCard
                                 code={'CUSTOMER_PERSONA'}
                                 header={'Define your target audience'} items={tips}
                                 image={image}
                                 />}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                customerPersonas ?
                    customerPersonas?.map(customerPersona => {
                        return <ClickableCard
                            title={customerPersona.name as string}
                            text={''}
                            status={CardState.Blank}
                            onClick={() => navigate('/dashboard/customer-personas/' + customerPersona.id)}
                        />
                    }).concat(
                        <AddNewCard
                            onClick={() => navigate('/dashboard/customer-personas/new')}
                        />
                    ) : [<AddNewCard
                        onClick={() => navigate('/dashboard/customer-personas/new')}
                    />]}
        />
    </Container>
}


export default CustomerPersonaPage