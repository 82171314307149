import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../../redux/features/business/business";
import { useEffect } from "react";
import { updateBusinessAction } from "../../../../redux/features/business/businessActions";
import { MarketingCampaignObjective, MarketingCampaignProvider, OnboardingStatus } from "../../../../API";
import { getSites } from "../../../../redux/features/site/siteActions";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import { Box, Button } from "@mui/material";
import ButtonHolder from "../../../atoms/button-holder/ButtonHolder";
import { allCustomerPersonas } from "../../../../redux/features/customerPersona/customerPersona";
import { allSites } from "../../../../redux/features/site/site";
import { getCustomerPersonasAction } from "../../../../redux/features/customerPersona/customerPersonaActions";
import { createMarketingCampaignAction } from "../../../../redux/features/marketingCampaign/marketingCampaignActions";
import Confetti from 'react-confetti'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const OnBoardingCampaignPage = () => {
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const customerPersonas = useSelector(allCustomerPersonas);
    const sites = useSelector(allSites);
    const dispatch = useDispatch();

    useEffect(() => {
        if (business) {
            if (!customerPersonas)
                dispatch(getCustomerPersonasAction(business.id))
            if (!sites)
                dispatch(getSites(business.id))
        }
    }, [dispatch, business, sites, customerPersonas])

    const submit = () => {
        if (business) {
            dispatch(createMarketingCampaignAction(business.id, {
                businessId: business.id,
                site: sites[0],
                provider: MarketingCampaignProvider.GOOGLE_ADS,
                objective: MarketingCampaignObjective.LEADS,
                // @ts-ignore
                customerPersona: customerPersonas[0]
            }, false))
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Done))
            navigate('/dashboard/overview')
        }
    }
    return <Container maxWidth="sm" sx={{ pb: 4 }}>
        <Confetti
            width={2000}
            height={2000}
        />
        <Stack sx={{ pt: 12, pb: 2 }} spacing={3}>
            <Stack spacing={1}>
                <Typography component="span" sx={{fontSize:"80px"}}>📣</Typography>
                <Typography variant="h1">
                    Now promote it!
                </Typography>
                <Typography variant="body1" component="h1" color="gray">
                    Share your site with people you know. Then, post it on social media, directories, and groups that are relevant to you. <br/><br/>To expand your reach even further add funds and use Evergrow to launch a campaign on Google. It's that simple!
                </Typography>
            </Stack>
            <Box>
                <Button
                    sx={{
                        maxWidth: "380px",
                        borderRadius: 100,
                        fontWeight: "bold",
                        py: 1,
                    }}
                    target='_blank'
                    color='secondary'
                    size="large"
                    fullWidth
                    href="https://buy.stripe.com/5kAaGfaiP9aw0jS9AE"
                    variant={"contained"}
                    endIcon={<NavigateNextIcon/>}
                >Add funds</Button>
            </Box>
            <ButtonHolder
                submitRight={submit}
                textRight="Go to Dashboard"
                justify={"right"}
            />
        </Stack>
    </Container>
}

export default OnBoardingCampaignPage;