import * as React from 'react';
import Container from '@mui/material/Container';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {allBusinessSteps} from "../../../../redux/features/businessStep/businessStep";
import BusinessStepForm from "../../../molecules/business-step-form/BusinessStepForm";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";


const EditBusinessStepPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const businessSteps = useSelector(allBusinessSteps);

    return <Container>
        <DashboardHeader title={"How it works / Step"}
                         backTo={"/dashboard/business-steps"}
        />
        <CardFormWrapper>
            <BusinessStepForm businessStep={
                businessSteps?.find((product) => product.id === id)
            }
                              afterSubmit={() => navigate('/dashboard/business-steps')}
                // @ts-ignore
                              defaultOrder={businessSteps?.reduce(
                                  // @ts-ignore
                                  (max, sol) => (sol?.order > max ? sol?.order : max),
                                  businessSteps[0]?.order
                              ) + 1 || 1}
            />
        </CardFormWrapper>
    </Container>
}


export default EditBusinessStepPage