import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import CardContainer from "../../../atoms/card/CardContainer";
import AddNewCard from "../../../atoms/card/AddNewCard";
import {allMarketingCampaigns} from "../../../../redux/features/marketingCampaign/marketingCampaign";
import {getMarketingCampaigns} from "../../../../redux/features/marketingCampaign/marketingCampaignActions";
import SiteHeader from "../../../molecules/site-header/SiteHeader";
import MarketingCard from "../../../atoms/marketing-card/MarketingCard";
import FormModal from "../../../atoms/modal/FormModal";
import MarketingCampaignStartForm from "../../../molecules/marketing-campaign-start-form/MarketingCampaignStartForm";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";

const MarketingCampaignPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const marketingCampaigns = useSelector(allMarketingCampaigns);
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [marketingCampaignId, setMarketingCampaignId] = useState<string>();


    useEffect(() => {
        if (business) {
            dispatch(getMarketingCampaigns(business.id))
        } else {
            dispatch(getBusinessAction())
        }

    }, [dispatch, business])

    return <Container>
        <SiteHeader title={"Campaigns"}
                    backTo={"/dashboard/customers"}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                marketingCampaigns ?
                    marketingCampaigns?.map(mk => {
                        return <MarketingCard
                            marketingCampaign={mk}
                            onStart={(id) => {
                                setMarketingCampaignId(id)
                                setOpenModal(true)
                            }}
                        />
                    }).concat(
                        <AddNewCard
                            onClick={() => navigate('/dashboard/marketing-campaign/new')}
                        />
                    ) : [<AddNewCard
                        onClick={() => navigate('/dashboard/marketing-campaign/new')}
                    />]}
        />
        <FormModal
            open={openModal}
            marketingCampaignId={marketingCampaignId as string}
            closeModal={() => setOpenModal(false)}
            onSubmit={() => {
                setOpenModal(false);
            }}>
            <MarketingCampaignStartForm
                businessId={business?.id as string}
                marketingCampaignId={marketingCampaignId as string}
                afterSubmit={() => {
                    setOpenModal(false);
                }
                }
            />
        </FormModal>
    </Container>
}


export default MarketingCampaignPage