// @ts-nocheck
import {addOne, fetched, isFetching, remove, select, updateAll, updateOne} from "./businessSolution";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinessSolutions} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {createBusinessSolution, deleteBusinessSolution, updateBusinessSolution} from "../../../graphql/mutations";
import {BusinessSolution} from "../../../API";

export const getBusinessSolutions = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listBusinessSolutions, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBusinessSolutions.items))
                const {businessSolution} = getState();
                if (!businessSolution.selected && businessSolution.businessSolutions.size !== 0) {
                    dispatch(select(businessSolution.businessSolutions[0]?.id))
                }
            })
    }
}

export const updateBusinessSolutionAction = (
    newBusinessSolution: BusinessSolution) => {
    return async (dispatch, getState) => {
        const {businessSolution} = await getState();
        if (!businessSolution.isFetching) {
            dispatch(isFetching())
            try {
                delete newBusinessSolution.createdAt
                delete newBusinessSolution.updatedAt
                await API.graphql(graphqlOperation(updateBusinessSolution, {
                    input: newBusinessSolution
                }))
                await dispatch(updateOne(newBusinessSolution))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createBusinessSolutionAction = (
    businessId: string,
    newBusinessSolution: BusinessSolution,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {businessSolution} = await getState();
        if (!businessSolution.isFetching || force) {
            dispatch(isFetching())
            try {
                newBusinessSolution.id = uuidv4();
                newBusinessSolution.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createBusinessSolution, {
                    input: newBusinessSolution
                }))
                await dispatch(addOne(newBusinessSolution))
                if (!businessSolution.selected)
                    dispatch(select(fromApi?.data?.createBusinessSolution.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}


export const deleteBusinessSolutionAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {businessSolution} = await getState();
        if (!businessSolution.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteBusinessSolution, {
                    input: {id: id}
                }))
                await dispatch(remove(id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}