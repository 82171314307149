// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Product} from "../../../API";
import {RootState} from "../../store";

interface ProductState {
    isFetching: boolean,
    products?: Product[],
    selected?: string,
    error?: string
}

const initialState: ProductState = {
    isFetching: false,
}

const sort = (first: Product, second: Product) => first.order as number - (second.order as number)

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[Product]>) => {
            state.products = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<Product>) => {
            state.products = state.products
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<Product>) => {
            state.products.push(action.payload)
            state.products.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.products = state.products
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        },
        handleError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched, handleError} = productSlice.actions
export const selectedProduct = (state: RootState) => state.product.selected
export const allProducts = (state: RootState) => state.product.products
export const productsIsFetching = (state: RootState) => state.product.isFetching
export const productError = (state: RootState) => state.product.error

export default productSlice.reducer