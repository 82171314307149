import { Box, FormHelperText } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";
import { FieldAttributes } from "formik/dist/Field";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



const LabeledRadioField = (props: {
    name: string,
    text: string,
    placeholder?: string,
    helperText?: (value: any) => string,
    transformField?: (value: any) => any,
    endAdornment?: JSX.Element
}
) => {
    const {setFieldValue} = useFormikContext();
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props.name);
    const name = 'selectedOption'

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({
            field,
            form,
        }: FieldAttributes<any>) => (
            <>
                <Box sx={{
                    display: 'flex',
                }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="title"
                        name={name}
                        value={field.value}
                        onChange={(event) => {
                         setFieldValue(props.name,event.currentTarget.value)
                        }
                        }
                    >
                        <FormControlLabel value="rounded" control={<Radio />} label="Rounded edges" />
                        <FormControlLabel value="pill" control={<Radio />} label="Pill" />
                        <FormControlLabel value="squared" control={<Radio />} label="Squared edges" />
                    </RadioGroup>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                </Box>
            </>
        )}</Field>
    </FieldHorizontalWrapper>
}


export default LabeledRadioField;