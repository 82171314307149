// @ts-nocheck
import {fetched, addOne, isFetching, remove, select, updateAll, updateOne, handleError} from "./product";
import {API, graphqlOperation} from "aws-amplify";
import {listProducts} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {createProduct, deleteProduct, updateProduct} from "../../../graphql/mutations";
import {Product} from "../../../API";

export const getProducts = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listProducts, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listProducts.items))
                const {product} = getState();
                if (!product.selected && product.products.size !== 0) {
                    dispatch(select(product.products[0]?.id))
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
                dispatch(handleError('Error fetching products. Please try again later.'));
            })
    }
}

export const updateProductAction = (
    newProduct: Product) => {
    return async (dispatch, getState) => {
        const {product} = await getState();
        if (!product.isFetching) {
            dispatch(isFetching())
            try {
                delete newProduct.createdAt
                delete newProduct.updatedAt
                await API.graphql(graphqlOperation(updateProduct, {
                    input: newProduct
                }))
                await dispatch(updateOne(newProduct))
            } catch (error) {
                console.error('Error updating product:', error);
                dispatch(handleError('Error updating product. Please try again later.'));
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createProductAction = (
    businessId: string,
    newProduct: Product,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {product} = await getState();
        if (!product.isFetching || force) {
            dispatch(isFetching())
            try {
                newProduct.id = uuidv4();
                newProduct.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createProduct, {
                    input: newProduct
                }))
                await dispatch(addOne(newProduct))
                if (!product.selected)
                    dispatch(select(fromApi?.data?.createProduct.id))
            } catch (error) {
                console.error('Error creating product:', error);
                dispatch(handleError('Error creating product. Please try again later.'));
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const deleteProductAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {product} = await getState();
        if (!product.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteProduct, {
                    input: {id: id}
                }))
                await dispatch(remove(businessId))
            } catch (error) {
                console.error('Error deleting product:', error);
                dispatch(handleError('Error deleting product. Please try again later.'));
            } finally {
                dispatch(fetched())
            }
        }
    }
}
