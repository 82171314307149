import {MarketingCampaign, MarketingCampaignObjective, MarketingCampaignProvider, Site} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {FormikErrors} from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import TwoColumn from "../../atoms/two-column/TwoColumn";
import AutoCompleteSelect from "../../atoms/autocomplete-select/AutoCompleteSelect";
import {createMarketingCampaignAction} from "../../../redux/features/marketingCampaign/marketingCampaignActions";
import {CustomerPersonaSimpleModel} from "../../../redux/features/customerPersona/model";

import GoogleAdsIllustration from "../../../images/illustrations/google_ads.png";
import {Box} from "@mui/material";

const validationSchema = Yup.object({
    customerPersona: Yup.string().transform(((value, originalvalue) => {
        return value?.id
    })).required("This field is required"),
    site: Yup.string().transform(((value, originalvalue) => {
        return value?.id
    })).required("This field is required")
})

type Props = {
    marketingCampaign?: MarketingCampaign,
    afterSubmit: () => void,
    customerPersonas: (filter: string) => Promise<CustomerPersonaSimpleModel[]>,
    sites: (filter: string) => Promise<Site[]>,
};


const MarketingCampaignFields = (props: {
    customerPersonas: (filter: string) => Promise<CustomerPersonaSimpleModel[]>,
    sites: (filter: string) => Promise<Site[]>,
    businessId?: string,
    afterSubmit: () => void,
}) => {
    const {submitForm, setFieldTouched, validateForm} = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['customerPersona', 'site'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <>
        <TwoColumn
            left={[
                <Box key={'GoogleAdsIllustration'} sx={{pt: 6, pb: 2}}>
                    <img
                        src={GoogleAdsIllustration}
                        alt={'Site'}
                        width="35%"
                    />
                </Box>]}
            right={[
                <AutoCompleteSelect
                    key={'customerPersona'}
                    name='customerPersona'
                    text='Target audience for campaign'
                    fetchOptions={props.customerPersonas}
                    multiple={false}
                    minChars={0}/>,
                <AutoCompleteSelect
                    key={'site'}
                    name='site'
                    text='Site to lead to'
                    fetchOptions={props.sites}
                    multiple={false}
                    minChars={0}/>,
            ]}
            bottom={
                <ButtonHolder
                    textRight={"Save"}
                    submitRight={
                        () => {
                            handleSubmit()
                                .then((errors) => {
                                    if (!errors)
                                        props.afterSubmit()
                                })
                        }}
                    justify={'right'}
                />}/>

    </>
}


const MarketingCampaignForm = ({marketingCampaign, afterSubmit, customerPersonas, sites}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            marketingCampaign ?
                {
                    ...marketingCampaign
                } : {
                    customerPersona: {
                        name: ''
                    },
                    businessId: '',
                    site: {
                        name: ''
                    },
                    objective: MarketingCampaignObjective.LEADS,
                    provider: MarketingCampaignProvider.GOOGLE_ADS,
                } as MarketingCampaign
        }
        validationSchema={validationSchema}
        onSubmit={async (values) => {
            if (marketingCampaign) {
                // await dispatch(updateMarketingCampaignAction(values))
            } else {
                if (business)
                    await dispatch(createMarketingCampaignAction(business.id, values, false))
            }
            // await updateTutorialStatus(business, dispatch, 'testimonial');
            afterSubmit()
        }}>
        <Form>
            <MarketingCampaignFields
                businessId={business?.id}
                afterSubmit={afterSubmit}
                customerPersonas={customerPersonas}
                sites={sites}
            />
        </Form>
    </Formik>
}

export default MarketingCampaignForm