import {Site} from "../../../API";


export const initialValues: Site =
    {
        __typename: "Site",
        live: {
            __typename: "SiteData",
            netlifySiteId: '',
            netlifyDeploymentId: '',
            url: '',
            meta: null,
            gtmTag: null,
            functions: {
                __typename: "SiteFunction",
                actions: [],
                form: {
                    __typename: "SiteForm",
                    forwardTo: '',
                    disabled: false,
                    bottomHeader: 'Take the next step',
                    title: 'Get in touch!',
                    buttonText: 'Get in touch!',
                    firstNameLabel: "First name",
                    firstNameEnabled: false,
                    firstNameRequired: false,
                    lastNameLabel: "Last name",
                    lastNameEnabled: false,
                    lastNameRequired: false,
                    locationLabel: "Location",
                    locationEnabled: false,
                    locationRequired: false,
                    phoneLabel: "Phone",
                    phoneEnabled: false,
                    phoneRequired: false,
                    dateLabel: "Date",
                    dateEnabled: false,
                    dateRequired: false,
                    options: "",
                    optionsLabel: "Options",
                    optionsEnabled: false,
                    optionsRequired: false,
                    multiOptions: false,
                    emailHeader: 'Your email',
                    emailHeaderEnabled: true,
                    emailHeaderRequired: true,
                    formHeader: 'How can we help?',
                    formHeaderEnabled: true,
                    formHeaderRequired: false,
                    successMessage: "Your message has been sent. Thank you!",
                    redirectUrl: "",
                },
                link: {
                    __typename: "LinkForm",
                    enabled: false,
                    buttonText: '',
                    redirectTo: ''
                },
                calendly: {
                    __typename: "CalendlyForm",
                    enabled: false,
                    buttonText: '',
                    redirectTo: ''
                },
                phone: {
                    __typename: "PhoneForm",
                    enabled: false,
                    buttonText: '',
                    redirectTo: ''
                }
            },
            styling: {
                __typename: "SiteStyling",
                primaryText: '#212121',
                buttons: '#212121',
                header: '#FAFAFA',
                footerBackground: '#212121',
                footerText: '#FAFAFA',
                background: '#FAFAFA',
                tilesBackground: '#FFFFFF'
            },
            layout: {
                __typename: "SiteLayout",
                siteTitle: "title",
                sectionTitles: true,
                headerType:"cover",
                contentAlign:"left",
                buttonShape:"pill",
                density:"full"
              }
        },
        id: '',
        businessId: '',
        name: '',
        lastDeploymentId: '',
        lastDeployment: null,
        createdAt: '',
        updatedAt: '',
    }