// @ts-nocheck
import {addOne, fetched, isFetching, remove, select, updateAll, updateOne} from "./businessFeature";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinessFeatures} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {createBusinessFeature, deleteBusinessFeature, updateBusinessFeature} from "../../../graphql/mutations";
import {BusinessFeature} from "../../../API";

export const getBusinessFeatures = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listBusinessFeatures, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBusinessFeatures.items))
                const {businessFeature} = getState();
                if (!businessFeature.selected && businessFeature.businessFeatures.size !== 0) {
                    dispatch(select(businessFeature.businessFeatures[0]?.id))
                }
            })
    }
}

export const updateBusinessFeatureAction = (
    newBusinessFeature: BusinessFeature) => {
    return async (dispatch, getState) => {
        const {businessFeature} = await getState();
        if (!businessFeature.isFetching) {
            dispatch(isFetching())
            try {
                delete newBusinessFeature.createdAt
                delete newBusinessFeature.updatedAt
                await API.graphql(graphqlOperation(updateBusinessFeature, {
                    input: newBusinessFeature
                }))
                await dispatch(updateOne(newBusinessFeature))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createBusinessFeatureAction = (
    businessId: string,
    newBusinessFeature: BusinessFeature,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {businessFeature} = await getState();
        if (!businessFeature.isFetching || force) {
            dispatch(isFetching())
            try {
                newBusinessFeature.id = uuidv4();
                newBusinessFeature.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createBusinessFeature, {
                    input: newBusinessFeature
                }))
                await dispatch(addOne(newBusinessFeature))
                if (!businessFeature.selected)
                    dispatch(select(fromApi?.data?.createBusinessFeature.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}


export const deleteBusinessFeatureAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {businessFeature} = await getState();
        if (!businessFeature.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteBusinessFeature, {
                    input: {id: id}
                }))
                await dispatch(remove(id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}