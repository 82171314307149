import {Box, FormHelperText, TextField,} from "@mui/material";
import {Field, useField} from "formik";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";
import DateAdapter from '@mui/lab/AdapterMoment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {LocalizationProvider} from "@mui/lab";
import {FieldAttributes} from "formik/dist/Field";
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from "moment";

const DatePicker = (props: {
                        name: string,
                        text: string,
                        placeholder?: string,
                        helperText?: (value: any) => string,
                        transformField?: (value: any) => any,
                        minDate?: any
                    }
) => {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props.name);

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({
               field,
               form,
           }: FieldAttributes<any>) => (
            <>
                <Box sx={{
                    display: 'flex',
                }}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <MobileDatePicker
                            inputFormat="yyyy-MM-DD"
                            value={field.value}
                            minDate={props.minDate}
                            onChange={(change) => helpers.setValue(moment(change).format("yyyy-MM-DD"))}
                            renderInput={(params) =>
                                <TextField {...params}
                                           variant="outlined"
                                           size={"small"}
                                           sx={{
                                               flexGrow: 1,
                                               justifySelf: 'center',
                                               backgroundColor: 'white',
                                           }}
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <CalendarMonthIcon/>
                                                   </InputAdornment>
                                           }}
                                />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                </Box>
            </>
        )}</Field>
    </FieldHorizontalWrapper>
}


export default DatePicker;