// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BusinessFaq} from "../../../API";
import {RootState} from "../../store";

interface BusinessFaqState {
    isFetching: boolean,
    businessFaqs?: [BusinessFaq],
    selected?: string
}

const initialState: BusinessFaqState = {
    isFetching: false,
}

const sort = (first, second) => first.order - second.order

export const businessFaqSlice = createSlice({
    name: 'businessFaq',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[BusinessFaq]>) => {
            state.businessFaqs = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<BusinessFaq>) => {
            state.businessFaqs = state.businessFaqs
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<BusinessFaq>) => {
            state.businessFaqs.push(action.payload)
            state.businessFaqs.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.businessFaqs = state.businessFaqs
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = businessFaqSlice.actions
export const selectedBusinessFaq = (state: RootState) => state.businessFaq.selected
export const allBusinessFaqs = (state: RootState) => state.businessFaq.businessFaqs
export const businessFaqsIsFetching = (state: RootState) => state.businessFaq.isFetching

export default businessFaqSlice.reducer