import * as React from 'react';
import Container from '@mui/material/Container';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import ProductForm from "../../../molecules/product-form/ProductForm";
import {useDispatch, useSelector} from "react-redux";
import {allProducts} from "../../../../redux/features/product/product";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {updateTutorialStatus} from "../../../../redux/features/business/tutorialActions";


const EditProductPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const products = useSelector(allProducts);
    const dispatch = useDispatch();
    const business = useSelector(selectedBusiness)

    return <Container>
        <DashboardHeader title={"Product"}
                         backTo={"/dashboard/products"}
        />

        <CardFormWrapper>
            <ProductForm product={
                products?.find((product) => product.id === id)
            }
                         afterSubmit={() => {
                             updateTutorialStatus(business, dispatch, 'productsOrSolutions');
                             navigate('/dashboard/products')
                         }}
                // @ts-ignore
                         defaultOrder={products?.reduce(
                             // @ts-ignore
                             (max, sol) => (sol?.order > max ? sol?.order : max),
                             products[0]?.order
                         ) + 1 || 1}/>
        </CardFormWrapper>
    </Container>
}


export default EditProductPage