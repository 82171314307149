import Box from "@mui/material/Box";
import TopBar from "../atoms/top-bar/TopBar";
import {useState} from "react";
import {styled} from '@mui/material/styles';
import SideToolbar from "../molecules/side-toolbar/SideToolbar";
import {Outlet} from "react-router-dom";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    [theme.breakpoints.up('md')]: {
        marginLeft: 20
    },
    padding: theme.spacing(1)
}));


const DashboardLayout = () => {
    const [expandMenu, setExpandMenu] = useState(false)

    return <Box sx={{
        backgroundColor: 'common.white',
        display: {md: 'flex'},
        justifyContent: 'center',
    }}>
        <Box sx={{
            display: {xs: 'block', md: 'none', lg: 'none'}
        }}>
            <TopBar onMenuClick={() => {
                setExpandMenu(!expandMenu)
            }}/>
        </Box>
        {expandMenu &&
            (
                <>
                    <Box sx={{
                        display: {xs: 'block', md: 'none', lg: 'none'}
                    }}>
                        <SideToolbar fullHeight={false}/>
                    </Box>
                </>
            )
        }
        <Box sx={{
            marginTop: '-65px',
            display: {xs: 'none', md: 'block', lg: 'block'}
        }}>
            <SideToolbar fullHeight={true}/>
        </Box>
        <div style={{width: "100%"}}>
            <Box sx={{
                mt: {xs: 0, md: 5}
            }}
            >
                <Box sx={{
                    maxWidth: '1200px',
                    margin: 'auto'
                }}>
                    <Main open={expandMenu}>
                        <Box sx={{
                            display: {xs: expandMenu ? 'none' : 'block', md: 'none', lg: 'none'}
                        }}>
                            <DrawerHeader/>
                        </Box>
                        <Box>
                            <Outlet/>
                        </Box>
                    </Main>
                </Box>
            </Box>
        </div>
    </Box>
}

export default DashboardLayout;