import * as React from 'react';
import {useEffect} from 'react';
import {Container} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {selectedSite} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import SiteHeader from "../../../molecules/site-header/SiteHeader";
import {useNavigate} from "react-router-dom";
import CardContainer from "../../../atoms/card/CardContainer";


const SitePage = () => {

    const site = useSelector(selectedSite);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (business) {
            dispatch(getSites(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business])


    return <Container>
        <SiteHeader title={"Site"}/>
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                [
                    <ClickableCard
                        title={"🗺️ Domain"}
                        text={"Here you can set and modify the address of your website"}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/custom-domain/' + site?.id)}/>,
                    <ClickableCard
                        title={"📩 Site Actions"}
                        text={"Valuable actions you want customers to take on your site (ex. send you a form or call you)."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/functions')}/>,
                    <ClickableCard
                        title={"🎨 Theme"}
                        text={"Branding, colors, typography, and other styling adjustments."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/site/' + site?.id + '/styling')}/>
                ]}/>
    </Container>
}


export default SitePage