export const listCustomerPersonasEager = /* GraphQL */ `
    query ListCustomerPersonasEager(
        $filter: ModelCustomerPersonaFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCustomerPersonas(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                
                businessId
                name
                age
                gender
                interests {
                    items {
                        id
                        interest {
                            id
                            name
                            googleAffinityAudiences
                            googleInMarketAudiences
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                locations {
                    items {
                        id
                        geoLocation {
                            id
                            name
                            countryCode
                            targetType
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listSitesEager = /* GraphQL */ `
    query ListSitesEager(
        $filter: ModelSiteFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                businessId
                name
                live {
                    netlifySiteId
                    netlifyDeploymentId
                    url
                    gtmTag
                    meta {
                        googleAdsAccount
                        googleAdsConversions {
                            ecommerce
                            leadRn
                            leadConversionId
                            leadLabel
                            phone
                        }
                    }
                    functions {
                        actions
                        form {
                            disabled
                            buttonText
                            bottomHeader
                            forwardTo
                            title
                            emailHeader
                            emailHeaderEnabled
                            emailHeaderRequired
                            formHeader
                            formHeaderEnabled
                            formHeaderRequired
                            firstNameLabel
                            firstNameEnabled
                            firstNameRequired
                            lastNameLabel
                            lastNameEnabled
                            lastNameRequired
                            locationLabel
                            locationEnabled
                            locationRequired
                            phoneLabel
                            phoneEnabled
                            phoneRequired
                            dateLabel
                            dateEnabled
                            dateRequired
                            options
                            optionsLabel
                            optionsEnabled
                            optionsRequired
                            multiOptions
                            successMessage
                            redirectUrl
                            url
                        }
                        link {
                            enabled
                            buttonText
                            redirectTo
                        }
                        calendly {
                            enabled
                            buttonText
                            redirectTo
                        }
                        phone {
                            enabled
                            buttonText
                            redirectTo
                        }
                    }
                    styling {
                        primaryText
                        buttons
                        header
                        footerBackground
                        footerText
                        background
                        tilesBackground
                    }
                    layout {
                        siteTitle
                        sectionTitles
                        headerType
                        contentAlign
                        buttonShape
                        density
                    }
                }
                lastDeployment {
                    id
                    deploymentStatus
                    createdAt
                }
            }
            nextToken
        }
    }
`;

//export const searchLeadStatistics = /* GraphQL */ `
  //  query SearchLeadStatistics(
    //    $filter: SearchableLeadFilterInput
    //) {
      //  searchLeads(
        //    filter: $filter
        // ) {
           // total
        // }
    // }
// `;


export const listMarketingCampaignsEager = /* GraphQL */ `
    query ListMarketingCampaignsEager(
        $filter: ModelMarketingCampaignFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listMarketingCampaigns(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                businessId
                customerPersona {
                    name
                }
                site {
                    id
                    name
                    live {
                        url
                    }
                }
                objective
                provider
                runs {
                    items {
                        id
                        startDate
                        endDate
                    }
                    nextToken
                }
                createdAt
                updatedAt
                marketingCampaignCustomerPersonaId
                marketingCampaignSiteId
            }
            nextToken
        }
    }
`;


// export const listGeoLocationsCustom = /* GraphQL */ `
//     query ListGeoLocations(
//         $filter: ModelGeoLocationFilterInput
//         $limit: Int
//         $nextToken: String
//     ) {
//         listGeoLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
//             items {
//                 name
//                 targetType
//                 countryCode
//                 googleAdsGeoCriteria
//                 id
//             }
//             nextToken
//         }
//     }
// `;