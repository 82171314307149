import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import OnBoardingLayout from "../layouts/OnBoardingLayout";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../redux/features/business/business";
import {useCallback, useEffect} from "react";
import {getBusinessAction} from "../../redux/features/business/businessActions";
import OnBoardingCustomers from "../pages/on-boarding/customers/OnBoardingCustomers";
import OnBoardingSites from "../pages/on-boarding/sites/OnBoardingSites";
import OnBoardingCampaignPage from "../pages/on-boarding/campaigns/OnBoardingCampaignPage";
import {OnboardingStatus} from "../../API";
import OnBoardingBrandPage from "../pages/on-boarding/brand/OnBoardingBrandPage";
import DashboardLayout from "../layouts/DashboardLayout";
import BrandPage from "../pages/dashboard/brand/BrandPage";
import CustomerPersonaPage from "../pages/dashboard/customer-persona/CustomerPersonaPage";
import WelcomePage from "../pages/on-boarding/welcome/WelcomePage";
import OnBoardingSiteSummary from "../pages/on-boarding/sites/OnBoardingSiteSummary";
import SitePage from "../pages/dashboard/site/SitePage";
import BusinessPage from "../pages/dashboard/business/BusinessPage";
import ProductsPage from "../pages/dashboard/products/ProductsPage";
import EditProductPage from "../pages/dashboard/products/EditProductPage";
import BusinessSolutionsPage from "../pages/dashboard/business-solution/BusinessSolutionsPage";
import EditBusinessSolutionPage from "../pages/dashboard/business-solution/EditBusinessSolutionPage";
import BusinessPartnersPage from "../pages/dashboard/business-partner/BusinessPartnersPage";
import EditBusinessPartnerPage from "../pages/dashboard/business-partner/EditBusinessPartnerPage";
import BusinessFaqsPage from "../pages/dashboard/business-faq/BusinessFaqsPage";
import EditBusinessFaqPage from "../pages/dashboard/business-faq/EditBusinessFaqPage";
import BusinessFeaturesPage from "../pages/dashboard/business-feature/BusinessFeaturesPage";
import EditBusinessFeaturePage from "../pages/dashboard/business-feature/EditBusinessFeaturePage";
import BusinessStepsPage from "../pages/dashboard/business-step/BusinessStepsPage";
import EditBusinessStepPage from "../pages/dashboard/business-step/EditBusinessStepPage";
import BusinessTestimonialsPage from "../pages/dashboard/business-testimonial/BusinessTestimonialsPage";
import EditBusinessTestimonialPage from "../pages/dashboard/business-testimonial/EditBusinessTestimonialPage";
import EditCustomDomainPage from "../pages/dashboard/custom-domain/EditCustomDomainPage";
import EditCustomerPersonaPage from "../pages/dashboard/customer-persona/EditCustomerPersonaPage";
import CustomersPage from "../pages/dashboard/customers/CustomersPage";
import OverviewPage from "../pages/dashboard/overview/OverviewPage";
import MarketingCampaignPage from "../pages/dashboard/campaigns/MarketingCampaignPage";
import EditMarketingCampaignPage from "../pages/dashboard/campaigns/EditMarketingCampaignPage";
import SiteContactFormPage from "../pages/dashboard/site-functions/SiteContactFormPage";
import SiteFunctionsPage from "../pages/dashboard/site-functions/SiteFunctionsPage";
import SiteLinkPage from "../pages/dashboard/site-functions/SiteLinkPage";
import SiteCalendlyPage from "../pages/dashboard/site-functions/SiteCalendlyPage";
import SitePhonePage from "../pages/dashboard/site-functions/SitePhonePage";
import SiteStylingPage from "../pages/dashboard/site-styling/SiteStylingPage";

const EvergrowRouter = () => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    const c = useCallback(() => {
        dispatch(getBusinessAction())
    }, [dispatch])

    useEffect(() => {
        c()
    }, [dispatch, c])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<>{
                    business?.onboardingStatus === OnboardingStatus.Done ? <Navigate to="/dashboard"/>
                        : business?.onboardingStatus !== undefined ? <Navigate to="/onboarding"/>
                            : <></>
                }
                </>}>
                </Route>
                <Route path="/onboarding" element={<OnBoardingLayout/>}
                >
                    <Route index element={<>{
                        (business?.onboardingStatus === OnboardingStatus.Done && <Navigate to="/dashboard"/>)
                        || (business?.onboardingStatus === OnboardingStatus.Brand && <Navigate to="/onboarding/brand"/>)
                        || (business?.onboardingStatus === OnboardingStatus.Customers &&
                            <Navigate to="/onboarding/customers"/>)
                        || (business?.onboardingStatus === OnboardingStatus.Site && <Navigate to="/onboarding/site"/>)
                        || (business?.onboardingStatus === OnboardingStatus.Campaign &&
                            <Navigate to="/onboarding/campaign"/>)
                        || (business?.onboardingStatus === OnboardingStatus.Welcome &&
                            <Navigate to="/onboarding/welcome"/>)
                        || (<Navigate to="/onboarding/welcome"/>)
                    }
                    </>
                    }
                    />
                    <Route path="welcome" element={<WelcomePage/>}/>
                    <Route path="brand" element={<OnBoardingBrandPage/>}/>
                    <Route path="customers" element={<OnBoardingCustomers/>}/>
                    <Route path="site" element={<OnBoardingSites/>}/>
                    <Route path="site-summary" element={<OnBoardingSiteSummary/>}/>
                    <Route path="campaign" element={<OnBoardingCampaignPage/>}/>
                </Route>
                <Route path="/dashboard" element={<DashboardLayout/>}>
                    <Route index element={<Navigate to="/dashboard/overview"/>}/>
                    <Route path="overview" element={<OverviewPage/>}/>
                    <Route path="business" element={<BusinessPage/>}/>
                    <Route path="site" element={<SitePage/>}/>
                    <Route path="campaigns" element={<BrandPage/>}/>

                    <Route path="brand" element={<BrandPage/>}/>

                    <Route path="customers" element={<CustomersPage/>}/>
                    <Route path="customer-personas" element={<CustomerPersonaPage/>}/>
                    <Route path="customer-personas/:id" element={<EditCustomerPersonaPage/>}/>
                    <Route path="marketing-campaign" element={<MarketingCampaignPage/>}/>
                    <Route path="marketing-campaign/:id" element={<EditMarketingCampaignPage/>}/>


                    <Route path="products" element={<ProductsPage/>}/>
                    <Route path="products/:id" element={<EditProductPage/>}/>

                    <Route path="business-solutions" element={<BusinessSolutionsPage/>}/>
                    <Route path="business-solutions/:id" element={<EditBusinessSolutionPage/>}/>
                    <Route path="business-partners" element={<BusinessPartnersPage/>}/>
                    <Route path="business-partners/:id" element={<EditBusinessPartnerPage/>}/>
                    <Route path="business-faqs" element={<BusinessFaqsPage/>}/>
                    <Route path="business-faqs/:id" element={<EditBusinessFaqPage/>}/>
                    <Route path="business-features" element={<BusinessFeaturesPage/>}/>
                    <Route path="business-features/:id" element={<EditBusinessFeaturePage/>}/>
                    <Route path="business-steps" element={<BusinessStepsPage/>}/>
                    <Route path="business-steps/:id" element={<EditBusinessStepPage/>}/>
                    <Route path="testimonials" element={<BusinessTestimonialsPage/>}/>
                    <Route path="testimonials/:id" element={<EditBusinessTestimonialPage/>}/>
                    <Route path="custom-domain/:id" element={<EditCustomDomainPage/>}/>
                    <Route path="site/:id/functions" element={<SiteFunctionsPage/>}/>
                    <Route path="site/:id/contact-form" element={<SiteContactFormPage/>}/>
                    <Route path="site/:id/link" element={<SiteLinkPage/>}/>
                    <Route path="site/:id/calendly" element={<SiteCalendlyPage/>}/>
                    <Route path="site/:id/phone" element={<SitePhonePage/>}/>
                    <Route path="site/:id/styling" element={<SiteStylingPage/>}/>
                </Route>

            </Routes>
        </BrowserRouter>
    )
}

export default EvergrowRouter;