// @ts-nocheck
import {fetched, isFetching, select, updateAll} from "./brand";
import {API, graphqlOperation} from "aws-amplify";
import {listBrands} from "../../../graphql/queries";
import {v4 as uuidv4} from 'uuid';
import {createBrand, updateBrand} from "../../../graphql/mutations";
import {Brand} from "../../../API";

export const getBrands = (businessId: string) => {
    return async (dispatch: (arg0: { payload: string | [Brand]; type: string; }) => void, getState: () => { brand: any; }) => {
        return API.graphql(graphqlOperation(listBrands, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBrands.items))
                const {brand} = getState();
                if (!brand.selected && brand.brands.size !== 0) {
                    dispatch(select(brand.brands[0]?.id))
                }
            })
    }
}

export const updateBrandAction = (
    newBrand: Brand) => {
    return async (dispatch, getState) => {
        const {brand} = await getState();
        if (!brand.isFetching) {
            dispatch(isFetching())
            const brand = Object.assign({}, newBrand)
            if (brand.createdAt)
                delete brand.createdAt
            if (brand.updatedAt)
                delete brand.updatedAt
            API.graphql(graphqlOperation(updateBrand, {
                input: brand
            })).then((fromApi) => {
                dispatch(getBrands(brand.businessId))
            }).finally(() => {
                dispatch(fetched())
            })
        }
    }
}

export const createBrandAction = (
    businessId: string,
    newBrand: Brand,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {brand} = await getState();
        if (!brand.isFetching || force) {
            dispatch(isFetching())
            newBrand.id = uuidv4();
            newBrand.businessId = businessId;
            API.graphql(graphqlOperation(createBrand, {
                input: newBrand
            }))
                .then((fromApi) => {
                    dispatch(getBrands(businessId))
                    if (!brand.selected)
                        dispatch(select(fromApi?.data?.createBrand.id))
                }).finally(() => {
                dispatch(fetched())
            })
        }
    }
}