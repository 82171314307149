const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90wv',
        md: '30vw'
    },
    maxWidth: 350,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    p: 4,
    display: 'flex',
    justifyContent: 'center',
};

export default style