import { useState } from "react";
import { Site } from "../../../API";

import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { FormikErrors } from "formik/dist/types";

import LabeledField from "../../atoms/labeled-field/LabeledField";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import LabeledCheckboxField from "../../atoms/labeled-field/LabeledCheckboxField";

import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../redux/features/business/business";
import { initialValues } from "../../../redux/features/site/initialValues";
import { updateSiteAction } from "../../../redux/features/site/siteActions";

import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import WaitingModal from "../../atoms/modal/WaitingModal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const validationSchema = Yup.object({
    live: Yup.object().shape({
        functions: Yup.object().shape({
            form: Yup.object().shape({
                buttonText: Yup.string().required(),
                title: Yup.string().required(),
                //emailHeader: Yup.string().required(),
                formHeader: Yup.string().required(),
                forwardTo: Yup.string().email("Email must have correct format").required("Your email is required"),
            })
        })
    })
})

type Props = {
    site?: Site,
    afterSubmit: () => void
};


const BusinessStepFields = (props: { businessId?: string, afterSubmit: () => void }) => {

    const { submitForm, setFieldTouched, validateForm } = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['live',].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <Stack spacing={2} divider={<Divider flexItem />} textAlign="left">
        <Stack>
            <Typography variant="h3">Basics</Typography>
            <Typography color="gray">Edit basic settings like show/hide, email used for notifications, and the call to action at the end of the page.</Typography>
            <Box sx={{ width: '100%', maxWidth: '380px', margin: 'auto', pt: 2 }}>
                <LabeledCheckboxField
                    key='disabled'
                    name='live.functions.form.disabled' text='Disable the form'
                    placeholder=''
                />

                <LabeledFieldWithMaxLength
                    key='bottomHeader'
                    name='live.functions.form.bottomHeader' text='Footer call to action'
                    placeholder=''
                    maxLength={80} />
                <LabeledFieldWithMaxLength
                    key='title'
                    name='live.functions.form.title' text='Form title'
                    placeholder='Contact us'
                    maxLength={100} />
                <LabeledFieldWithMaxLength
                    key='buttonText'
                    name='live.functions.form.buttonText' text='Button text'
                    placeholder='Send'
                    maxLength={30} />
                <LabeledField
                    key={'forward-to'}
                    name='live.functions.form.forwardTo'
                    text='Forward leads to'
                    placeholder='ex. sales@acmeinc.com' />
<LabeledField
                    key={'gtmTag'}
                    name='live.gtmTag'
                    text='Google Tag Manager ID (Advanced)'
                    placeholder='ex. GTM-1234567' />
                    
            </Box>
        </Stack>
        <Stack>
            <Stack>
            <Typography variant="h3">Form fields</Typography>
            <Typography color="gray">Edit the labels on your form fields and the text to show in the send button.</Typography>
            </Stack>
            <Stack alignSelf={"center"} spacing={3} py={4} sx={{ width: '100%', maxWidth: '380px'}}>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <AccountCircleOutlinedIcon />
                        <Typography variant="h4">First name</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='firstNameEnabled'
                                name='live.functions.form.firstNameEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='firstNameRequired'
                                name='live.functions.form.firstNameRequired' text='Required?'
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <AccountCircleOutlinedIcon />
                        <Typography variant="h4">Last name</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='lastNameEnabled'
                                name='live.functions.form.lastNameEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='lastNameRequired'
                                name='live.functions.form.lastNameRequired' text='Required?'
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <MailOutlinedIcon />
                        <Typography variant="h4">Email</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='emailHeaderEnabled'
                                name='live.functions.form.emailHeaderEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='emailHeaderRequired'
                                name='live.functions.form.emailHeaderRequired' text='Required?'
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <LocalPhoneOutlinedIcon />
                        <Typography variant="h4">Phone</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='phoneEnabled'
                                name='live.functions.form.phoneEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='phoneRequired'
                                name='live.functions.form.phoneRequired' text='Required?'
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <LocationOnOutlinedIcon />
                        <Typography variant="h4">Location</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='locationEnabled'
                                name='live.functions.form.locationEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='locationRequired'
                                name='live.functions.form.locationRequired' text='Required?'
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={2} px={3} pt={3} sx={{ border: 1, borderRadius: 2, borderColor: 'lightGray' }}>
                    <Stack direction={"row"} spacing={1}>
                        <MessageOutlinedIcon />
                        <Typography variant="h4">Message</Typography>
                    </Stack>
                    <Stack>
                        <Stack direction={"row"} spacing={2}>
                            <LabeledCheckboxField
                                key='formHeaderEnabled'
                                name='live.functions.form.formHeaderEnabled' text='Enabled?'
                            />
                            <LabeledCheckboxField
                                key='formHeaderRequired'
                                name='live.functions.form.formHeaderRequired' text='Required?'
                            />
                        </Stack>
                        
                        <LabeledFieldWithMaxLength
                        key='formHeader'
                        name='live.functions.form.formHeader' text='Field label'
                        placeholder='Message'
                        maxLength={20}/>
                    </Stack>
                </Stack>
            </Stack>
            <ButtonHolder
                textRight={"Save"}
                textLeft={"Delete"}
                justify={'right'}
                submitRight={
                    () => {
                        handleSubmit()
                            .then((errors) => {
                                if (!errors)
                                    props.afterSubmit()
                            })
                    }} />
        </Stack>

    </Stack>
}

const SiteContactForm = ({ site, afterSubmit }: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => {
        setOpen(false);
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={site || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values: Site) => {
                openModal()
                if (business) {
                    try {
                        await dispatch(updateSiteAction(business.id, values))
                    } finally {
                        closeModal()
                        afterSubmit()
                    }
                }
            }}>
            <Form>
                <BusinessStepFields businessId={business?.id} afterSubmit={afterSubmit} />
            </Form>
        </Formik>
        {open && <WaitingModal text={'Please wait we are updating the site'} />}
    </>
}

export default SiteContactForm