import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import {FormikErrors} from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import {start} from "../../../redux/features/marketingCampaign/marketingCampaignActions";
import {useDispatch, useSelector} from "react-redux";
import {isCreatingRunSelector} from "../../../redux/features/marketingCampaign/marketingCampaign";
import DatePicker from "../../atoms/date-picker/DatePicker";

import moment from "moment";
import {MarketingCampaignRun} from "../../../API";
import CampaignCost from "../../atoms/campaign-cost/CampaignCost";
import {useState} from "react";
import ErrorBox from "../../atoms/errors/ErrorBox";

const validationSchema = Yup.object({
    startDate: Yup.date().required("This is a required field"),
    endDate: Yup.date().required("This is a required field")
        .min(Yup.ref('startDate'),
            "end date can't be before start date"
        ),
    dailyBudget: Yup.number().required("This is a required field"),
})

type Props = {
    businessId: string,
    marketingCampaignId: string,
    afterSubmit: () => void
};


const MarketingCampaignStartFields = (props: { businessId?: string, afterSubmit: () => void , errors: [] }) => {


    const isCreating = useSelector(isCreatingRunSelector)
    const {submitForm, setFieldTouched, validateForm, values} = useFormikContext<MarketingCampaignRun>();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['startDate', 'endDate', 'dailyBudget'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            // @ts-ignore
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <>
        <DatePicker
            key={'startDate'}
            name='startDate' text='Start Date'
            placeholder=''
            minDate={moment().add(1, 'days')}
        />
        <DatePicker
            key={'endDate'}
            name='endDate' text='End Date'
            placeholder=''
            minDate={moment().add(1, 'days')}
        />
        <LabeledField
            key={'dailyBudget'}
            name='dailyBudget' text='Daily budget'
            endAdornment={<>$</>}
        />
        <CampaignCost startDate={values.startDate} endDate={values.endDate} dailyBudget={values.dailyBudget}/>
        <ErrorBox errors={props.errors}/>
        <ButtonHolder
            textRight={"Start"}
            textLeft={"Close"}
            justify={'right'}
            submitRight={
                () => {
                    handleSubmit()
                        .then((errors) => {
                        })
                }}
            submitLeft={() => {
                props.afterSubmit()
            }}
            disabledRight={isCreating}

        />
    </>
}


const MarketingCampaignStartForm = ({businessId, marketingCampaignId, afterSubmit}: Props) => {

    const dispatch = useDispatch();
    const [errors, setErrors] = useState<[]>([]);

    return <Formik
        enableReinitialize={true}
        initialValues={{
            businessId: businessId,
            marketingCampaignId: marketingCampaignId,
            startDate: moment().add(1, 'days').format("yyyy-MM-DD"),
            endDate: moment().add(8, 'days').format("yyyy-MM-DD"),
            dailyBudget: 100
        }
        }
        validationSchema={validationSchema}
        onSubmit={async (values) => {
            await dispatch(start(values))
                // @ts-ignore
                .then((response) => {
                    console.log(response)
                    afterSubmit()
                })
                .catch(
                    // @ts-ignore
                    (err) => {
                        console.log(err)
                        setErrors(err)
                    })
        }}>
        <Form>
            <MarketingCampaignStartFields businessId={businessId} afterSubmit={afterSubmit} errors={errors}/>
        </Form>
    </Formik>
}

export default MarketingCampaignStartForm