import {Box, CircularProgress} from "@mui/material";
import * as React from 'react';
import Modal from '@mui/material/Modal';
import style from "./ModalStyle";
import Typography from '@mui/material/Typography';


const WaitingModal = (props: { text?: string }) => {
    return <Modal
        open={true}
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <CircularProgress size={'50px'} color={'secondary'}/>
                </Box>
                <Box id="modal-modal-description">
                    <Typography variant="h5" align="center">
                    {props.text || 'Please wait'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Modal>
}

export default WaitingModal