import * as React from 'react';
import { useEffect } from 'react';
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../../redux/features/business/business";
import { selectedSite } from "../../../../redux/features/site/site";
import { getBusinessAction } from "../../../../redux/features/business/businessActions";
import ClickableCard, { CardState } from "../../../atoms/card/ClickableCard";
import { useNavigate } from "react-router-dom";
import CardContainer from "../../../atoms/card/CardContainer";
import { getBrands } from "../../../../redux/features/brand/brandActions";
import { getProducts } from "../../../../redux/features/product/productActions";
import { getBusinessSolutions } from "../../../../redux/features/businessSolution/businessSolutionActions";
import { getBusinessPartners } from "../../../../redux/features/businessPartner/businessPartnerActions";
import { getBusinessFaqs } from "../../../../redux/features/businessFaq/businessFaqActions";
import { getBusinessFeatures } from "../../../../redux/features/businessFeature/businessFeatureActions";
import { getBusinessSteps } from "../../../../redux/features/businessStep/businessStepActions";
import { getBusinessTestimonials } from "../../../../redux/features/businessTestimonial/businessTestimonialActions";
import ClosableInfoCard from "../../../molecules/info-card/ClosableInfoCard";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import image from "../../../../images/illustrations/blocks.png";


const tips = ["Work on your brand, your products, features, testimonials, and more.",
    'Add as many items as you need to help your customers understand who you are and what you do.',
]

const BusinessPage = () => {
    const navigate = useNavigate();
    const selected = useSelector(selectedSite);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business) {
            dispatch(getBrands(business.id))
            dispatch(getProducts(business.id))
            dispatch(getBusinessSolutions(business.id))
            dispatch(getBusinessFaqs(business.id))
            dispatch(getBusinessFeatures(business.id))
            dispatch(getBusinessSteps(business.id))
            dispatch(getBusinessTestimonials(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business, selected])


    return <Container>
        <DashboardHeader title={"Business"}
            underTitle={
                <ClosableInfoCard
                    code={'Business'}
                    header={'Build your business using blocks'}
                    items={tips}
                    image={image}
                    buttonLink={'/dashboard/products'}
                    buttonText={'Add a product'}
                    textColor="#6b21a8"
                    backgroundColor='#e9d5ff'
                />}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                [<ClickableCard
                    title={"❤️ Brand"}
                    text={"Your brand name, logo, and other basic information that makes your business unique."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/brand')}
                />,
                <ClickableCard
                    title={"📦 Products"}
                    text={"Physical or virtual items that people can purchase online or in-store."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/products')}
                />,
                <ClickableCard
                    title={"✨ Solutions"}
                    text={"A product or service designed to solve a need or a problem."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/business-solutions')}
                />,
                <ClickableCard
                    title={"🤝 Partners"}
                    text={"Clients, brands or organizations that you work with."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/business-partners')}
                />,
                <ClickableCard
                    title={"👣 How it works"}
                    text={"The steps that your customers take to buy your product or service."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/business-steps')}
                />,
                <ClickableCard
                    title={"👀 Features"}
                    text={"Key highlights of your products, services, and solutions."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/business-features')}
                />,
                <ClickableCard
                    title={"🗣 Testimonials"}
                    text={"Customer feedback about your business and products."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/testimonials')}
                />,
                <ClickableCard
                    title={"❓️ FAQs"}
                    text={"Quick answers to the most common questions your customers have."}
                    status={CardState.Blank}
                    onClick={() => navigate('/dashboard/business-faqs')}
                />
                ]
            } />

    </Container>
}


export default BusinessPage