import * as React from 'react';
import {useEffect} from 'react';
import {Container} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getBrands} from "../../../../redux/features/brand/brandActions";
import {selectedBusiness} from "../../../../redux/features/business/business";
import SiteHeader from "../../../molecules/site-header/SiteHeader";
import CardContainer from "../../../atoms/card/CardContainer";
import TutorialCard from "../../../atoms/tutorial/TutorialCard";
import {Site, Tutorial} from "../../../../API";
import {selectedSite} from "../../../../redux/features/site/site";
import BudgetCard from "../../../atoms/budget/BudgetCard";
import OverviewStatistics from "../../../organisms/overview-statistics/OverviewStatistics";
import {getProducts} from "../../../../redux/features/product/productActions";
import {getBusinessSolutions} from "../../../../redux/features/businessSolution/businessSolutionActions";
import {getBusinessSteps} from "../../../../redux/features/businessStep/businessStepActions";
import {getBusinessTestimonials} from "../../../../redux/features/businessTestimonial/businessTestimonialActions";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";
import {getSites} from "../../../../redux/features/site/siteActions";
import Stack from "@mui/material/Stack";


const OverviewPage = () => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const site = useSelector(selectedSite);

    useEffect(() => {
        if (business) {
            dispatch(getBrands(business.id))
            dispatch(getProducts(business.id))
            dispatch(getBusinessSolutions(business.id))
            dispatch(getBusinessSteps(business.id))
            dispatch(getBusinessTestimonials(business.id))
            dispatch(getSites(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business])

    return <Container>
        <SiteHeader
            title={"Overview"}
        />
        {business &&
            <>
                <Stack spacing={1}>
                <OverviewStatistics/>
                <CardContainer
                    xsColumns={1}
                    smColumns={2}
                    mdColumns={2}
                    lgColumns={2}
                    cards={[
                        business?.tutorial && !business?.tutorial.closed ?
                            <TutorialCard
                                tutorial={business?.tutorial ? business.tutorial : {} as Tutorial}
                                site={site as Site}
                            /> :
                            <></>
                        ,
                        <BudgetCard/>
                    ]}
                />
                </Stack>
            </>
        }
    </Container>
}


export default OverviewPage