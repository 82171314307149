import {updateBrandAction} from "../../../redux/features/brand/brandActions";
import {Brand} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import UploadField from "../upload-field/UploadField";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import {FormikErrors} from "formik/dist/types";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import TwoColumn from "../../atoms/two-column/TwoColumn";
import SelectWithAddition from "../../atoms/option-field/SelectWithAddition";

const validationSchema = Yup.object({
    name: Yup.string().required("This is a required field"),
    briefDescription: Yup.string().required("This is a required field"),
    longDescription: Yup.string().required("This is a required field"),
    keywords: Yup.array().min(3,"Add more keywords."),
})

type Props = {
    brand?: Brand,
    afterSubmit: () => void
};

const initialValues = {
    name: '',
    logo: '',
    icon: '',
    briefDescription: '',
    longDescription: '',
    keywords: '',
    coverImage: ''
}

const BrandBaseFields = (props: { businessId?: string }) => {
    const {submitForm, setFieldTouched, validateForm} = useFormikContext();


    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['name', 'logo', 'icon', 'briefDescription', 'keywords'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }

    return <TwoColumn
        left={[<UploadField key={'logo'}
                            name={'logo'}
                            text={'Logo'}
                            thumbnailSize={168}
                            newFileRoot={'business/' + props.businessId + '/images'}
        />,<UploadField key={'icon'}
        name={'icon'}
        text={'Square Icon'}
        thumbnailSize={168}
        newFileRoot={'business/' + props.businessId + '/images'}
/>,
            <UploadField
                key={'coverImage'}
                name={'coverImage'}
                text={'Cover Image'}
                thumbnailSize={168}
                newFileRoot={'business/' + props.businessId + '/images'}
            />]}
        right={[
            <LabeledField
                key={'name'} name='name' text='Brand' placeholder='ex. Acme Inc.'/>,
            <LabeledFieldWithMaxLength
                key={'briefDescription'} name='briefDescription' text='Tagline'
                placeholder='ex. A Company Making Everything'
                helperText={() => 'What your brand is all about in one sentence.'}
                maxLength={30}/>,
            <LabeledFieldWithMaxLength
                key={'longDescription'} name='longDescription' text='Description'
                placeholder='ex. We sell hammers, tools, anvils, rockets, and TNT...'
                helperText={() => 'Describe what you do or sell.'}
                maxLength={90}/>,
            <SelectWithAddition key='keywords'
                                name='keywords' text='Keywords'
                                helperText={() => 'Enter 3 keywords or more to help people find you.'}
            />]}
        bottom={<ButtonHolder
            justify={'right'}
            textRight={'Save'}
            submitRight={
                () => {
                    handleSubmit()
                        .then((errors) => {
                            // if (!errors)
                            // props.afterSubmit()
                        })
                }}/>}
    />

}


const BrandForm = ({brand, afterSubmit}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            brand ?
                {
                    ...brand
                } : initialValues
        }
        initialTouched={{
            briefDescription: true,
            longDescription: true
        }}

        validationSchema={validationSchema}
        onSubmit={async (values) => {
            await dispatch(updateBrandAction(values as Brand))
            afterSubmit()
        }}>
        <Form>
            <BrandBaseFields businessId={business?.id}/>
        </Form>
    </Formik>
}

export default BrandForm