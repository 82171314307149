// @ts-nocheck
import {addOne, fetched, isFetching, remove, select, updateAll, updateOne} from "./businessTestimonial";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinessTestimonials} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {
    createBusinessTestimonial,
    deleteBusinessTestimonial,
    updateBusinessTestimonial
} from "../../../graphql/mutations";
import {BusinessTestimonial} from "../../../API";

export const getBusinessTestimonials = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listBusinessTestimonials, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBusinessTestimonials.items))
                const {businessTestimonial} = getState();
                if (!businessTestimonial.selected && businessTestimonial.businessTestimonials.size !== 0) {
                    dispatch(select(businessTestimonial.businessTestimonials[0]?.id))
                }
            })
    }
}

export const updateBusinessTestimonialAction = (
    newBusinessTestimonial: BusinessTestimonial) => {
    return async (dispatch, getState) => {
        const {businessTestimonial} = await getState();
        if (!businessTestimonial.isFetching) {
            dispatch(isFetching())
            try {
                delete newBusinessTestimonial.createdAt
                delete newBusinessTestimonial.updatedAt
                await API.graphql(graphqlOperation(updateBusinessTestimonial, {
                    input: newBusinessTestimonial
                }))
                await dispatch(updateOne(newBusinessTestimonial))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createBusinessTestimonialAction = (
    businessId: string,
    newBusinessTestimonial: BusinessTestimonial,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {businessTestimonial} = await getState();
        if (!businessTestimonial.isFetching || force) {
            dispatch(isFetching())
            try {
                newBusinessTestimonial.id = uuidv4();
                newBusinessTestimonial.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createBusinessTestimonial, {
                    input: newBusinessTestimonial
                }))
                await dispatch(addOne(newBusinessTestimonial))
                if (!businessTestimonial.selected)
                    dispatch(select(fromApi?.data?.createBusinessTestimonial.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const deleteBusinessTestimonialAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {product} = await getState();
        if (!product.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteBusinessTestimonial, {
                    input: {id: id}
                }))
                await dispatch(remove(id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}