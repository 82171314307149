import { Product } from '../../../API';
import { Form, Formik, useFormikContext } from 'formik';
import LabeledField from '../../atoms/labeled-field/LabeledField';
import LabeledFieldWithMaxLength from '../../atoms/labeled-field/LabeledFieldWithMaxLength';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectedBusiness } from '../../../redux/features/business/business';
import UploadField from '../upload-field/UploadField';
import {
  createProductAction,
  deleteProductAction,
  updateProductAction,
} from '../../../redux/features/product/productActions';
import { useState } from 'react';
import { FormikErrors } from 'formik/dist/types';
import ButtonHolder from '../../atoms/button-holder/ButtonHolder';
import DeleteModal from '../../atoms/modal/DeleteModal';
import TwoColumn from '../../atoms/two-column/TwoColumn';

const validationSchema = Yup.object({
  name: Yup.string().required('This is a required field'),
  // price: Yup.string().required('This is a required field'),
  description: Yup.string().required('This is a required field'),
  order: Yup.number().required('This is a required field'),
});

type Props = {
    product?: Product;
    afterSubmit: () => void;
    defaultOrder: number;
  };
  
  const ProductFields = (props: { businessId: string; afterSubmit: () => void }) => {
    const { values, submitForm, setFieldTouched, validateForm } = useFormikContext<Product>();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false);
  
    const handleSubmit = async () => {
      ['name', 'description', 'order'].forEach((field) => {
        setFieldTouched(field, true, true);
      });
      const errors = await validateForm();
  
      if (errors && Object.keys(errors).length === 0) {
        await submitForm();
      }
      return errors;
    };

    return (
        <>
          <TwoColumn
            left={[
              <UploadField
                key={'coverImage'}
                name={'coverImage'}
                text={'Cover Image'}
                thumbnailSize={168}
                newFileRoot={'business/' + props.businessId + '/images'}
              />,
            ]}
            right={[
              <LabeledFieldWithMaxLength
                key={'name'}
                name='name'
                text='Name'
                placeholder=''
                maxLength={90}
              />,
              <LabeledField key={'price'} name='price' text='Price' />,
              <LabeledFieldWithMaxLength
                key={'description'}
                name='description'
                text='Description'
                placeholder=''
                maxLength={200}
              />,
              <LabeledField key={'order'} name='order' text='Order on page' />,
            ]}
            bottom={
              <ButtonHolder
                textRight={'Save'}
                textLeft={'Delete'}
                justify={'right'}
                submitRight={async () => {
                  const errors = await handleSubmit();
                  if (!errors) props.afterSubmit();
                }}
                submitLeft={() => {
                  setOpenModal(true);
                }}
              />
            }
          />
          <DeleteModal
            open={openModal}
            closeModal={() => setOpenModal(false)}
            deleteSubmit={async () => {
                await dispatch(deleteProductAction(props.businessId, values.id));
                props.afterSubmit();
              }}              
          />
        </>
      );
    };
    const ProductForm = ({ product, afterSubmit, defaultOrder }: Props) => {
        const business = useSelector(selectedBusiness);
        const dispatch = useDispatch();
      
        return (
          <Formik
            enableReinitialize={true}
            initialValues={
              product
                ? product
                : {
                    name: '',
                    price: '',
                    description: '',
                    coverImage: '',
                    order: defaultOrder,
                  } as Product
            }
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              if (business)
                if (product) {
                  await dispatch(updateProductAction(values));
                } else {
                  await dispatch(createProductAction(business.id, values, false));
                }
            }}
          >
            <Form>
              <ProductFields businessId={business?.id as string} afterSubmit={afterSubmit} />
            </Form>
          </Formik>
        );
      };
      
      export default ProductForm;          