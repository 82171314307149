import Card from "@mui/material/Card";
import * as React from "react";
import Stack from '@mui/material/Stack';

type Props = {
    children: JSX.Element,
};

const CardFormWrapper = ({children}: Props) => {
    return <Card sx={{
        my: 1,
        p: 4,
        borderRadius: 4,
        boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)'
    }}>
        <Stack
            sx={{
                maxWidth: '1112px',
            }}
        >
            {children}
        </Stack>
    </Card>

}

export default CardFormWrapper