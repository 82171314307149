import {Box, Button} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ButtonHolder = (props: {
    textRight?: string,
    textLeft?: string,
    submitRight: () => void,
    submitLeft?: () => void,
    justify: 'center' | 'right',
    disabledRight?: boolean
    nextIcon?: boolean
}) => {
    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap:2
    }}>
        {props.submitLeft &&
            <Button
                sx={{
                    flexGrow: 1 / 4,
                     width: {xs: '100%', sm: '170px'},
                    borderRadius: 100,
                    fontWeight: "bold",
                    py:1,
                    px: 2
                }}
                color={"secondary"}
                onClick={props.submitLeft}
            >
                {props.textLeft ? props.textLeft : 'Back'}
            </Button>
        }
        <Button
            sx={{
                flexGrow: 1 / 4,
                maxWidth: "380px",
                borderRadius: 100,
                backgroundColor: "black",
                fontWeight: "bold",
                py:1,
            }}
            endIcon={props.nextIcon ? <NavigateNextIcon/> : null}
            size="large"
            fullWidth
            variant={"contained"}
            disabled={props.disabledRight? props.disabledRight : false}
            onClick={props.submitRight}
        >
            {props.textRight ? props.textRight : 'Next'}
        </Button>
    </Box>;
}

export default ButtonHolder