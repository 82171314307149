import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {selectedSite} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import SiteLinkForm from "../../../molecules/link-form/SiteLinkForm";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";


const SiteContactFormPage = () => {
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const site = useSelector(selectedSite);

    useEffect(() => {
        if (business) {
            if (!site)
                dispatch(getSites(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business, site])

    return <Container>
        <DashboardHeader title={"Site actions"}
                         backTo={'/dashboard/site/' + site?.id + '/functions'}
        />
        <CardFormWrapper>
            <SiteLinkForm site={site}
                          afterSubmit={() => navigate('/dashboard/site/' + site?.id + '/functions')}
            />
        </CardFormWrapper>
    </Container>


}


export default SiteContactFormPage