import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store";
import {LeadStatistics} from "./leadType";


interface LeadStatisticsState {
    isFetching: boolean,
    leadStatistics?: LeadStatistics
}

const initialState: LeadStatisticsState = {
    isFetching: false,
    leadStatistics: undefined
}


export const leadStatisticsSlice = createSlice({
    name: 'leadStatistics',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<LeadStatistics>) => {
            state.leadStatistics = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {update, isFetching, fetched} = leadStatisticsSlice.actions
export const currentLeadStatistics = (state: RootState) => state.leadStatistics.leadStatistics
export const leadStatisticsIsFetching = (state: RootState) => state.leadStatistics.isFetching

export default leadStatisticsSlice.reducer