// @ts-nocheck
import {API, graphqlOperation} from "aws-amplify";
import {updateBusiness} from "../../../graphql/mutations";
import {TutorialUpdate} from "./tutorialType";
import {updateTutorial} from "./business";
import {Business} from "../../../API";
import {Dispatch} from "react";
import moment from "moment";


export const updateTutorialStatus = (
    business?: Business,
    dispatch: Dispatch<any>,
    field: string,
) => {
    if (business?.tutorial) {
        const newTutorial = Object.assign({}, business.tutorial)
        newTutorial[field] = moment().toISOString()
        dispatch(updateTutorialAction({
            businessId: business.id,
            tutorial: newTutorial
        }))
    }
}


const updateTutorialAction = (update: TutorialUpdate) => {
    return async (dispatch, getState) => {
        await API.graphql(graphqlOperation(updateBusiness, {
            input: {
                id: update.businessId,
                tutorial: update.tutorial
            }
        }))
        await dispatch(updateTutorial(update))
    }
}

