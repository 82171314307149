// @ts-nocheck
import {Autocomplete, Box, createFilterOptions, FormHelperText, TextField} from "@mui/material";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";

import {Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";

const filter = createFilterOptions<string>();
const SelectWithAddition = (props: {
                                name: string,
                                text: string,
                                helperText: () => string
                            }
) => {
    // eslint-disable-next-line
    const {values, setFieldValue} = useFormikContext();

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({field}: FieldAttributes<any>) => (
            <>
                <Autocomplete
                    {...field}
                    options={[]}

                    multiple
                    onChange={(event, newValues) => {
                        const mapped = newValues.map(newValue => {
                            if (typeof newValue === 'string') {
                                return newValue
                            } else if (newValue && newValue.inputValue) {
                                return newValue.inputValue
                            } else {
                                return newValue
                            }
                        })
                        setFieldValue(props.name, mapped);
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const {inputValue} = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    id={"autoselect-with-add" + props.name}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.title;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}

                    freeSolo
                    fullWidth={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size={"small"}
                            placeholder="Start typing"
                            sx={{
                                flexGrow: 1,
                                backgroundColor: 'white',
                                justifySelf: 'center',
                            }}
                        />
                    )}
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                </Box>
            </>
        )}
        </Field>
    </FieldHorizontalWrapper>
}

export default SelectWithAddition