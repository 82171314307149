import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate} from "react-router-dom";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import {selectedSite} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import CustomDomainForm from "../../../molecules/sites-form/CustomDomainForm";

const EditCustomDomainPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const site = useSelector(selectedSite);

    useEffect(() => {
        if (business) {
            dispatch(getSites(business.id))
        }

    }, [dispatch, business])

    return <Container>
            <DashboardHeader title={"Site domain"}
                             backTo={"/dashboard/site"}
            />
        <CardFormWrapper>
            <CustomDomainForm site={site}
                              afterSubmit={() => navigate('/dashboard/site')}
            />
        </CardFormWrapper>
    </Container>
}


export default EditCustomDomainPage