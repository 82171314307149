import Card from "@mui/material/Card";
import * as React from "react";
import {Button, CardContent} from "@mui/material";

type Props = {
    onClick?: () => void
};

const AddNewCard = ({onClick}: Props) => {
    return <Card sx={{
        minWidth: '200px',
        height: {
            xs: '160px',
            lg: '196px',
        },
        cursor: 'pointer',

        borderRadius: "16px",
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)',
        boxSizing: 'border-box',
    }}
                 onClick={onClick}
    >
        <CardContent sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Button
                sx={{
                    width: {xs: '100%', sm: '150px'},
                    borderRadius: 100,
                    backgroundColor: "black",
                    fontSize: 'body1.fontSize',
                    fontFamily: 'body1.fontFamily'
                }}
                variant={"contained"}
            >
                Add new
            </Button>
        </CardContent>
    </Card>

}

export default AddNewCard