// @ts-nocheck
import {fetched, isFetching, select, updateAll, updateLastDeployment} from "./site";
import {API, graphqlOperation} from "aws-amplify";
import {v4 as uuidv4} from "uuid";
import {DeploymentStatus, Site} from "../../../API";
import {createSiteCustom, updateSiteCustom} from "../../../graphql/custom_mutations";
import {listSitesEager} from "../../../graphql/custom_queries";
import {initialValues} from "./initialValues";

export const getSites = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listSitesEager, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then(async (got) => {
                const filledWithMissingData = got?.data?.listSites.items.map((item) => {
                    if (!item.live.functions.link) {
                        item.live.functions.link = {
                            enabled: initialValues.live?.functions?.link?.enabled,
                            redirectTo: initialValues.live?.functions?.link?.redirectTo,
                            buttonText: initialValues.live?.functions?.link?.buttonText,
                        }
                    }
                    if (!item.live.functions.calendly) {
                        item.live.functions.calendly = {
                            enabled: initialValues.live?.functions?.calendly?.enabled,
                            redirectTo: initialValues.live?.functions?.calendly?.redirectTo,
                            buttonText: initialValues.live?.functions?.calendly?.buttonText,
                        }
                    }
                    if (!item.live.functions.phone) {
                        item.live.functions.phone = {
                            enabled: initialValues.live?.functions?.phone?.enabled,
                            redirectTo: initialValues.live?.functions?.phone?.redirectTo,
                            buttonText: initialValues.live?.functions?.phone?.buttonText,
                        }
                    }
                    return item
                })
                await dispatch(updateAll(filledWithMissingData))
                const {site} = getState();
                if (!site.selected && site.sites.length !== 0) {
                    const selectedSite = site.sites[0];
                    await dispatch(select(selectedSite.id))
                }

            })
    }
}

export const updateSiteAction = (
    businessId: string,
    newSitePatch: Site) => {
    return async (dispatch, getState) => {
        const {site} = await getState();
        if (!site.isFetching) {
            dispatch(isFetching())
            try {
                const sitePatch = {...newSitePatch}

                delete sitePatch.lastDeployment
                delete sitePatch.lastDeploymentId

                if (site.sites?.find((tc => tc.id === site.selected)).live.url !== sitePatch.live.url) {
                    await createOrUpdateNetlifySite(
                        businessId, sitePatch.id, sitePatch.name, sitePatch.live.url)
                        .then(response => {
                            sitePatch.live = {
                                ...sitePatch.live,
                                netlifySiteId: response.liveId,
                                netlifyDeploymentId: response.liveDomain
                            }
                        })
                        .catch(e => {
                            throw e.response.data
                        })
                }
                await API.graphql(graphqlOperation(updateSiteCustom, {
                    input: sitePatch
                }))
                await dispatch(getSites(businessId))
                return sitePatch
            } finally {
                dispatch(fetched())
            }
        }
    }
}

const createOrUpdateNetlifySite = (businessId: string, siteId: string, name: string, url: string) => {
    return API.post('sites', '/create', {
        headers: {
            'Content-Type': 'application/json'
        },
        body: {
            "tenant": "evergrow",
            "businessId": businessId,
            "siteId": siteId,
            "siteName": name,
            "url": url
        }
    })
}

export const deployAction = (businessId: string,
                             siteId: string,
                             deployTo: 'live') => {
    return async (dispatch, getState) => {
        const response = await API.post('sites', '/deploy', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "websiteId": siteId,
                "deployTo": deployTo
            }
        })
        dispatch(updateLastDeployment({
            id: response.deploymentId,
            deploymentStatus: DeploymentStatus.SCHEDULED,
            siteId: siteId
        }))
        return response.deploymentId
    }
}

export const createSiteAction = (
    businessId: string,
    newSite: Site,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {site} = await getState();
        if (!site.isFetching || force) {
            dispatch(isFetching())
            newSite.id = uuidv4();
            newSite.businessId = businessId;
            await createOrUpdateNetlifySite(
                businessId, newSite.id, newSite.name, newSite.live.url)
                .then(response => {
                    newSite.live = {
                        ...newSite.live,
                        netlifySiteId: response.liveId,
                        netlifyDeploymentId: response.liveDomain,
                    }
                })
                .catch(e => {
                    throw e.response.data
                })
            await API.graphql(graphqlOperation(createSiteCustom, {
                input: newSite
            }))
            await dispatch(getSites(businessId))
            dispatch(fetched())
            return newSite
        }
    }
}

