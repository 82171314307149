import {Box, FormHelperText, TextField} from "@mui/material";
import {Field} from "formik";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";
import {FieldAttributes} from "formik/dist/Field";


const LabeledFieldWithMaxLength = (props: {
    name: string,
    text: string,
    placeholder?: string,
    maxLength: number,
    helperText?: (value: any) => string
}) => {

    return <FieldHorizontalWrapper name={props.name} text={props.text} noPaddingBottom={true}>
        <Field
            name={props.name}
        >{({field}: FieldAttributes<any>) => (
            <>
                <Box sx={{
                    display: 'flex',
                }}>
                    <TextField {...field}
                               placeholder={props.placeholder}
                               variant="outlined"
                               size={"small"}

                               inputProps={{
                                   maxLength: props.maxLength
                               }}
                               multiline
                               maxRows={4}
                               sx={{
                                   flexGrow: 1,
                                   justifySelf: 'center',
                                   backgroundColor: 'white',
                               }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                    <FormHelperText>{`${field.value?.length ? field.value.length : 0}/${props.maxLength}`}</FormHelperText>
                </Box>
            </>
        )}</Field>
    </FieldHorizontalWrapper>
}

export default LabeledFieldWithMaxLength;