import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Site, Tutorial } from "../../../API";
import { Divider, Stack, Button } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import OverviewCard from "../card/OverviewCard";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

type StepProps = {
    text: JSX.Element,
    status: boolean
    href?: string,
    externalHref?: string,
    buttonText: string,

};

function Step({ text, status, href, buttonText, externalHref }: StepProps) {
    return <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
    >
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            {status ? <CheckBoxIcon color="success" /> : <CheckBoxOutlineBlankIcon />}
            <Typography variant="body1">{text}</Typography>
        </Stack>
        <Button endIcon={<ChevronRightRoundedIcon />} sx={{ fontWeight: "bold", borderRadius: 16 }} href={externalHref ? externalHref : href} {...externalHref ? { target: "blank", rel: "noopener" } : ""}>{buttonText}</Button>
    </Stack>;
}

type Props = {
    tutorial: Tutorial,
    site: Site
};
const TutorialCard = ({
    tutorial,
    site
}: Props
) => {
    return <OverviewCard
        title={'Your progress'}
        subtitle={'Complete these tasks to get your business discovered fast.'}
        child={
            <Stack>
                <Step text={
                    <>Add a product or solution
                    </>
                }
                    href={'business'}
                    buttonText={'Go to Business'}
                    status={Boolean(tutorial.productsOrSolutions)} />
                <Divider />
                <Step text={
                    <>Describe how it works</>
                }
                    href={'business-steps'}
                    buttonText={'Add steps'}
                    status={Boolean(tutorial.steps)}
                />
                <Divider />
                <Step text={
                    <>Show the love of a customer</>
                }
                    href={'testimonials'}
                    buttonText={'Add Testimonial'}
                    status={Boolean(tutorial.testimonial)}
                />
                <Divider />
                <Step text={
                    <>Test the form on your site</>
                }
                    externalHref={'https://' + site?.live?.url}
                    buttonText={'Go to Site'}
                    status={Boolean(tutorial.siteTest)} />
                <Divider />
                <Step text={
                    <>Connect your domain</>
                }
                    href={'custom-domain/'}
                    buttonText={'Edit Settings'}
                    status={Boolean(tutorial.domain)} />
                <Divider />
                <Step text={
                    <>Promote your site on Google </>
                }
                    href={'marketing-campaign'}
                    buttonText={'Go to Campaigns'}
                    status={Boolean(tutorial.campaign)} />
            </Stack>
        }

    />

}


export default TutorialCard;
