import { Image } from '@aws-amplify/ui-react';
import { useCallback, useEffect, useState } from "react";
import { Storage } from "aws-amplify";

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Box } from "@mui/system";


const UploadImageButton = (props: { name: string, image?: string, onClick: (event: any) => {}, thumbnailSize: number }) => {
    const [imageSrc, setImageSrc] = useState()

    const getImage = useCallback(() => {
        if (props.image) {
            Storage.get(props.image,
                { level: 'public' }
            )
                .then((v) => {
                    // @ts-ignore
                    return setImageSrc(v);
                }
                )
        }
    }, [props.image])

    useEffect(() => {
        getImage()
    }, [props.image, getImage])


    return <Box
        sx={{
            width: props.thumbnailSize + 'px',
            height: props.thumbnailSize + 'px',
            borderRadius: 4,
            backgroundColor: 'white',
            border: 1,
            borderColor: 'lightgrey',
            borderStyle: 'solid'
        }}
    >
        <input
            accept="image/*"
            id={"button-add-picture-" + props.name}
            multiple
            type="file"
            onChange={props.onClick}
            style={{ display: 'none' }}
        />
        <label htmlFor={"button-add-picture-" + props.name}>
            {props.image ?
                // @ts-ignore


                <Image
                    srcSet={imageSrc}
                    alt={props.name}
                />

                : <Box
                    sx={{
                        width: props.thumbnailSize + 'px',
                        height: props.thumbnailSize + 'px',
                        alignItems: "center",
                        justifyContent: "center",

                        borderRadius: 4,
                        backgroundColor: 'white',
                        border: 1,
                        borderColor: 'lightgrey',
                        borderStyle: 'solid',
                        cursor: "pointer",


                    }}
                    display="flex"
                >
                    <AddAPhotoIcon sx={{
                        width: '32px',
                        height: '32px'
                    }} />
                </Box>
            }
        </label>
    </Box>
}

export default UploadImageButton;