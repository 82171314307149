import {BusinessFaq} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {
    createBusinessFaqAction,
    deleteBusinessFaqAction,
    updateBusinessFaqAction
} from "../../../redux/features/businessFaq/businessFaqActions";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import {FormikErrors} from "formik/dist/types";
import DeleteModal from "../../atoms/modal/DeleteModal";
import {useState} from "react";
import TwoColumn from "../../atoms/two-column/TwoColumn";
import {updateTutorialStatus} from "../../../redux/features/business/tutorialActions";

const validationSchema = Yup.object({
    name: Yup.string().required("This is a required field"),
    description: Yup.string().required("This is a required field"),
    order: Yup.number().required("This is a required field"),
})

type Props = {
    businessFaq?: BusinessFaq,
    afterSubmit: () => void,
    defaultOrder: number
};

const BusinessFaqFields = (props: { businessId: string, afterSubmit: () => void }) => {

    const {values, submitForm, setFieldTouched, validateForm} = useFormikContext();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false)

    const handleSubmit = (): Promise<BusinessFaq | FormikErrors<BusinessFaq>> => {
        ['name', 'description', 'order'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }

    return <>
        <TwoColumn
            left={[]}
            right={[
                <LabeledFieldWithMaxLength key={'name'}
                                           name='name'
                                           text='Question'
                                           placeholder=''
                                           maxLength={90}/>,
                <LabeledFieldWithMaxLength key={'description'}
                                           name='description' text='Answer'
                                           placeholder=''
                                           maxLength={200}/>,
                <LabeledField key={'order'}
                              name='order'
                              text='Order on page'/>
            ]}
            bottom={<ButtonHolder
                justify={'right'}
                textRight={"Save"}
                textLeft={"Delete"}
                submitRight={
                    () => {
                        handleSubmit()
                            .then((errors) => {
                                if (!errors)
                                    props.afterSubmit()
                            })
                    }}
                submitLeft={() => {
                    setOpenModal(true)
                }}/>}
        />
        <DeleteModal
            open={openModal}
            closeModal={() => setOpenModal(false)}
            deleteSubmit={() => {
                dispatch(deleteBusinessFaqAction(
                    props.businessId,
                    // @ts-ignore
                    values?.id))
                    // @ts-ignore
                    .then(() => {
                        props.afterSubmit()
                    })
            }}/>
    </>
}


const BusinessFaqForm = ({businessFaq, afterSubmit, defaultOrder}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            businessFaq ? businessFaq : {
                name: '',
                description: '',
                order: defaultOrder
            } as BusinessFaq
        }
        validationSchema={validationSchema}
        onSubmit={async (values: BusinessFaq) => {
            if (businessFaq) {
                await dispatch(updateBusinessFaqAction(values))
            } else {
                if (business)
                    await dispatch(createBusinessFaqAction(business.id, values, false))
            }
            updateTutorialStatus(business, dispatch, 'productsOrFaqs');
            afterSubmit()
        }}>
        <Form>
            <BusinessFaqFields businessId={business?.id as string} afterSubmit={afterSubmit}/>
        </Form>
    </Formik>
}

export default BusinessFaqForm