import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Brand} from "../../../API";
import {RootState} from "../../store";

interface BrandState {
    isFetching: boolean,
    brands?: [Brand],
    selected?: string
}

const initialState: BrandState = {
    isFetching: false,
}


export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[Brand]>) => {
            state.brands = action.payload
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, select, isFetching, fetched} = brandSlice.actions
export const selectedBrand = (state: RootState) => state.brand.selected
export const allBrands = (state: RootState) => state.brand.brands
export const brandsIsFetching = (state: RootState) => state.brand.isFetching

export default brandSlice.reducer