import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store";
import {Budget} from "./budgetType";



interface BudgetState {
    isFetching: boolean,
    budget?: Budget
}

const initialState: BudgetState = {
    isFetching: false,
    budget: undefined
}


export const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Budget>) => {
            state.budget = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {update, isFetching, fetched} = budgetSlice.actions
export const currentBudget = (state: RootState) => state.budget.budget
export const budgetsIsFetching = (state: RootState) => state.budget.isFetching

export default budgetSlice.reducer