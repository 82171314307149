import {Box, FormHelperText, TextField} from "@mui/material";
import {Field, useField} from "formik";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";
import {FieldAttributes} from "formik/dist/Field";
import InputAdornment from "@mui/material/InputAdornment";

const LabeledField = (props: {
                          name: string,
                          text: string,
                          placeholder?: string,
                          helperText?: (value: any) => string,
                          transformField?: (value: any) => any,
                          endAdornment?: JSX.Element
                      }
) => {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props.name);

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({
               field,
               form,
           }: FieldAttributes<any>) => (
            <>
                <Box sx={{
                    display: 'flex',
                }}>
                    <TextField {...field}
                               placeholder={props.placeholder}
                               variant="outlined"
                               size={"small"}

                               inputProps={{'aria-label': 'description'}}
                               sx={{
                                   flexGrow: 1,
                                   justifySelf: 'center',
                                   backgroundColor: 'white'
                               }}
                               onChange={(event) => {
                                   let newVal = event.target.value
                                   if (props.transformField)
                                       newVal = props.transformField(newVal)

                                   helpers.setValue(newVal)
                               }
                               }
                               InputProps={{
                                   endAdornment:
                                       props.endAdornment ?
                                           <InputAdornment position="end">
                                               {props.endAdornment}
                                           </InputAdornment> :
                                           <></>
                               }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                </Box>
            </>
        )}</Field>
    </FieldHorizontalWrapper>
}


export default LabeledField;