import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../../redux/features/business/business";
import { useEffect } from "react";
import { getSites } from "../../../../redux/features/site/siteActions";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import ButtonHolder from "../../../atoms/button-holder/ButtonHolder";
import { updateBusinessAction } from "../../../../redux/features/business/businessActions";
import { OnboardingStatus } from "../../../../API";

const OnBoardingSiteSummary = () => {
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business)
            dispatch(getSites(business.id))
    }, [dispatch, business])

    const submit = () => {
        if (business) {
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Campaign))
            navigate('/onboarding/campaign')
        }
    }

    return <Container maxWidth="sm">
        <Stack sx={{ pt: 12, pb: 2 }} spacing={3}>
            <Stack spacing={1}>
                <Typography variant="h1" component="span">🏗</Typography>
                <Typography variant="h4" component="h1">Your site is being published</Typography>
                <Typography variant="body1" component="h1" color="gray">In the full version you will be able to add more
                    content, set a custom domain, and more!</Typography>
            </Stack>
            <ButtonHolder
                submitRight={submit}
                textRight="Go to campaigns"
                justify={"right"}
            />
        </Stack>
    </Container>
}

export default OnBoardingSiteSummary;