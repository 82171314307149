import { AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../../images/logo-arrow.svg";
import * as React from "react";

const TopBar = (props: {
    onMenuClick: () => void
}) => {
    return <>
        <AppBar position="fixed" elevation={0}>
            <Toolbar sx={{
                backgroundColor: 'common.white'
            }}>
                <IconButton
                    onClick={props.onMenuClick}
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    sx={{ mr: 1 }}
                >
                    <MenuIcon />
                </IconButton>
                <img src={Logo} alt={"Evergrow"} width={'24px'} />
            </Toolbar>
        </AppBar>
    </>
}

export default TopBar;
