/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      name
      onboardingStatus
      brands {
        items {
          id
          businessId
          name
          briefDescription
          longDescription
          keywords
          logo
          icon
          coverImage
          createdAt
          updatedAt
        }
        nextToken
      }
      customerPersonas {
        items {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        nextToken
      }
      sites {
        items {
          id
          businessId
          name
          lastDeploymentId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          businessId
          name
          price
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      solutions {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      faqs {
        items {
          id
          businessId
          name
          description
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      features {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      businessSteps {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      testimonials {
        items {
          id
          businessId
          who
          testimonial
          avatar
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      tutorial {
        closed
        productsOrSolutions
        steps
        testimonial
        domain
        siteTest
        campaign
      }
      editors
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        onboardingStatus
        brands {
          nextToken
        }
        customerPersonas {
          nextToken
        }
        sites {
          nextToken
        }
        products {
          nextToken
        }
        solutions {
          nextToken
        }
        faqs {
          nextToken
        }
        features {
          nextToken
        }
        partners {
          nextToken
        }
        businessSteps {
          nextToken
        }
        testimonials {
          nextToken
        }
        tutorial {
          closed
          productsOrSolutions
          steps
          testimonial
          domain
          siteTest
          campaign
        }
        editors
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      businessId
      name
      briefDescription
      longDescription
      keywords
      logo
      icon
      coverImage
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        name
        briefDescription
        longDescription
        keywords
        logo
        icon
        coverImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      businessId
      name
      price
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        name
        price
        description
        coverImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessSolution = /* GraphQL */ `
  query GetBusinessSolution($id: ID!) {
    getBusinessSolution(id: $id) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessSolutions = /* GraphQL */ `
  query ListBusinessSolutions(
    $filter: ModelBusinessSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessSolutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        name
        description
        coverImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessFaq = /* GraphQL */ `
  query GetBusinessFaq($id: ID!) {
    getBusinessFaq(id: $id) {
      id
      businessId
      name
      description
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessFaqs = /* GraphQL */ `
  query ListBusinessFaqs(
    $filter: ModelBusinessFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        name
        description
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessFeature = /* GraphQL */ `
  query GetBusinessFeature($id: ID!) {
    getBusinessFeature(id: $id) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessFeatures = /* GraphQL */ `
  query ListBusinessFeatures(
    $filter: ModelBusinessFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        name
        description
        coverImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessPartner = /* GraphQL */ `
  query GetBusinessPartner($id: ID!) {
    getBusinessPartner(id: $id) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessPartners = /* GraphQL */ `
  query ListBusinessPartners(
    $filter: ModelBusinessPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        name
        description
        coverImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessStep = /* GraphQL */ `
  query GetBusinessStep($id: ID!) {
    getBusinessStep(id: $id) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessSteps = /* GraphQL */ `
  query ListBusinessSteps(
    $filter: ModelBusinessStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        name
        description
        coverImage
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessTestimonial = /* GraphQL */ `
  query GetBusinessTestimonial($id: ID!) {
    getBusinessTestimonial(id: $id) {
      id
      businessId
      who
      testimonial
      avatar
      order
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessTestimonials = /* GraphQL */ `
  query ListBusinessTestimonials(
    $filter: ModelBusinessTestimonialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessTestimonials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        who
        testimonial
        avatar
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      businessId
      name
      live {
        netlifySiteId
        netlifyDeploymentId
        url
        meta {
          googleAdsAccount
        }
        functions {
          actions
        }
        gtmTag
        styling {
          primaryText
          buttons
          header
          footerBackground
          footerText
          background
          tilesBackground
        }
        layout {
          siteTitle
          sectionTitles
          headerType
          contentAlign
          buttonShape
          density
          font
        }
      }
      lastDeploymentId
      lastDeployment {
        id
        siteId
        deploymentStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        name
        live {
          netlifySiteId
          netlifyDeploymentId
          url
          gtmTag
        }
        lastDeploymentId
        lastDeployment {
          id
          siteId
          deploymentStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSiteDeployment = /* GraphQL */ `
  query GetSiteDeployment($id: ID!) {
    getSiteDeployment(id: $id) {
      id
      siteId
      deploymentStatus
      createdAt
      updatedAt
    }
  }
`;
export const listSiteDeployments = /* GraphQL */ `
  query ListSiteDeployments(
    $filter: ModelSiteDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteDeployments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteId
        deploymentStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerPersona = /* GraphQL */ `
  query GetCustomerPersona($id: ID!) {
    getCustomerPersona(id: $id) {
      id
      businessId
      name
      age
      gender
      interests {
        items {
          id
          customerPersonaID
          interestID
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          customerPersonaID
          geoLocationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerPersonas = /* GraphQL */ `
  query ListCustomerPersonas(
    $filter: ModelCustomerPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerPersonas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInterests = /* GraphQL */ `
  query ListInterests(
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const interestsByName = /* GraphQL */ `
  query InterestsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interestsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGeoLocations = /* GraphQL */ `
  query ListGeoLocations(
    $filter: ModelGeoLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeoLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const geoLocationsByName = /* GraphQL */ `
  query GeoLocationsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGeoLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geoLocationsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      siteId
      forwardTo
      firstName
      lastName
      phone
      location
      fromEmail
      form
      createdAt
      updatedAt
      url
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteId
        forwardTo
        firstName
        lastName
        phone
        location
        fromEmail
        form
        createdAt
        updatedAt
        url
      }
      nextToken
    }
  }
`;
export const getClosedInfo = /* GraphQL */ `
  query GetClosedInfo($id: ID!) {
    getClosedInfo(id: $id) {
      id
      code
      closed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listClosedInfos = /* GraphQL */ `
  query ListClosedInfos(
    $filter: ModelClosedInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClosedInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        closed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMarketingCampaign = /* GraphQL */ `
  query GetMarketingCampaign($id: ID!) {
    getMarketingCampaign(id: $id) {
      id
      businessId
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      site {
        id
        businessId
        name
        live {
          netlifySiteId
          netlifyDeploymentId
          url
          gtmTag
        }
        lastDeploymentId
        lastDeployment {
          id
          siteId
          deploymentStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      objective
      provider
      runs {
        items {
          id
          marketingCampaignId
          startDate
          endDate
          dailyBudget
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      marketingCampaignCustomerPersonaId
      marketingCampaignSiteId
    }
  }
`;
export const listMarketingCampaigns = /* GraphQL */ `
  query ListMarketingCampaigns(
    $filter: ModelMarketingCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        customerPersona {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        site {
          id
          businessId
          name
          lastDeploymentId
          createdAt
          updatedAt
        }
        objective
        provider
        runs {
          nextToken
        }
        createdAt
        updatedAt
        marketingCampaignCustomerPersonaId
        marketingCampaignSiteId
      }
      nextToken
    }
  }
`;
export const getMarketingCampaignRun = /* GraphQL */ `
  query GetMarketingCampaignRun($id: ID!) {
    getMarketingCampaignRun(id: $id) {
      id
      marketingCampaignId
      startDate
      endDate
      dailyBudget
      createdAt
      updatedAt
    }
  }
`;
export const listMarketingCampaignRuns = /* GraphQL */ `
  query ListMarketingCampaignRuns(
    $filter: ModelMarketingCampaignRunFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingCampaignRuns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        marketingCampaignId
        startDate
        endDate
        dailyBudget
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBalanceEntry = /* GraphQL */ `
  query GetBalanceEntry($id: String!) {
    getBalanceEntry(id: $id) {
      id
      origin
      entryType
      businessId
      currency
      amount
      sign
      createdAt
      updatedAt
    }
  }
`;
export const listBalanceEntries = /* GraphQL */ `
  query ListBalanceEntries(
    $id: String
    $filter: ModelBalanceEntryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBalanceEntries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        origin
        entryType
        businessId
        currency
        amount
        sign
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerPersonaInterests = /* GraphQL */ `
  query GetCustomerPersonaInterests($id: ID!) {
    getCustomerPersonaInterests(id: $id) {
      id
      customerPersonaID
      interestID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      interest {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerPersonaInterests = /* GraphQL */ `
  query ListCustomerPersonaInterests(
    $filter: ModelCustomerPersonaInterestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerPersonaInterests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerPersonaID
        interestID
        customerPersona {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        interest {
          name
          googleAffinityAudiences
          googleInMarketAudiences
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerPersonaLocations = /* GraphQL */ `
  query GetCustomerPersonaLocations($id: ID!) {
    getCustomerPersonaLocations(id: $id) {
      id
      customerPersonaID
      geoLocationID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      geoLocation {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerPersonaLocations = /* GraphQL */ `
  query ListCustomerPersonaLocations(
    $filter: ModelCustomerPersonaLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerPersonaLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerPersonaID
        geoLocationID
        customerPersona {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        geoLocation {
          name
          targetType
          countryCode
          googleAdsGeoCriteria
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
