import * as React from 'react';
import Container from '@mui/material/Container';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {allBusinessTestimonials} from "../../../../redux/features/businessTestimonial/businessTestimonial";
import BusinessTestimonialForm from "../../../molecules/business-testimonial-form/BusinessTestimonialForm";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";


const EditBusinessTestimonialPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const businessTestimonials = useSelector(allBusinessTestimonials);

    return <Container>
        <DashboardHeader title={"Testimonial"}
                         backTo={"/dashboard/testimonials"}
        />
        <CardFormWrapper>
            <BusinessTestimonialForm businessTestimonial={
                businessTestimonials?.find((product) => product.id === id)
            }
                                     afterSubmit={() => navigate('/dashboard/testimonials')}
                // @ts-ignore
                                     defaultOrder={businessTestimonials?.reduce(
                                         // @ts-ignore
                                         (max, sol) => (sol?.order > max ? sol?.order : max),
                                         businessTestimonials[0]?.order
                                     ) + 1 || 1}/>
        </CardFormWrapper>
    </Container>
}


export default EditBusinessTestimonialPage