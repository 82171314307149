/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      onboardingStatus
      brands {
        items {
          id
          businessId
          name
          briefDescription
          longDescription
          keywords
          logo
          icon
          coverImage
          createdAt
          updatedAt
        }
        nextToken
      }
      customerPersonas {
        items {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        nextToken
      }
      sites {
        items {
          id
          businessId
          name
          lastDeploymentId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          businessId
          name
          price
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      solutions {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      faqs {
        items {
          id
          businessId
          name
          description
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      features {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      businessSteps {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      testimonials {
        items {
          id
          businessId
          who
          testimonial
          avatar
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      tutorial {
        closed
        productsOrSolutions
        steps
        testimonial
        domain
        siteTest
        campaign
      }
      editors
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      onboardingStatus
      brands {
        items {
          id
          businessId
          name
          briefDescription
          longDescription
          keywords
          logo
          icon
          coverImage
          createdAt
          updatedAt
        }
        nextToken
      }
      customerPersonas {
        items {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        nextToken
      }
      sites {
        items {
          id
          businessId
          name
          lastDeploymentId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          businessId
          name
          price
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      solutions {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      faqs {
        items {
          id
          businessId
          name
          description
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      features {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      businessSteps {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      testimonials {
        items {
          id
          businessId
          who
          testimonial
          avatar
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      tutorial {
        closed
        productsOrSolutions
        steps
        testimonial
        domain
        siteTest
        campaign
      }
      editors
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      onboardingStatus
      brands {
        items {
          id
          businessId
          name
          briefDescription
          longDescription
          keywords
          logo
          icon
          coverImage
          createdAt
          updatedAt
        }
        nextToken
      }
      customerPersonas {
        items {
          id
          businessId
          name
          age
          gender
          createdAt
          updatedAt
        }
        nextToken
      }
      sites {
        items {
          id
          businessId
          name
          lastDeploymentId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          businessId
          name
          price
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      solutions {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      faqs {
        items {
          id
          businessId
          name
          description
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      features {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      businessSteps {
        items {
          id
          businessId
          name
          description
          coverImage
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      testimonials {
        items {
          id
          businessId
          who
          testimonial
          avatar
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      tutorial {
        closed
        productsOrSolutions
        steps
        testimonial
        domain
        siteTest
        campaign
      }
      editors
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      businessId
      name
      briefDescription
      longDescription
      keywords
      logo
      icon
      coverImage
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      businessId
      name
      briefDescription
      longDescription
      keywords
      logo
      icon
      coverImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      businessId
      name
      briefDescription
      longDescription
      keywords
      logo
      icon
      coverImage
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      businessId
      name
      price
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      businessId
      name
      price
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      businessId
      name
      price
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessSolution = /* GraphQL */ `
  mutation CreateBusinessSolution(
    $input: CreateBusinessSolutionInput!
    $condition: ModelBusinessSolutionConditionInput
  ) {
    createBusinessSolution(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessSolution = /* GraphQL */ `
  mutation UpdateBusinessSolution(
    $input: UpdateBusinessSolutionInput!
    $condition: ModelBusinessSolutionConditionInput
  ) {
    updateBusinessSolution(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessSolution = /* GraphQL */ `
  mutation DeleteBusinessSolution(
    $input: DeleteBusinessSolutionInput!
    $condition: ModelBusinessSolutionConditionInput
  ) {
    deleteBusinessSolution(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessFaq = /* GraphQL */ `
  mutation CreateBusinessFaq(
    $input: CreateBusinessFaqInput!
    $condition: ModelBusinessFaqConditionInput
  ) {
    createBusinessFaq(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessFaq = /* GraphQL */ `
  mutation UpdateBusinessFaq(
    $input: UpdateBusinessFaqInput!
    $condition: ModelBusinessFaqConditionInput
  ) {
    updateBusinessFaq(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessFaq = /* GraphQL */ `
  mutation DeleteBusinessFaq(
    $input: DeleteBusinessFaqInput!
    $condition: ModelBusinessFaqConditionInput
  ) {
    deleteBusinessFaq(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessFeature = /* GraphQL */ `
  mutation CreateBusinessFeature(
    $input: CreateBusinessFeatureInput!
    $condition: ModelBusinessFeatureConditionInput
  ) {
    createBusinessFeature(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessFeature = /* GraphQL */ `
  mutation UpdateBusinessFeature(
    $input: UpdateBusinessFeatureInput!
    $condition: ModelBusinessFeatureConditionInput
  ) {
    updateBusinessFeature(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessFeature = /* GraphQL */ `
  mutation DeleteBusinessFeature(
    $input: DeleteBusinessFeatureInput!
    $condition: ModelBusinessFeatureConditionInput
  ) {
    deleteBusinessFeature(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessPartner = /* GraphQL */ `
  mutation CreateBusinessPartner(
    $input: CreateBusinessPartnerInput!
    $condition: ModelBusinessPartnerConditionInput
  ) {
    createBusinessPartner(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessPartner = /* GraphQL */ `
  mutation UpdateBusinessPartner(
    $input: UpdateBusinessPartnerInput!
    $condition: ModelBusinessPartnerConditionInput
  ) {
    updateBusinessPartner(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessPartner = /* GraphQL */ `
  mutation DeleteBusinessPartner(
    $input: DeleteBusinessPartnerInput!
    $condition: ModelBusinessPartnerConditionInput
  ) {
    deleteBusinessPartner(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessStep = /* GraphQL */ `
  mutation CreateBusinessStep(
    $input: CreateBusinessStepInput!
    $condition: ModelBusinessStepConditionInput
  ) {
    createBusinessStep(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessStep = /* GraphQL */ `
  mutation UpdateBusinessStep(
    $input: UpdateBusinessStepInput!
    $condition: ModelBusinessStepConditionInput
  ) {
    updateBusinessStep(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessStep = /* GraphQL */ `
  mutation DeleteBusinessStep(
    $input: DeleteBusinessStepInput!
    $condition: ModelBusinessStepConditionInput
  ) {
    deleteBusinessStep(input: $input, condition: $condition) {
      id
      businessId
      name
      description
      coverImage
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessTestimonial = /* GraphQL */ `
  mutation CreateBusinessTestimonial(
    $input: CreateBusinessTestimonialInput!
    $condition: ModelBusinessTestimonialConditionInput
  ) {
    createBusinessTestimonial(input: $input, condition: $condition) {
      id
      businessId
      who
      testimonial
      avatar
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessTestimonial = /* GraphQL */ `
  mutation UpdateBusinessTestimonial(
    $input: UpdateBusinessTestimonialInput!
    $condition: ModelBusinessTestimonialConditionInput
  ) {
    updateBusinessTestimonial(input: $input, condition: $condition) {
      id
      businessId
      who
      testimonial
      avatar
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessTestimonial = /* GraphQL */ `
  mutation DeleteBusinessTestimonial(
    $input: DeleteBusinessTestimonialInput!
    $condition: ModelBusinessTestimonialConditionInput
  ) {
    deleteBusinessTestimonial(input: $input, condition: $condition) {
      id
      businessId
      who
      testimonial
      avatar
      order
      createdAt
      updatedAt
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      businessId
      name
      live {
        netlifySiteId
        netlifyDeploymentId
        url
        meta {
          googleAdsAccount
        }
        functions {
          actions
        }
        gtmTag
        styling {
          primaryText
          buttons
          header
          footerBackground
          footerText
          background
          tilesBackground
        }
        layout {
          siteTitle
          sectionTitles
          headerType
          contentAlign
          buttonShape
          density
          font
        }
      }
      lastDeploymentId
      lastDeployment {
        id
        siteId
        deploymentStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      businessId
      name
      live {
        netlifySiteId
        netlifyDeploymentId
        url
        meta {
          googleAdsAccount
        }
        functions {
          actions
        }
        gtmTag
        styling {
          primaryText
          buttons
          header
          footerBackground
          footerText
          background
          tilesBackground
        }
        layout {
          siteTitle
          sectionTitles
          headerType
          contentAlign
          buttonShape
          density
          font
        }
      }
      lastDeploymentId
      lastDeployment {
        id
        siteId
        deploymentStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      businessId
      name
      live {
        netlifySiteId
        netlifyDeploymentId
        url
        meta {
          googleAdsAccount
        }
        functions {
          actions
        }
        gtmTag
        styling {
          primaryText
          buttons
          header
          footerBackground
          footerText
          background
          tilesBackground
        }
        layout {
          siteTitle
          sectionTitles
          headerType
          contentAlign
          buttonShape
          density
          font
        }
      }
      lastDeploymentId
      lastDeployment {
        id
        siteId
        deploymentStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSiteDeployment = /* GraphQL */ `
  mutation CreateSiteDeployment(
    $input: CreateSiteDeploymentInput!
    $condition: ModelSiteDeploymentConditionInput
  ) {
    createSiteDeployment(input: $input, condition: $condition) {
      id
      siteId
      deploymentStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateSiteDeployment = /* GraphQL */ `
  mutation UpdateSiteDeployment(
    $input: UpdateSiteDeploymentInput!
    $condition: ModelSiteDeploymentConditionInput
  ) {
    updateSiteDeployment(input: $input, condition: $condition) {
      id
      siteId
      deploymentStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteSiteDeployment = /* GraphQL */ `
  mutation DeleteSiteDeployment(
    $input: DeleteSiteDeploymentInput!
    $condition: ModelSiteDeploymentConditionInput
  ) {
    deleteSiteDeployment(input: $input, condition: $condition) {
      id
      siteId
      deploymentStatus
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerPersona = /* GraphQL */ `
  mutation CreateCustomerPersona(
    $input: CreateCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    createCustomerPersona(input: $input, condition: $condition) {
      id
      businessId
      name
      age
      gender
      interests {
        items {
          id
          customerPersonaID
          interestID
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          customerPersonaID
          geoLocationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerPersona = /* GraphQL */ `
  mutation UpdateCustomerPersona(
    $input: UpdateCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    updateCustomerPersona(input: $input, condition: $condition) {
      id
      businessId
      name
      age
      gender
      interests {
        items {
          id
          customerPersonaID
          interestID
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          customerPersonaID
          geoLocationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerPersona = /* GraphQL */ `
  mutation DeleteCustomerPersona(
    $input: DeleteCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    deleteCustomerPersona(input: $input, condition: $condition) {
      id
      businessId
      name
      age
      gender
      interests {
        items {
          id
          customerPersonaID
          interestID
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          customerPersonaID
          geoLocationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      siteId
      forwardTo
      firstName
      lastName
      phone
      location
      fromEmail
      form
      createdAt
      updatedAt
      url
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      siteId
      forwardTo
      firstName
      lastName
      phone
      location
      fromEmail
      form
      createdAt
      updatedAt
      url
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      siteId
      forwardTo
      firstName
      lastName
      phone
      location
      fromEmail
      form
      createdAt
      updatedAt
      url
    }
  }
`;
export const createClosedInfo = /* GraphQL */ `
  mutation CreateClosedInfo(
    $input: CreateClosedInfoInput!
    $condition: ModelClosedInfoConditionInput
  ) {
    createClosedInfo(input: $input, condition: $condition) {
      id
      code
      closed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateClosedInfo = /* GraphQL */ `
  mutation UpdateClosedInfo(
    $input: UpdateClosedInfoInput!
    $condition: ModelClosedInfoConditionInput
  ) {
    updateClosedInfo(input: $input, condition: $condition) {
      id
      code
      closed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteClosedInfo = /* GraphQL */ `
  mutation DeleteClosedInfo(
    $input: DeleteClosedInfoInput!
    $condition: ModelClosedInfoConditionInput
  ) {
    deleteClosedInfo(input: $input, condition: $condition) {
      id
      code
      closed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMarketingCampaign = /* GraphQL */ `
  mutation CreateMarketingCampaign(
    $input: CreateMarketingCampaignInput!
    $condition: ModelMarketingCampaignConditionInput
  ) {
    createMarketingCampaign(input: $input, condition: $condition) {
      id
      businessId
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      site {
        id
        businessId
        name
        live {
          netlifySiteId
          netlifyDeploymentId
          url
          gtmTag
        }
        lastDeploymentId
        lastDeployment {
          id
          siteId
          deploymentStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      objective
      provider
      runs {
        items {
          id
          marketingCampaignId
          startDate
          endDate
          dailyBudget
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      marketingCampaignCustomerPersonaId
      marketingCampaignSiteId
    }
  }
`;
export const updateMarketingCampaign = /* GraphQL */ `
  mutation UpdateMarketingCampaign(
    $input: UpdateMarketingCampaignInput!
    $condition: ModelMarketingCampaignConditionInput
  ) {
    updateMarketingCampaign(input: $input, condition: $condition) {
      id
      businessId
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      site {
        id
        businessId
        name
        live {
          netlifySiteId
          netlifyDeploymentId
          url
          gtmTag
        }
        lastDeploymentId
        lastDeployment {
          id
          siteId
          deploymentStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      objective
      provider
      runs {
        items {
          id
          marketingCampaignId
          startDate
          endDate
          dailyBudget
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      marketingCampaignCustomerPersonaId
      marketingCampaignSiteId
    }
  }
`;
export const deleteMarketingCampaign = /* GraphQL */ `
  mutation DeleteMarketingCampaign(
    $input: DeleteMarketingCampaignInput!
    $condition: ModelMarketingCampaignConditionInput
  ) {
    deleteMarketingCampaign(input: $input, condition: $condition) {
      id
      businessId
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      site {
        id
        businessId
        name
        live {
          netlifySiteId
          netlifyDeploymentId
          url
          gtmTag
        }
        lastDeploymentId
        lastDeployment {
          id
          siteId
          deploymentStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      objective
      provider
      runs {
        items {
          id
          marketingCampaignId
          startDate
          endDate
          dailyBudget
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      marketingCampaignCustomerPersonaId
      marketingCampaignSiteId
    }
  }
`;
export const createMarketingCampaignRun = /* GraphQL */ `
  mutation CreateMarketingCampaignRun(
    $input: CreateMarketingCampaignRunInput!
    $condition: ModelMarketingCampaignRunConditionInput
  ) {
    createMarketingCampaignRun(input: $input, condition: $condition) {
      id
      marketingCampaignId
      startDate
      endDate
      dailyBudget
      createdAt
      updatedAt
    }
  }
`;
export const updateMarketingCampaignRun = /* GraphQL */ `
  mutation UpdateMarketingCampaignRun(
    $input: UpdateMarketingCampaignRunInput!
    $condition: ModelMarketingCampaignRunConditionInput
  ) {
    updateMarketingCampaignRun(input: $input, condition: $condition) {
      id
      marketingCampaignId
      startDate
      endDate
      dailyBudget
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarketingCampaignRun = /* GraphQL */ `
  mutation DeleteMarketingCampaignRun(
    $input: DeleteMarketingCampaignRunInput!
    $condition: ModelMarketingCampaignRunConditionInput
  ) {
    deleteMarketingCampaignRun(input: $input, condition: $condition) {
      id
      marketingCampaignId
      startDate
      endDate
      dailyBudget
      createdAt
      updatedAt
    }
  }
`;
export const createBalanceEntry = /* GraphQL */ `
  mutation CreateBalanceEntry(
    $input: CreateBalanceEntryInput!
    $condition: ModelBalanceEntryConditionInput
  ) {
    createBalanceEntry(input: $input, condition: $condition) {
      id
      origin
      entryType
      businessId
      currency
      amount
      sign
      createdAt
      updatedAt
    }
  }
`;
export const updateBalanceEntry = /* GraphQL */ `
  mutation UpdateBalanceEntry(
    $input: UpdateBalanceEntryInput!
    $condition: ModelBalanceEntryConditionInput
  ) {
    updateBalanceEntry(input: $input, condition: $condition) {
      id
      origin
      entryType
      businessId
      currency
      amount
      sign
      createdAt
      updatedAt
    }
  }
`;
export const deleteBalanceEntry = /* GraphQL */ `
  mutation DeleteBalanceEntry(
    $input: DeleteBalanceEntryInput!
    $condition: ModelBalanceEntryConditionInput
  ) {
    deleteBalanceEntry(input: $input, condition: $condition) {
      id
      origin
      entryType
      businessId
      currency
      amount
      sign
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerPersonaInterests = /* GraphQL */ `
  mutation CreateCustomerPersonaInterests(
    $input: CreateCustomerPersonaInterestsInput!
    $condition: ModelCustomerPersonaInterestsConditionInput
  ) {
    createCustomerPersonaInterests(input: $input, condition: $condition) {
      id
      customerPersonaID
      interestID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      interest {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerPersonaInterests = /* GraphQL */ `
  mutation UpdateCustomerPersonaInterests(
    $input: UpdateCustomerPersonaInterestsInput!
    $condition: ModelCustomerPersonaInterestsConditionInput
  ) {
    updateCustomerPersonaInterests(input: $input, condition: $condition) {
      id
      customerPersonaID
      interestID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      interest {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerPersonaInterests = /* GraphQL */ `
  mutation DeleteCustomerPersonaInterests(
    $input: DeleteCustomerPersonaInterestsInput!
    $condition: ModelCustomerPersonaInterestsConditionInput
  ) {
    deleteCustomerPersonaInterests(input: $input, condition: $condition) {
      id
      customerPersonaID
      interestID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      interest {
        name
        googleAffinityAudiences
        googleInMarketAudiences
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerPersonaLocations = /* GraphQL */ `
  mutation CreateCustomerPersonaLocations(
    $input: CreateCustomerPersonaLocationsInput!
    $condition: ModelCustomerPersonaLocationsConditionInput
  ) {
    createCustomerPersonaLocations(input: $input, condition: $condition) {
      id
      customerPersonaID
      geoLocationID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      geoLocation {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerPersonaLocations = /* GraphQL */ `
  mutation UpdateCustomerPersonaLocations(
    $input: UpdateCustomerPersonaLocationsInput!
    $condition: ModelCustomerPersonaLocationsConditionInput
  ) {
    updateCustomerPersonaLocations(input: $input, condition: $condition) {
      id
      customerPersonaID
      geoLocationID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      geoLocation {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerPersonaLocations = /* GraphQL */ `
  mutation DeleteCustomerPersonaLocations(
    $input: DeleteCustomerPersonaLocationsInput!
    $condition: ModelCustomerPersonaLocationsConditionInput
  ) {
    deleteCustomerPersonaLocations(input: $input, condition: $condition) {
      id
      customerPersonaID
      geoLocationID
      customerPersona {
        id
        businessId
        name
        age
        gender
        interests {
          nextToken
        }
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      geoLocation {
        name
        targetType
        countryCode
        googleAdsGeoCriteria
        targetCustomers {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
