import Card from "@mui/material/Card";
import * as React from "react";


type Props = {
    children: JSX.Element,
};

const CardWrapper = ({children}: Props) => {
    return <Card sx={{
        my: 1,
        borderRadius: 4,
        boxShadow: '4px 2px 16px rgba(0, 0, 0, 0.05)'
    }}>
            {children}
    </Card>

}

export default CardWrapper