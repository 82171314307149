import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import CardContainer from "../../../atoms/card/CardContainer";
import AddNewCard from "../../../atoms/card/AddNewCard";
import {allProducts} from "../../../../redux/features/product/product";
import {getProducts} from "../../../../redux/features/product/productActions";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";

const ProductsPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const products = useSelector(allProducts);


    useEffect(() => {
        if (business) {
            dispatch(getProducts(business.id))
        }

    }, [dispatch, business])

    return <Container>
        <DashboardHeader title={"Business / Products"}
                    backTo={"/dashboard/business"}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                products ?
                    products?.map(product => {
                        return <ClickableCard
                            title={product.name as string}
                            text={product.description as string}
                            status={CardState.Blank}
                            onClick={() => navigate('/dashboard/products/' + product.id)}
                        />
                    }).concat(
                        <AddNewCard
                            onClick={() => navigate('/dashboard/products/new')}
                        />
                    ) : [<AddNewCard
                        onClick={() => navigate('/dashboard/products/new')}
                    />]}
        />

    </Container>
}


export default ProductsPage