// @ts-nocheck
import {API} from "aws-amplify";
import {fetched, isFetching, update} from "./budget";


export const getBudget = (businessId: string) => {
    return async (dispatch, getState) => {
        const {budget} = await getState();
        if (!budget.budget && !budget.isFetching) {
            dispatch(isFetching())
            try {
                const response = await API.get('metrics', '/budget', {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    queryStringParameters: {
                        "businessId": businessId,
                    }
                })
                dispatch(update(response))
            } finally {
                dispatch(fetched())
            }
        }
    }
}