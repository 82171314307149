// @ts-nocheck
import {Field, useField} from "formik";
import FieldHorizontalWrapper from "../../atoms/field-wrapper/FieldHorizontalWrapper";
import UploadImageButton from "../../atoms/button/UploadImageButton";
import {Storage} from "aws-amplify";
import {v4 as uuidv4} from 'uuid';
import {UploadTaskProgressEvent} from "@aws-amplify/storage/lib-esm/providers/AWSS3UploadTask";
import {useState} from "react";
import {Box, Stack} from "@mui/material";
import {FieldAttributes} from "formik/dist/Field";


const UploadField = (props: {
    name: string, text: string, newFileRoot: string, thumbnailSize: number
}) => {

    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props.name);
    const [progress, setProgress] = useState<undefined | number | 'complete'>(undefined)

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    function loadImgAsync(imgSrc) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.onerror = reject;
            img.src = imgSrc;
        })
    }

    function imgToBlobAsync(img, canvas) {
        return new Promise((resolve, reject) => {
            const ctxMain = canvas.getContext('2d');
            ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctxMain.canvas.toBlob(async (blob) => {
                resolve(blob)
            }, 'image/*');
        })
    }

    async function pushImgToS3(uri, filename) {
        if (uri === null) return
        return await Storage.put(filename, uri, {
            level: 'public',
            contentType: 'image/*',
            progressCallback: (progress: UploadTaskProgressEvent) => {
                if (progress.loaded === progress.total) {
                    setProgress('complete')
                } else {
                    setProgress(Math.round(100 * progress.loaded / progress.total))
                }

            }

        })
            .then(result => {
                return result.key
            })
            .catch(err => console.log(err));
    }

    async function deleteImgFromS3(uri) {
        console.log(uri)
        if (uri === null) return
        return await Storage.remove(uri, {
            level: 'public'
        })
            .then(result => {
                console.log(result)
                return result
            })
            .catch(err => console.log(err));
    }

    function createCanvas(img, maxSize) {
        const canvas = document.createElement('canvas');
        if (img.width > img.height) {
            const widthMain = maxSize;
            const scaleFactorMain = widthMain / img.width;
            canvas.width = widthMain;
            canvas.height = img.height * scaleFactorMain;
        } else {
            const heightMain = maxSize;
            const scaleFactorMain = heightMain / img.height;
            canvas.width = img.width * scaleFactorMain;
            canvas.height = heightMain;
        }
        return canvas
    }

    const handleAdd = async (event) => {
        event.persist();
        // Check there is some files to upload
        if (!event || !event.target || !event.target.files) {
            return
        }
        const filesLength = event.target.files.length;
        // Loop through all selected files
        for (let i = 0; i < filesLength; i++) {
            const file = event.target.files[i];
            const filename = file.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
            const fileExtension = file.name.split('.').pop();
// Define the image name
            const rootName = props.newFileRoot + '/' + filename + '-' + uuidv4();
            let mainImgName = rootName + '.' + fileExtension;
            let thumbnailImgName = rootName + '-thumbnail.' + fileExtension;
            // Read the image
            const imgSrc = await readFileAsync(file);
            const img = await loadImgAsync(imgSrc)
            // From the image and a canvas (for the resize),
            // generate a blob to be uploaded
            const canvas = createCanvas(img, 1000)
            const imgBlob = await imgToBlobAsync(img, canvas)

            // Same with the thumbnail
            const canvasThumb = createCanvas(img, props.thumbnailSize)
            const imgBlobThumb = await imgToBlobAsync(img, canvasThumb)
            // Create a file from the blob for the mail and thumbnail
            const fileMain = new File([imgBlob], filename, {
                type: 'image/*',
                lastModified: Date.now()
            });
            const fileThumb = new File([imgBlobThumb], filename, {
                type: 'image/*',
                lastModified: Date.now()
            });
            // Push the image to S3
            await pushImgToS3(fileThumb, thumbnailImgName)
            const fileLocation = await pushImgToS3(fileMain, mainImgName)
            helpers.setValue(fileLocation)
        }
    }

    const deleteImage = async () => {
        await deleteImgFromS3(field.value)
        helpers.setValue(null)
    }


    return <FieldHorizontalWrapper
        name={props.name}
        text={props.text}
        onDelete={field.value ? deleteImage : undefined}
    >
        <Field
            name={props.name}
        >{({field}: FieldAttributes<any>) => (
            <Stack>
                <UploadImageButton
                    name={props.name}
                    image={field.value}
                    onClick={handleAdd}
                    thumbnailSize={props.thumbnailSize}/>
                <Box sx={{alignSelf: 'center', padding: '10px'}}>
                    {progress && (
                        progress === 'complete' ? 'Upload complete' :
                            'Upload progress ' + progress + '%')
                    }
                </Box>

            </Stack>
        )}</Field>
    </FieldHorizontalWrapper>
}

export default UploadField;