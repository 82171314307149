// @ts-nocheck
import {fetched, isFetching, remove, select, updateAll} from "./customerPersona";
import {API, graphqlOperation} from "aws-amplify";

import {createCustomerPersona, deleteCustomerPersona, updateCustomerPersona} from "../../../graphql/mutations";
import {v4 as uuidv4} from "uuid";
import {CustomerPersona} from "../../../API";
import {
    createCustomerPersonaInterestsCustom,
    createCustomerPersonaLocationCustom,
    deleteCustomerPersonaInterestsCustom,
    deleteCustomerPersonaLocationsCustom
} from "../../../graphql/custom_mutations";
import {listCustomerPersonasEager} from "../../../graphql/custom_queries";
import {GeoLocationSimpleModel, InterestSimpleModel, CustomerPersonaSimpleModel} from "./model";
import {notPresentInSecond} from "../utils";

const toSimpleModel = (tc: CustomerPersona): CustomerPersonaSimpleModel => {
    const target: CustomerPersonaSimpleModel = {
        id: tc.id,
        businessId: tc.businessId,
        name: tc.name,
        age: tc.age,
        gender: tc.gender,
        interests: tc.interests?.items.map((interest) => {
            const simpleLocation: InterestSimpleModel = {
                associationId: interest.id,
                id: interest.interest.id,
                name: interest.interest.name,
                googleAffinityAudiences: interest.interest.googleAffinityAudiences,
                googleInMarketAudiences: interest.interest.googleInMarketAudiences,

            }
            return simpleLocation;
        }),
        locations: tc.locations?.items.map((location) => {
            const simpleLocation: GeoLocationSimpleModel = {
                associationId: location.id,
                id: location.geoLocation.id,
                name: location.geoLocation.name,
                targetType: location.geoLocation.targetType,
                countryCode: location.geoLocation.countryCode,
            }
            return simpleLocation;
        })
    }
    return target
}

export const getCustomerPersonasAction = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listCustomerPersonasEager, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listCustomerPersonas.items.map(toSimpleModel)))
                const {customerPersona} = getState();
                if (!customerPersona.selected && customerPersona.customerPersonas.size !== 0) {
                    dispatch(select(customerPersona.customerPersonas[0]?.id))
                }
            })
    }
}

function updateLocations(customerPersona, locations: GeoLocationSimpleModel[], customerPersonaId: string) {
    const current = customerPersona.customerPersonas.find((tc: CustomerPersonaSimpleModel) => tc.id === customerPersonaId).locations
    const toAdd = notPresentInSecond(locations, current)
    const toRemove = notPresentInSecond(current, locations)
    toAdd.forEach((location) => {
        API.graphql(graphqlOperation(createCustomerPersonaLocationCustom, {
            input: {
                customerPersonaID: customerPersonaId,
                geoLocationID: location.id,
            }
        }))
    })
    toRemove.forEach((location) => {
        API.graphql(graphqlOperation(deleteCustomerPersonaLocationsCustom, {
            input: {
                id: location.associationId
            }
        }))
    })
}

function updateInterests(customerPersona, interests: InterestSimpleModel[], customerPersonaId: string) {
    const current = customerPersona.customerPersonas.find((tc: CustomerPersonaSimpleModel) => tc.id === customerPersonaId).interests
    const toAdd = notPresentInSecond(interests, current)
    const toRemove = notPresentInSecond(current, interests)
    toAdd.forEach((interest) => {
        API.graphql(graphqlOperation(createCustomerPersonaInterestsCustom, {
            input: {
                customerPersonaID: customerPersonaId,
                interestID: interest.id,
            }
        }))
    })
    toRemove.forEach((interest) => {
        API.graphql(graphqlOperation(deleteCustomerPersonaInterestsCustom, {
            input: {
                id: interest.associationId
            }
        }))
    })
}

export const updateCustomerPersonaAction = (
    businessId: string,
    id: string,
    name: string,
    age: string[],
    interests: InterestSimpleModel[],
    gender: string[],
    locations: GeoLocationSimpleModel[]) => {
    return async (dispatch, getState) => {
        const {customerPersona} = await getState();
        if (!customerPersona.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(updateCustomerPersona, {
                    input: {
                        id: id,
                        businessId: businessId,
                        name,
                        age,
                        gender,
                    }
                }))
                await updateLocations(customerPersona, locations, id);
                await updateInterests(customerPersona, interests, id);
                await dispatch(getCustomerPersonasAction(businessId))
            } finally {
                dispatch(fetched())
            }
        }
    }
}


export const createCustomerPersonaAction = (
    businessId: string,
    name: string,
    age: string[],
    interests: InterestSimpleModel[],
    gender: string[],
    locations: GeoLocationSimpleModel[],
    force?: boolean
) => {
    return async (dispatch, getState) => {
        const {customerPersona} = await getState();
        const id = uuidv4();
        if (!customerPersona.isFetching || force) {
            dispatch(isFetching())
            try {
                const fromApi = await API.graphql(graphqlOperation(createCustomerPersona, {
                    input: {
                        id: id,
                        businessId: businessId,
                        name,
                        age,
                        gender,
                    }
                }))
                await locations.forEach((location) => {
                    API.graphql(graphqlOperation(createCustomerPersonaLocationCustom, {
                        input: {
                            customerPersonaID: id,
                            geoLocationID: location.id,
                        }
                    }))
                })
                await interests.forEach((interest) => {
                    API.graphql(graphqlOperation(createCustomerPersonaInterestsCustom, {
                        input: {
                            customerPersonaID: id,
                            interestID: interest.id,
                        }
                    }))
                })
                await dispatch(getCustomerPersonasAction(businessId))
                if (!customerPersona.selected)
                    dispatch(select(fromApi?.data?.createCustomerPersona.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const deleteCustomerPersonaAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {customerPersona} = await getState();
        if (!customerPersona.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteCustomerPersona, {
                    input: {id: id}
                }))
                await dispatch(remove(businessId))
            } finally {
                dispatch(fetched())
            }
        }
    }
}
