// @ts-nocheck
import {addOne, fetched, isFetching, remove, select, updateAll, updateOne} from "./businessFaq";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinessFaqs} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {createBusinessFaq, deleteBusinessFaq, updateBusinessFaq} from "../../../graphql/mutations";
import {BusinessFaq} from "../../../API";

export const getBusinessFaqs = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listBusinessFaqs, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBusinessFaqs.items))
                const {businessFaq} = getState();
                if (!businessFaq.selected && businessFaq.businessFaqs.size !== 0) {
                    dispatch(select(businessFaq.businessFaqs[0]?.id))
                }
            })
    }
}

export const updateBusinessFaqAction = (
    newBusinessFaq: BusinessFaq) => {
    return async (dispatch, getState) => {
        const {businessFaq} = await getState();
        if (!businessFaq.isFetching) {
            dispatch(isFetching())
            try {
                delete newBusinessFaq.createdAt
                delete newBusinessFaq.updatedAt
                await API.graphql(graphqlOperation(updateBusinessFaq, {
                    input: newBusinessFaq
                }))
                await dispatch(updateOne(newBusinessFaq))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createBusinessFaqAction = (
    businessId: string,
    newBusinessFaq: BusinessFaq,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {businessFaq} = await getState();
        if (!businessFaq.isFetching || force) {
            dispatch(isFetching())
            try {
                newBusinessFaq.id = uuidv4();
                newBusinessFaq.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createBusinessFaq, {
                    input: newBusinessFaq
                }))
                await dispatch(addOne(newBusinessFaq))
                if (!businessFaq.selected)
                    dispatch(select(fromApi?.data?.createBusinessFaq.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}


export const deleteBusinessFaqAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {businessFaq} = await getState();
        if (!businessFaq.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteBusinessFaq, {
                    input: {id: id}
                }))
                await dispatch(remove(id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}