// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store";
import {CustomerPersonaSimpleModel} from "./model";


interface CustomerPersonaState {
    isFetching: boolean,
    customerPersonas?: CustomerPersonaSimpleModel[],
    selected?: string
}

const initialState: CustomerPersonaState = {
    isFetching: false,
}
const sort = (first: CustomerPersonaSimpleModel, second: CustomerPersonaSimpleModel) => {
    if (first.name < second.name) {
        return -1;
    }
    if (first.name > second.name) {
        return 1;
    }
    return 0;
}

export const customerPersonaSlice = createSlice({
    name: 'customerPersona',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<CustomerPersonaSimpleModel[]>) => {
            state.customerPersonas = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<CustomerPersonaSimpleModel>) => {
            state.customerPersonas = state.customerPersonas
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<CustomerPersonaSimpleModel>) => {
            state.customerPersonas.push(action.payload)
            state.customerPersonas.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.customerPersonas = state.customerPersonas
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        },
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = customerPersonaSlice.actions
export const selectedCustomerPersona = (state: RootState) => state.customerPersona.selected
export const allCustomerPersonas = (state: RootState) => state.customerPersona.customerPersonas as CustomerPersonaSimpleModel[]
export const customerPersonaIsFetching = (state: RootState) => state.customerPersona.isFetching

export default customerPersonaSlice.reducer