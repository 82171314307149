import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import MarketingCampaignForm from "../../../molecules/marketing-campaign-form/MarketingCampaignForm";
import {allMarketingCampaigns} from "../../../../redux/features/marketingCampaign/marketingCampaign";
import {allCustomerPersonas} from "../../../../redux/features/customerPersona/customerPersona";
import {getCustomerPersonasAction} from "../../../../redux/features/customerPersona/customerPersonaActions";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {CustomerPersonaSimpleModel} from "../../../../redux/features/customerPersona/model";
import {allSites} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import {Site} from "../../../../API";


const EditMarketingCampaignPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const marketingCampaigns = useSelector(allMarketingCampaigns);
    const customerPersonas = useSelector(allCustomerPersonas);
    const sites = useSelector(allSites);

    const filterCustomerPersonas = async (filter: string) => {
        if (customerPersonas)
            return customerPersonas?.filter(cp => cp.name?.toUpperCase().includes(filter?.toUpperCase())) || [] as CustomerPersonaSimpleModel[]
        return [] as CustomerPersonaSimpleModel[]
    };

    const filterSites = async (filter: string) => {
        if (sites)
            return sites?.filter(cp => cp.name?.toUpperCase().includes(filter?.toUpperCase())) || [] as Site[]
        return [] as Site[]
    };

    useEffect(() => {
        if (business) {
            if (!customerPersonas)
                dispatch(getCustomerPersonasAction(business.id))
            if (!sites)
                dispatch(getSites(business.id))
        }
    }, [dispatch, business, sites, customerPersonas])

    return <Container>
        <DashboardHeader title={"Marketing campaign"}
                         backTo={"/dashboard/marketing-campaign"}
        />
        <CardFormWrapper>
            <MarketingCampaignForm
                marketingCampaign={
                    marketingCampaigns?.find((product) => product.id === id)
                }
                afterSubmit={() => navigate('/dashboard/marketing-campaign')}
                customerPersonas={filterCustomerPersonas}
                sites={filterSites}
            />
        </CardFormWrapper>
    </Container>
}


export default EditMarketingCampaignPage