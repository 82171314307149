import Typography from "@mui/material/Typography";
import errorMapping from "../../errors/Errors";

type Props = {
    errors: []
}
const ErrorBox = ({errors}: Props) => {
    return (errors && errors.length > 0 && <>
        {errors.map((e: { errorCode: string }) =>
            <Typography
                variant={'subtitle1'}
                color={'error'}
                sx={{mb: 2}}
            >
                {(errorMapping.has(e.errorCode) && errorMapping.get(e.errorCode)) ||
                    "Unknown error, pelase contact support"
                }
            </Typography>)}

    </>) || <></>
}

export default ErrorBox