import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {allBrands, selectedBrand} from "../../../../redux/features/brand/brand";
import {getBrands} from "../../../../redux/features/brand/brandActions";
import {useNavigate} from "react-router-dom";
import {updateBusinessAction} from "../../../../redux/features/business/businessActions";
import {OnboardingStatus} from "../../../../API";
import OnboardingBrandForm from "../../../molecules/brand-form/OnBoardingBrandForm";

const OnBoardingBrandPage = () => {
    const navigate = useNavigate();
    const brands = useSelector(allBrands);
    const selected = useSelector(selectedBrand);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business)
            dispatch(getBrands(business.id))
    }, [dispatch, business])

    const afterSubmit = () => {
        if (business) {
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Customers))
            navigate('/onboarding/customers')
        }
    }

    return <OnboardingBrandForm brand={brands?.find((bc => bc.id === selected))}
                                afterSubmit={afterSubmit}
    />

}

export default OnBoardingBrandPage;