import {BusinessStep} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import LabeledFieldWithMaxLength from "../../atoms/labeled-field/LabeledFieldWithMaxLength";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {
    createBusinessStepAction,
    deleteBusinessStepAction,
    updateBusinessStepAction
} from "../../../redux/features/businessStep/businessStepActions";
import UploadField from "../upload-field/UploadField";
import {useState} from "react";
import {FormikErrors} from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import DeleteModal from "../../atoms/modal/DeleteModal";
import TwoColumn from "../../atoms/two-column/TwoColumn";
import {updateTutorialStatus} from "../../../redux/features/business/tutorialActions";

const validationSchema = Yup.object({
    name: Yup.string().required("This is a required field"),
    description: Yup.string(),
    order: Yup.number()
})

type Props = {
    businessStep?: BusinessStep,
    afterSubmit: () => void,
    defaultOrder: number
};


const BusinessStepFields = (props: { businessId: string, afterSubmit: () => void }) => {

    const {values, submitForm, setFieldTouched, validateForm} = useFormikContext<BusinessStep>();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false)

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['name', 'description', 'order'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            // @ts-ignore
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }

    return <>
        <TwoColumn
            left={[
                <UploadField
                    key={'coverImage'}
                    name={'coverImage'}
                    text={'Cover Image'}
                    thumbnailSize={168}
                    newFileRoot={'business/' + props.businessId + '/images'}
                />]}
            right={[<LabeledFieldWithMaxLength
                key={'name'}
                name='name'
                text='Name'
                placeholder=''
                maxLength={90}/>,
                <LabeledFieldWithMaxLength

                    key={'description'}
                    name='description'
                    text='Description'
                    placeholder=''
                    maxLength={200}/>,
                <LabeledField
                    key={'order'}
                    name='order'
                    text='Order on page'/>]}
            bottom={
                <ButtonHolder
                    textRight={"Save"}
                    textLeft={"Delete"}
                    justify={'right'}
                    submitRight={
                        () => {
                            handleSubmit()
                                .then((errors) => {
                                    if (!errors)
                                        props.afterSubmit()
                                })
                        }}
                    submitLeft={() => {
                        setOpenModal(true)
                    }}/>
            }/>
        <DeleteModal
            open={openModal}
            closeModal={() => setOpenModal(false)}
            deleteSubmit={() => {
                dispatch(deleteBusinessStepAction(
                    props.businessId,
                    values.id))
                    // @ts-ignore
                    .then(() => {
                        props.afterSubmit()
                    })
            }}/>
    </>
}


const BusinessStepForm = ({businessStep, afterSubmit, defaultOrder}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            businessStep ? businessStep
                : {
                    name: '',
                    description: '',
                    coverImage: '',
                    order: defaultOrder
                } as BusinessStep
        }
        validationSchema={validationSchema}
        onSubmit={async (values: BusinessStep) => {
            if (business) {
                if (businessStep) {
                    await dispatch(updateBusinessStepAction(values))
                } else {
                    await dispatch(createBusinessStepAction(business.id, values, false))
                }
                await updateTutorialStatus(business, dispatch, 'steps');
                afterSubmit()
            }
        }}>
        <Form>
            <BusinessStepFields businessId={business?.id as string} afterSubmit={afterSubmit}/>
        </Form>
    </Formik>
}

export default BusinessStepForm