import * as React from 'react';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {allBusinessFeatures} from "../../../../redux/features/businessFeature/businessFeature";
import BusinessFeatureForm from "../../../molecules/business-feature-form/BusinessFeatureForm";
import CardFormWrapper from '../../../atoms/card/CardFormWrapper';
import Container from '@mui/material/Container';

const EditBusinessFeaturePage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const businessFeatures = useSelector(allBusinessFeatures);


    return <Container>
        <DashboardHeader title={"Feature"}
                         backTo={"/dashboard/business-features"}
        />
        <CardFormWrapper>
            <BusinessFeatureForm businessFeature={
                businessFeatures?.find((product) => product.id === id)
            }
                                  afterSubmit={() => navigate('/dashboard/business-features')}
                // @ts-ignore
                                  defaultOrder={businessFeatures?.reduce(
                                      // @ts-ignore
                                      (max, sol) => (sol?.order > max ? sol?.order : max),
                                      businessFeatures[0]?.order || 0
                                  ) + 1 || 1}
            />
        </CardFormWrapper>
    </Container>
}


export default EditBusinessFeaturePage