import InfoCard from "../../atoms/info-card/InfoCard";
import {useDispatch, useSelector} from "react-redux";
import {allClosedInfos} from "../../../redux/features/closeInfo/closeInfo";
import {useEffect, useState} from "react";
import {closeInfoAction, getInfos} from "../../../redux/features/closeInfo/closeInfoActions";

interface ClosableInfoCardProps {
    header: string,
    items: string[],
    image?: string,
    buttonLink?: string,
    buttonText?: string,
    backgroundColor?: string,
    textColor?: string,
    code: string
}


const ClosableInfoCard = ({header, items, image, buttonLink, buttonText, backgroundColor, textColor, code}: ClosableInfoCardProps) => {
    const closedInfos = useSelector(allClosedInfos)
    const dispatch = useDispatch();
    const [isClosed, setIsClosed] = useState<boolean>(true)

    useEffect(() => {
        if (!closedInfos)
            dispatch(getInfos())
    })

    useEffect(() => {
        if (closedInfos)
            setIsClosed(Boolean(closedInfos?.find(info => info.code === code)?.closed))
    }, [closedInfos, code])

    const closeInfo = (): void => {
        dispatch(closeInfoAction(code))
    }

    return <>{isClosed ?
        <></> :
        <InfoCard
            header={header}
            items={items}
            image={image}
            buttonLink={buttonLink}
            buttonText={buttonText}
            backgroundColor={backgroundColor}
            textColor={textColor}
            closeInfo={closeInfo}
        />
    }
    </>
}

export default ClosableInfoCard