import {Autocomplete, TextField} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";

import {Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";


const AutoCompleteSelect = (props: {
                                name: string,
                                text: string,
                                fetchOptions: (filter: string) => Promise<any[]>,
                                minChars: number,
                                multiple: boolean
                            }
) => {

    const [input, setInput] = useState('')
    const [options, setOptions] = useState<any>([])
    const {values, setFieldValue} = useFormikContext();

    const optionsCallback = useCallback(() => {
        if (input.length >= props.minChars)
            props.fetchOptions(input).then((options => setOptions(options)))
        else
            setOptions([])
    }, [input, props])

    useEffect(() => {
        optionsCallback()
    }, [input, values, optionsCallback])


    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({field}: FieldAttributes<any>) => (
            <>
                <Autocomplete
                    {...field}
                    sx={{
                        backgroundColor: 'white',
                        justifySelf: 'center',
                        // width:'400px',
                        flexGrow: 1
                    }}
                    multiple={props.multiple}
                    options={options ? options : []}
                    filterOptions={(x) => x}
                    getOptionLabel={(option: { name?: string }) => {
                        return option.name?.replace(/\w\S*/g, (m: string) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase())
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option: { id: any }, value: { id: any }) => option?.id === value?.id}
                    onChange={(e, value) => {
                        setFieldValue(
                            props.name,
                            value !== null ? value : {}
                        );
                    }}

                    fullWidth={true}
                    renderInput={(params) => (

                        <TextField
                            {...params}
                            variant="outlined"
                            size={"small"}
                            placeholder="Start typing"
                            sx={{flexGrow: 1}}
                        />
                    )}
                />
            </>
        )}
        </Field>
    </FieldHorizontalWrapper>
}

export default AutoCompleteSelect