import {ColorPicker, ColorValue} from 'mui-color';
import {Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";
import {FormHelperText} from "@mui/material";
import Stack from "@mui/material/Stack";
import FieldHorizontalWrapper from "../field-wrapper/FieldHorizontalWrapper";


const ColorPickerField = (props: {
                              name: string,
                              text: string,
                              helperText?: (value: any) => string,
                          }
) => {
    const {values, setFieldValue} = useFormikContext();

    return <FieldHorizontalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({
               field,
               form,
           }: FieldAttributes<any>) => (
            <>
                <Stack>
                    <ColorPicker

                        value={field.value}
                        onChange={(x: ColorValue) => {
                            // @ts-ignore
                            setFieldValue(props.name, "#"+x.hex)
                        }}

                    />
                </Stack>
                <Stack>
                    <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
                </Stack>
            </>
        )}
        </Field>
    </FieldHorizontalWrapper>
}

export default ColorPickerField