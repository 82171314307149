// @ts-nocheck
import { createSiteAction, deployAction, updateSiteAction } from "../../../redux/features/site/siteActions";
import { Site, SiteAction } from "../../../API";
import { Form, Formik, useFormikContext } from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../redux/features/business/business";
import WaitingModal from "../../atoms/modal/WaitingModal";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import { FormikErrors } from "formik/dist/types";
import ErrorModal from "../../atoms/modal/ErrorModal";

const validationSchema = Yup.object().shape({
    name: Yup.string().required()
        .test(
            'urlCheck',
            'This field must be in lowercase',
            (item) => item !== undefined ? item.toLowerCase() === item : true
        )
        .matches(/^[aA-zZ\d]+$/, "Only letters are allowed in this field"),
    live: Yup.object().shape({
        functions: Yup.object().shape({
            form: Yup.object().shape({
                forwardTo: Yup.string().email("Please add a valid email").required("The leads email is required"),
            })
        })
    })
})


type Props = {
    site?: Site,
    onboarding: boolean,
    afterSubmitNext: () => void,
    afterSubmitBack: () => void,
    defaultName: string
};

const SiteBaseFields = (props: {
    afterSubmitNext: () => void,
    afterSubmitBack: () => void
}) => {
    const { submitForm, setFieldTouched, validateForm } = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['name', 'live'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <Box sx={{ maxWidth: '380px', flexDirection: "column", margin: 'auto' }}>
        <LabeledField key='name' name='name' text='Site name' placeholder='Acme'
            helperText={(value) => {
                return "https://" + (value ? value : "acme") + "." + process.env.REACT_APP_DOMAIN_SUFFIX
            }}
            transformField={(value) => value?.toLowerCase()}

        />
       
            <Typography variant="body1" textAlign={"left"} my={3}>Your will notified by email when a visitor messages you using the form on the site.</Typography>
        
        <LabeledField key='forwardTo' name='live.functions.form.forwardTo' text='Contact email' />
        <ButtonHolder

            justify={'right'}
            submitRight={
                () => {
                    handleSubmit()
                        .then((errors) => {
                            if (!errors)
                                props.afterSubmitNext()
                        })
                }}
            textRight="Create site"
        />
    </Box>

}

// Always set all values with empty strings/values otherwise formik will go bonkers
const initialValues = {
    name: '',
    live: {
        gtmTag: null,
        functions: {
            actions: [SiteAction.Leads],
            form: {
                forwardTo: '',
                bottomHeader: 'Take the next step',
                title: 'Get in touch',
                buttonText: 'Get in touch',
                firstNameLabel: "First name",
                firstNameEnabled: false,
                firstNameRequired: false,
                lastNameLabel: "Last name",
                lastNameEnabled: false,
                lastNameRequired: false,
                locationLabel: "Location",
                locationEnabled: false,
                locationRequired: false,
                phoneLabel: "Phone",
                phoneEnabled: false,
                phoneRequired: false,
                dateLabel: "Date",
                dateEnabled: false,
                dateRequired: false,
                options: "",
                optionsLabel: "Options",
                optionsEnabled: false,
                optionsRequired: false,
                multiOptions: false,
                emailHeader: 'Your email',
                emailHeaderEnabled: true,
                emailHeaderRequired: true,
                formHeader: 'How can we help?',
                formHeaderEnabled: true,
                formHeaderRequired: false,
                successMessage: "Your message has been sent. Thank you!",
                redirectUrl: "",
            }
        },
        styling: {
            primaryText: '#212121',
            buttons: '#212121',
            header: '#FAFAFA',
            footerBackground: '#212121',
            footerText: '#FAFAFA',
            background: '#FAFAFA',
            tilesBackground: '#FFFFFF'
        },
        layout: {
            siteTitle: "title",
            sectionTitles: true,
            headerType:"cover",
            contentAlign:"left",
            buttonShape:"pill",
            density:"full"
          }
    }
}


const OnboardingSiteForm = ({ site, afterSubmitNext, afterSubmitBack }: Props) => {
    const [openWaitModal, setOpenWaitModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [error, setError] = useState<string>()
    const openModal = () => setOpenWaitModal(true);

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    function onError(error) {
        setError(error?.errors[0]?.errorCode)
        setOpenWaitModal(false)
        setOpenErrorModal(true)
        return error.errors
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={
                site ? site : initialValues
            }
            validationSchema={validationSchema}
            onSubmit={(values) => {
                openModal()
                let newVal = Object.assign({}, values)
                const prefix = "." + process.env.REACT_APP_DOMAIN_SUFFIX
                newVal.live = {
                    ...newVal.live,
                    url: newVal.name + prefix,
                }
                delete newVal.lastDeployment
                if (site?.id) {
                    return dispatch(updateSiteAction(
                        business.id,
                        newVal
                    ))
                        .then(async (newSite) => {
                            await dispatch(deployAction(newSite.businessId, newSite.id, 'live'))
                        })
                        .catch(onError)
                } else {
                    return dispatch(createSiteAction(
                        business.id,
                        newVal
                    ))
                        .then((newSite) => {
                            dispatch(deployAction(newSite.businessId, newSite.id, 'live'))
                        })
                        .catch(onError)
                }
            }}>
            <Container sx={{ pb: 4 }}>
                <Form>
                    <Stack spacing={1} mb={3}>
                        <Typography variant="h1">Create a new site</Typography>
                        <Typography variant="body1" color="gray">
                            If you already have a website, use this one as your campaign page.
                        </Typography>
                        </Stack>
                    <SiteBaseFields
                        afterSubmitNext={afterSubmitNext}
                        afterSubmitBack={afterSubmitBack} />

                </Form>
            </Container>
        </Formik>

        {openWaitModal && <WaitingModal text="Hang tight! We're building your site..." />}
        {openErrorModal && <ErrorModal
            errorCode={error}
            closeModal={() => setOpenErrorModal(false)}
            open={openErrorModal}
        />}
    </>
}

export default OnboardingSiteForm