import {Box} from "@mui/system";

const TwoColumn = (props: {
    left: JSX.Element[],
    right: JSX.Element[],
    bottom: JSX.Element,
    leftColumnAlign?: 'start' | 'center'}
) => {
    return <>
        <Box sx={{
            alignSelf: 'center',
            p:1
        }}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)'
                },
                columnGap: 3,
                rowGap: 1,
                maxWidth: '752px',
                margin: 'auto'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: {xs: 'center', sm: 'end'},
                    alignItems: {xs: 'center', sm: props.leftColumnAlign? props.leftColumnAlign : 'start'},
                }}>
                    <Box>
                        {props.left}
                    </Box>
                </Box>
                <Box>
                    {props.right}
                </Box>
                <Box/>
                <Box>
                    {props.bottom}
                </Box>
            </Box>
        </Box>
    </>
}

export default TwoColumn