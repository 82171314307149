import {
    createCustomerPersonaAction,
    deleteCustomerPersonaAction,
    updateCustomerPersonaAction
} from "../../../redux/features/customerPersona/customerPersonaActions";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import OptionField from "../../atoms/option-field/OptionField";
import AutoCompleteSelect from "../../atoms/autocomplete-select/AutoCompleteSelect";
import {useState} from "react";
import {FormikErrors} from "formik/dist/types";
import TwoColumn from "../../atoms/two-column/TwoColumn";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import DeleteModal from "../../atoms/modal/DeleteModal";
import image from "../../../images/illustrations/customer.png";
import {memorizedInterests} from "../../../redux/features/customerPersona/utils";
import {CustomerPersonaSimpleModel} from "../../../redux/features/customerPersona/model";

const AGE = ['18-24', '25-34', '35-44', '44-54', '54-54', '65+']
const GENDER = ['Male', 'Female']

const validationSchema = Yup.object().shape({
    age: Yup.array().min(1),
    gender: Yup.array().min(1),
    // locations: Yup.array().min(1),
    interests: Yup.array().min(1),
})

type Props = {
    customerPersona?: CustomerPersonaSimpleModel,
    afterSubmit: () => void
};


const CustomerPersonaBaseFields = (props: { businessId: string, afterSubmit: () => void }) => {

    const {values, submitForm, setFieldTouched, validateForm} = useFormikContext<CustomerPersonaSimpleModel>();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false)


    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['age', 'gender', //'locations',
            'interests'].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            // @ts-ignore
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }

    return <>
        <TwoColumn
            leftColumnAlign={'center'}
            left={[
                <img
                    key={"CustomerIllustration"}
                    src={image}
                    alt={'Customer persona'}
                    width="100%"
                />
            ]}
            right={[
                <LabeledField key='name' name='name' text='Name' placeholder=''/>,
                <OptionField multiselect={true}
                             key='gender' name='gender' text={'Gender'}
                             options={GENDER}
                />,
                <OptionField key='age' multiselect={true}
                             name='age' text={'Age'}
                             options={AGE}
                />,
                // <AutoCompleteSelect
                //     key='locations'
                //     name='locations'
                //     text={'Location'}
                //     fetchOptions={memorizedLocations}
                //     minChars={2}
                //     multiple
                // />,
                <AutoCompleteSelect
                    key='interests'
                    name='interests'
                    text={'Interests'}
                    fetchOptions={memorizedInterests}
                    minChars={0}
                    multiple
                />
            ]}
            bottom={
                <ButtonHolder
                    textRight={"Save"}
                    textLeft={"Delete"}
                    justify={'right'}
                    submitRight={
                        () => {
                            handleSubmit()
                                .then((errors) => {
                                    if (!errors)
                                        props.afterSubmit()
                                })
                        }}
                    submitLeft={() => {
                        setOpenModal(true)
                    }}/>}/>
        <DeleteModal
            open={openModal}
            closeModal={() => setOpenModal(false)}
            deleteSubmit={() => {
                dispatch(deleteCustomerPersonaAction(
                    props.businessId,
                    values.id))
                    // @ts-ignore
                    .then(() => {
                        props.afterSubmit()
                    })
            }}/>
    </>
}


const CustomerPersonaForm = ({customerPersona, afterSubmit}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            customerPersona ? customerPersona :
                {
                    __typename: "TargetCustomer",
                    id: '',
                    businessId: '',
                    createdAt: '',
                    updatedAt: '',
                    age: [],
                    gender: [],
                    name: '',
                    locations: [],
                    interests: [],
                } as CustomerPersonaSimpleModel
        }
        validationSchema={validationSchema}
        onSubmit={async (values: CustomerPersonaSimpleModel) => {
            if (business)
                if (customerPersona?.id) {
                    await dispatch(updateCustomerPersonaAction(
                        business.id,
                        customerPersona.id,
                        values.name as string,
                        values.age,
                        values.interests,
                        values.gender,
                        values.locations))

                } else {
                    await dispatch(createCustomerPersonaAction(
                        business.id,
                        values.name as string,
                        values.age,
                        values.interests,
                        values.gender,
                        values.locations))
                }
        }}>
        <Form>
            <CustomerPersonaBaseFields businessId={business?.id as string} afterSubmit={afterSubmit}/>
        </Form>
    </Formik>
}

export default CustomerPersonaForm