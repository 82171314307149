import {Box} from "@mui/material";
import * as React from 'react';
import Modal from '@mui/material/Modal';


const FormModal = (props: {
    open: boolean,
    marketingCampaignId: string,
    onSubmit: () => void,
    closeModal: () => void
    children: JSX.Element
}) => {
    return <Modal
        open={props.open}
        aria-describedby="modal-modal-description"
    >
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                xs: '90wv',
                md: '50vw'
            },
            maxWidth: 440,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            p: 4,
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Box sx={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <Box id="modal-modal-description" sx={{
                        m: 2,
                        fontSize: 'h4.fontSize',
                        fontFamily: 'h4.fontFamily',
                        textAlign: 'center'
                    }}>
                        {'Run marketing campaign'}
                    </Box>
                </Box>
                {props.children}
            </Box>
        </Box>
    </Modal>
}

export default FormModal