// @ts-nocheck
import {Box} from "@mui/system";
import {Button, Stack} from "@mui/material";
import {DeploymentStatus} from "../../../API";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import DashboardHeader from "../../atoms/dashboard/DashboardHeader";
import {deployAction, getSites} from "../../../redux/features/site/siteActions";
import {API, graphqlOperation} from "aws-amplify";
import {onUpdateSideDeploymentByIdCustom} from "../../../graphql/custom_subscriptions";
import {useDispatch, useSelector} from "react-redux";
import {lastDeployment, selectedSite, updateLastDeployment} from "../../../redux/features/site/site";
import {selectedBusiness} from "../../../redux/features/business/business";

type Props = {
    title: string,
    backTo?: string,
    underTitle?: JSX.Element,
    siteUrl?: string,
};

const statusToText = new Map<DeploymentStatus, string>([
    [DeploymentStatus.SCHEDULED, "Your site is being published 🏗"],
    [DeploymentStatus.STARTED, "Your site is being published 🏗"],
    [DeploymentStatus.VERIFYING, "Verifying..."],
    [DeploymentStatus.WAITING_FOR_HTTPS, "Making your website secure 🔒"],
    [DeploymentStatus.COMPLETE, "Your site is published ✅"],
    [DeploymentStatus.FAILED, "Publish failed. Please try again in a few minutes"],
    [DeploymentStatus.EXPIRED, ""],
])


const SiteHeader = ({title, backTo, underTitle, siteUrl}: Props) => {
    const [buttonState, setButtonState] = useState<DeploymentStatus | 'Loading' | 'ButtonPressed'>('Loading');
    const site = useSelector(selectedSite);
    const business = useSelector(selectedBusiness)

    const deployment = useSelector(lastDeployment);
    const dispatch = useDispatch();

    const publish = () => {
        setButtonState('ButtonPressed')
        if (business && site)
            dispatch(deployAction(business.id,
                site.id,
                'live'
            ))
                .then(() => {
                    setButtonState(DeploymentStatus.SCHEDULED)
                }).catch((err) => {
                console.log(err)
                setButtonState(DeploymentStatus.FAILED)
            })
    }

    useEffect(() => {
        if (business)
            dispatch(getSites(business.id))
    }, [dispatch, business])

    useEffect(() => {
        if (deployment)
            setButtonState(deployment.deploymentStatus)
    }, [deployment])

    useEffect(() => {
        if (deployment?.id) {
            API.graphql(
                graphqlOperation(onUpdateSideDeploymentByIdCustom,
                    {
                        'id': deployment.id
                    }
                )
            ).subscribe({
                next: ({provider, value}) => {
                    if (value?.data?.onUpdateSideDeploymentById) {
                        setButtonState(value?.data?.onUpdateSideDeploymentById.deploymentStatus)
                        dispatch(updateLastDeployment({
                            id: value?.data?.onUpdateSideDeploymentById.id,
                            deploymentStatus: value?.data?.onUpdateSideDeploymentById.deploymentStatus
                        }))
                    }
                },
                error: error => {
                    console.log(error)
                    setButtonState(undefined)
                }
            });
        }
    }, [deployment, site, dispatch])

    const url = useCallback(() => {
        return 'https://' + (siteUrl ? siteUrl : site?.live?.url)
    }, [siteUrl, site])

    const openUrl = () => {
        if (site?.live?.url)
            window.open(url(), '_blank');
    }

    function isDisabled(buttonState) {
        return [
            DeploymentStatus.SCHEDULED,
            DeploymentStatus.STARTED,
            DeploymentStatus.VERIFYING,
            DeploymentStatus.WAITING_FOR_HTTPS,
            'Loading',
            'ButtonPressed'
        ].indexOf(buttonState) > -1;
    }

    return <DashboardHeader
        title={title}
        underTitle={
            <>
                {underTitle}
            </>
        }
        backTo={backTo}
        actionSection={

            <Box sx={{
                mt: {xs: 2, sm: 1},
                width: {xs: '100%'}
            }}>
                <Stack
                    direction="row"
                    alignItems='start'
                    spacing={1}>
                    <Stack spacing={1} sx={{
                        width: '100%'
                    }}>
                        <Button
                            sx={{
                                width: '100%',
                                borderRadius: 100,
                                backgroundColor: "black",
                            }}
                            variant={"contained"}
                            size={"large"}
                            onClick={publish}
                            disabled={isDisabled(buttonState)}
                        >
                            Publish
                        </Button>
                        <Box sx={{
                            fontSize: 'subtitle2.fontSize',
                            fontFamily: 'subtitle2.fontFamily'
                        }}>
                            {statusToText.get(buttonState)}
                        </Box>
                    </Stack>
                    <Button
                            sx={{
                                width: '100%',
                                borderRadius: 100
                            }}
                            variant={"outlined"}
                            size={"large"}
                            onClick={openUrl}
                            disabled={isDisabled(buttonState)}
                        >
                            Preview
                        </Button>

                </Stack>

            </Box>

        }
    />
}

export default SiteHeader;