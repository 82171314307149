import Card from "@mui/material/Card";
import * as React from "react";
import { CardContent } from "@mui/material";
import Typography from '@mui/material/Typography';

export enum CardState {
    Blank = "Blank",
    Invitation = "No data",
    ComingSoon = "Coming Soon"
}

type Props = {
    value: string | number,
    label: string,
};

const ScoreCard = ({ value, label }: Props) => {
    return <Card sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
    }}
    >
        <CardContent>
            <Typography variant="h1">{value}</Typography>
            <Typography variant="h5" color='#71717A' fontWeight={700}>{label}</Typography>
        </CardContent>
    </Card>
}

export default ScoreCard