// @ts-nocheck
import {Site} from "../../../API";
import {Form, Formik, useFormikContext} from "formik";
import LabeledField from "../../atoms/labeled-field/LabeledField";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import Typography from '@mui/material/Typography';
import {FormikErrors} from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import {deployAction, updateSiteAction} from "../../../redux/features/site/siteActions";
import {useState} from "react";
import WaitingModal from "../../atoms/modal/WaitingModal";
import ErrorModal from "../../atoms/modal/ErrorModal";
import {updateTutorialStatus} from "../../../redux/features/business/tutorialActions";
import {initialValues} from "../../../redux/features/site/initialValues";
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const validationSchema = Yup.object({
    live: Yup.object().shape({
        url: Yup.string().required("This field is required")
            .test(
                'urlCheck',
                'This field must be lowercase',
                (item) => item !== undefined ? item.toLowerCase() === item : true
            ).matches(/^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/, "Enter a valid URL"),
    })

})

type Props = {
    site?: Site,
    afterSubmit: () => void
};



const BusinessStepFields = (props: { businessId?: string, afterSubmit: () => void }) => {

    const {values, submitForm, setFieldTouched, validateForm} = useFormikContext<Site>();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['live.url',].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    submitForm();
                }
                return errors
            });
    }
    return <Stack sx={{textAlign:"left"}}>
        <Typography variant="h3">Add a custom domain</Typography>
        <Typography variant="body1" color="gray">By default, your site comes with the free
            domain<strong>{process.env.REACT_APP_DOMAIN_SUFFIX}</strong>.</Typography>
        <Stack sx={{width: '100%', maxWidth: '380px', margin: 'auto', pt: 2}} spacing={2}>
            <Alert severity="info"><AlertTitle>To add your own domain:</AlertTitle>
                <ol style={{padding: "0"}}>
                    <li>Go to your DNS provider</li>
                    <li>Add an A record pointing to <strong>75.2.60.5</strong></li>
                    <li>Add your custom domain below</li>
                </ol>
            </Alert>
            <LabeledField name='live.url' text='Site URL'
                          transformField={(value) => value?.toLowerCase()}/>
            <ButtonHolder
                justify={'right'}
                textRight={"Save"}
                submitRight={
                    () => {
                        handleSubmit()
                            .then((errors) => {
                                if (!errors)
                                    props.afterSubmit()
                            })
                    }}
            />
        </Stack>
    </Stack>

}


const CustomDomainForm = ({site, afterSubmit}: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const [openWaitModal, setOpenWaitModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [error, setError] = useState<string>()
    const openModal = () => setOpenWaitModal(true);

    function onError(error: any) {
        setError(error?.errors[0]?.errorCode)
        setOpenWaitModal(false)
        setOpenErrorModal(true)
        return error.errors
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={
                site ? site : initialValues
            }
            validationSchema={validationSchema}
            onSubmit={(values) => {
                openModal()
                // @ts-ignore
                return dispatch(updateSiteAction(business.id, values))
                    .then(async (newSite: Site) => {
                        await dispatch(deployAction(newSite.businessId, newSite.id, 'live'))
                        setOpenWaitModal(false)
                        await updateTutorialStatus(business, dispatch, 'domain');
                        afterSubmit()
                    })
                    .catch(onError)

            }}>
            <Form>
                <BusinessStepFields businessId={business?.id} afterSubmit={afterSubmit}/>
            </Form>
        </Formik>
        {openWaitModal && <WaitingModal text="Hang tight! We're building your site"/>}
        {openErrorModal && <ErrorModal
            errorCode={error}
            closeModal={() => setOpenErrorModal(false)}
            open={openErrorModal}
        />}
    </>
}

export default CustomDomainForm