/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://xaq5enlnv5f65inbnzfspxl3me.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pipub2vixnhapeujcv7kgmd22q",
    "aws_cloud_logic_custom": [
        {
            "name": "leads",
            "endpoint": "https://49dwe9zsda.execute-api.ap-southeast-2.amazonaws.com/live",
            "region": "ap-southeast-2"
        },
        {
            "name": "marketingCampaigns",
            "endpoint": "https://tpni5pqd5e.execute-api.ap-southeast-2.amazonaws.com/live",
            "region": "ap-southeast-2"
        },
        {
            "name": "metrics",
            "endpoint": "https://8z3x3s99vh.execute-api.ap-southeast-2.amazonaws.com/live",
            "region": "ap-southeast-2"
        },
        {
            "name": "sites",
            "endpoint": "https://s10tqo29wh.execute-api.ap-southeast-2.amazonaws.com/live",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:1c2d5459-cf61-4267-9f63-bfb2ffcaebad",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_UiFRpLnDo",
    "aws_user_pools_web_client_id": "2eip81v37tgjs5u8auvi0e9faa",
    "oauth": {
        "domain": "tryevergrow-live.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://tryevergrow.com/,http://localhost:3000/",
        "redirectSignOut": "https://tryevergrow.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "content194621-live",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
