import * as React from 'react';
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {allBusinessFaqs} from "../../../../redux/features/businessFaq/businessFaq";
import BusinessFaqForm from "../../../molecules/business-faq-form/BusinessFaqForm";
import CardFormWrapper from '../../../atoms/card/CardFormWrapper';
import Container from '@mui/material/Container';

const EditBusinessFaqPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const businessFaqs = useSelector(allBusinessFaqs);


    return <Container>
        <DashboardHeader title={"Faq"}
                         backTo={"/dashboard/business-faqs"}
        />
        <CardFormWrapper>
            <BusinessFaqForm businessFaq={
                businessFaqs?.find((product) => product.id === id)
            }
                                  afterSubmit={() => navigate('/dashboard/business-faqs')}
                // @ts-ignore
                                  defaultOrder={businessFaqs?.reduce(
                                      // @ts-ignore
                                      (max, sol) => (sol?.order > max ? sol?.order : max),
                                      businessFaqs[0]?.order || 0
                                  ) + 1 || 1}
            />
        </CardFormWrapper>
    </Container>
}


export default EditBusinessFaqPage