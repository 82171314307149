// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BusinessTestimonial} from "../../../API";
import {RootState} from "../../store";

interface BusinessTestimonialState {
    isFetching: boolean,
    businessTestimonials?: [BusinessTestimonial],
    selected?: string
}

const initialState: BusinessTestimonialState = {
    isFetching: false,
}

const sort = (first, second) => first.order - second.order

export const businessTestimonialSlice = createSlice({
    name: 'businessTestimonial',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[BusinessTestimonial]>) => {
            state.businessTestimonials = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<BusinessTestimonial>) => {
            state.businessTestimonials = state.businessTestimonials
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<BusinessTestimonial>) => {
            state.businessTestimonials.push(action.payload)
            state.businessTestimonials.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.businessTestimonials = state.businessTestimonials
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = businessTestimonialSlice.actions
export const selectedBusinessTestimonial = (state: RootState) => state.businessTestimonial.selected
export const allBusinessTestimonials = (state: RootState) => state.businessTestimonial.businessTestimonials
export const businessTestimonialsIsFetching = (state: RootState) => state.businessTestimonial.isFetching

export default businessTestimonialSlice.reducer