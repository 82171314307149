import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import BrandForm from "../../../molecules/brand-form/BrandForm";
import {useDispatch, useSelector} from "react-redux";
import {allBrands, selectedBrand} from "../../../../redux/features/brand/brand";
import {getBrands} from "../../../../redux/features/brand/brandActions";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import CardFormWrapper from "../../../atoms/card/CardFormWrapper";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import ClosableInfoCard from "../../../molecules/info-card/ClosableInfoCard";
import image from "../../../../images/illustrations/brand.png";

const tips = ['There is more than one way you can describe your business to your customers. Make sure your value proposition stands out.']

const BrandPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const brands = useSelector(allBrands);
    const selected = useSelector(selectedBrand);

    useEffect(() => {
        if (business) {
            dispatch(getBrands(business.id))
        }

    }, [dispatch, business])

    return <Container>
        <DashboardHeader
            title={"Business / Brand"}
            backTo={"/dashboard/business"}
            underTitle={
                <ClosableInfoCard
                    code={'BRAND'}
                    header={'Describe your business to your customers'}
                    items={tips}
                    image={image}
                    />}
        />
        <CardFormWrapper>
            <BrandForm brand={brands?.find((b) => b.id === selected)}
                       afterSubmit={() => navigate('/dashboard/business')}
            />
        </CardFormWrapper>
    </Container>
}


export default BrandPage