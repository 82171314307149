import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import CardContainer from "../../../atoms/card/CardContainer";
import AddNewCard from "../../../atoms/card/AddNewCard";
import {allBusinessTestimonials} from "../../../../redux/features/businessTestimonial/businessTestimonial";
import {getBusinessTestimonials} from "../../../../redux/features/businessTestimonial/businessTestimonialActions";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";

const BusinessTestimonialsPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const businessTestimonials = useSelector(allBusinessTestimonials);


    useEffect(() => {
        if (business) {
            dispatch(getBusinessTestimonials(business.id))
        }

    }, [dispatch, business])

    return <Container>
        <DashboardHeader title={"Business / Testimonials"}
                    backTo={"/dashboard/business"}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                businessTestimonials ?
                    businessTestimonials?.map(step => {
                        return <ClickableCard
                            title={step.who as string}
                            text={step.testimonial as string}
                            status={CardState.Blank}
                            onClick={() => navigate('/dashboard/testimonials/' + step.id)}
                        />
                    }).concat(
                        <AddNewCard
                            onClick={() => navigate('/dashboard/testimonials/new')}
                        />
                    ) : [<AddNewCard
                        onClick={() => navigate('/dashboard/testimonials/new')}
                    />]}
        />
    </Container>
}


export default BusinessTestimonialsPage