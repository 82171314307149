// @ts-nocheck
import {addOne, fetched, isFetching, remove, select, updateAll, updateOne} from "./businessPartner";
import {API, graphqlOperation} from "aws-amplify";
import {listBusinessPartners} from "../../../graphql/queries";
import {v4 as uuidv4} from "uuid";
import {createBusinessPartner, deleteBusinessPartner, updateBusinessPartner} from "../../../graphql/mutations";
import {BusinessPartner} from "../../../API";

export const getBusinessPartners = (businessId: string) => {
    return async (dispatch, getState) => {
        return API.graphql(graphqlOperation(listBusinessPartners, {
            filter: {
                businessId: {
                    eq: businessId
                }
            }
        }))
            .then((got) => {
                dispatch(updateAll(got?.data?.listBusinessPartners.items))
                const {businessPartner} = getState();
                if (!businessPartner.selected && businessPartner.businessPartners.size !== 0) {
                    dispatch(select(businessPartner.businessPartners[0]?.id))
                }
            })
    }
}

export const updateBusinessPartnerAction = (
    newBusinessPartner: BusinessPartner) => {
    return async (dispatch, getState) => {
        const {businessPartner} = await getState();
        if (!businessPartner.isFetching) {
            dispatch(isFetching())
            try {
                delete newBusinessPartner.createdAt
                delete newBusinessPartner.updatedAt
                await API.graphql(graphqlOperation(updateBusinessPartner, {
                    input: newBusinessPartner
                }))
                await dispatch(updateOne(newBusinessPartner))
            } finally {
                dispatch(fetched())
            }
        }
    }
}

export const createBusinessPartnerAction = (
    businessId: string,
    newBusinessPartner: BusinessPartner,
    force?: boolean) => {
    return async (dispatch, getState) => {
        const {businessPartner} = await getState();
        if (!businessPartner.isFetching || force) {
            dispatch(isFetching())
            try {
                newBusinessPartner.id = uuidv4();
                newBusinessPartner.businessId = businessId;
                const fromApi = await API.graphql(graphqlOperation(createBusinessPartner, {
                    input: newBusinessPartner
                }))
                await dispatch(addOne(newBusinessPartner))
                if (!businessPartner.selected)
                    dispatch(select(fromApi?.data?.createBusinessPartner.id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}


export const deleteBusinessPartnerAction = (
    businessId: string,
    id: string
) => {
    return async (dispatch, getState) => {
        const {businessPartner} = await getState();
        if (!businessPartner.isFetching) {
            dispatch(isFetching())
            try {
                await API.graphql(graphqlOperation(deleteBusinessPartner, {
                    input: {id: id}
                }))
                await dispatch(remove(id))
            } finally {
                dispatch(fetched())
            }
        }
    }
}