import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useNavigate} from "react-router-dom";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import CardContainer from "../../../atoms/card/CardContainer";
import AddNewCard from "../../../atoms/card/AddNewCard";
import {allBusinessSteps} from "../../../../redux/features/businessStep/businessStep";
import {getBusinessSteps} from "../../../../redux/features/businessStep/businessStepActions";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";

const BusinessStepsPage = () => {

    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const businessSteps = useSelector(allBusinessSteps);


    useEffect(() => {
        if (business) {
            dispatch(getBusinessSteps(business.id))
        }

    }, [dispatch, business])

    return <Container>
        <DashboardHeader title={"Business / How it works"}
                    backTo={"/dashboard/business"}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                businessSteps ?
                    businessSteps?.map(step => {
                        return <ClickableCard
                            title={step.name as string}
                            text={step.description as string}
                            status={CardState.Blank}
                            onClick={() => navigate('/dashboard/business-steps/' + step.id)}
                        />
                    }).concat(
                        <AddNewCard
                            onClick={() => navigate('/dashboard/business-steps/new')}
                        />
                    ) : [<AddNewCard
                        onClick={() => navigate('/dashboard/business-steps/new')}
                    />]}
        />

    </Container>
}


export default BusinessStepsPage