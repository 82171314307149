import LogoDark from "../../../images/logo-dark.svg";
import {AppBar, Box, Toolbar} from '@mui/material';
import Container from '@mui/material/Container';
import {useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {OnboardingStatus} from "../../../API";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
    'Brand',
    'Customers',
    'Site',
    'Campaign'
];


const OnBoardingToolbar = () => {
    const customer = useSelector(selectedBusiness)

    const progress =
        customer?.onboardingStatus === OnboardingStatus.Brand ? 0 :
            customer?.onboardingStatus === OnboardingStatus.Customers ? 1 :
                customer?.onboardingStatus === OnboardingStatus.Site ? 2 :
                    customer?.onboardingStatus === OnboardingStatus.Campaign ? 3 : 0
    return <Box sx={{flexGrow: 1, mt: 2, mb: 4}} id="Navbar">
        <AppBar position="static" color={"transparent"} elevation={0}>
            <Toolbar>
                <Box sx={{
                    display: {sm: 'flex-col', lg: 'flex'},
                    width: '100%',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                }}>
                    <img src={LogoDark} alt={"Evergrow"}/>
                    <Container maxWidth="sm">
                        <Stepper activeStep={progress}
                                 alternativeLabel sx={{pt: 2}}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                    <Box sx={{
                        width: {xs: '0%', sm: '177px'},
                    }}
                    ></Box>
                </Box>
            </Toolbar>
        </AppBar>
    </Box>
}

export default OnBoardingToolbar;
