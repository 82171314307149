import { FormHelperText } from "@mui/material";
import { Field, useField } from "formik";
import { FieldAttributes } from "formik/dist/Field";
import FieldVerticalWrapper from "../field-wrapper/FieldVerticalWrapper";
import Switch from '@mui/material/Switch';

// @ts-ignore
const Check = ({ field }) => {
    return <Switch {...field}
        color="secondary"
    />
}

const LabeledCheckboxField = (props: {
    name: string,
    text: string,
    placeholder?: string,
    helperText?: (value: any) => string,
}
) => {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props.name);
    return <FieldVerticalWrapper name={props.name} text={props.text}>
        <Field
            name={props.name}
        >{({
            field,
            form,
        }: FieldAttributes<any>) => (
            <>

                <Field type="checkbox" name={props.name}
                    component={Check}
                />
                <FormHelperText>{props.helperText ? props.helperText(field.value) : ''}</FormHelperText>
            </>
        )}
        </Field>
    </FieldVerticalWrapper>
}

export default LabeledCheckboxField;