import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SideToolbarListItem = (props: {
    text: string,
    icon?: JSX.Element,
    onClick: () => void,
    selected: boolean,
}
) => {
    return <ListItem button key={props.text} onClick={props.onClick} sx={{borderRadius:100}}>
        <Stack 
            direction={'row'} 
            spacing={2} 
            justifyContent="center"
            alignItems="center" 
            sx={{
                fontSize: 'h4.fontSize',
                fontWeight: '700',
            }}
            color={props.selected ? 'secondary.main' : 'common.black'}
        >
            {props.icon}

            <Typography variant="h5">
            <ListItemText disableTypography primary={props.text} />
            </Typography>
        </Stack>
    </ListItem>
}


export default SideToolbarListItem;
