import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../redux/features/business/business";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import { FormikErrors } from "formik/dist/types";
import OptionField from "../../atoms/option-field/OptionField";
import AutoCompleteSelect from "../../atoms/autocomplete-select/AutoCompleteSelect";
import {
    createCustomerPersonaAction,
    updateCustomerPersonaAction
} from "../../../redux/features/customerPersona/customerPersonaActions";
import { CustomerPersonaSimpleModel } from "../../../redux/features/customerPersona/model";
import { memorizedInterests } from "../../../redux/features/customerPersona/utils";

const AGE = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
const GENDER = ['Female', 'Male']

const validationSchema = Yup.object().shape({
    age: Yup.array().min(1),
    gender: Yup.array().min(1),
    // locations: Yup.array().min(1),
    interests: Yup.array().min(1),
})

type Props = {
    customerPersona?: CustomerPersonaSimpleModel,
    afterSubmitNext: () => void,
    afterSubmitBack: () => void
};

const CustomerPersonaBaseFields = (props: {
    businessId?: string,
    afterSubmitNext: () => void,
    afterSubmitBack: () => void
}) => {


    const { submitForm, setFieldTouched, validateForm } = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['name', 'gender', //'locations',
            'interests'].forEach((field) => {
                setFieldTouched(field, true, true)
            })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }


    return <Box sx={{ maxWidth: '380px', flexDirection: "column", margin: 'auto' }}>
        <OptionField
            key={'gender'}
            multiselect={true}
            name='gender' text={'Gender'}
            options={GENDER}
        />
        <OptionField
            key={'age'} multiselect={true}
            name='age' text={'Age'}
            options={AGE}
        />
        {/*<AutoCompleteSelect*/}
        {/*    key={'locations'}*/}
        {/*    name='locations'*/}
        {/*    text={'Location'}*/}
        {/*    fetchOptions={memorizedLocations}*/}
        {/*    minChars={2}*/}
        {/*    multiple*/}
        {/*/>*/}
        <AutoCompleteSelect
            key={'interests'}
            name='interests'
            text={'Interests'}
            fetchOptions={memorizedInterests}
            minChars={0}
            multiple
        />
        <ButtonHolder
            justify={'right'}
            submitRight={
                () => {
                    handleSubmit()
                        .then((errors) => {
                            if (!errors)
                                props.afterSubmitNext()
                        })
                }}
            textRight="Go to Sites"
        />
    </Box>

}


const OnBoardingCustomerForm = ({ customerPersona, afterSubmitNext, afterSubmitBack }: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    return <Formik
        enableReinitialize={true}
        initialValues={
            customerPersona ? customerPersona : {
                __typename: "TargetCustomer",
                id: '',
                businessId: '',
                createdAt: '',
                updatedAt: '',
                age: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
                gender: ['Female', 'Male'],
                name: 'Default persona',
                locations: [],
                interests: [],
            } as CustomerPersonaSimpleModel
        }
        initialTouched={{}}

        validationSchema={validationSchema}
        onSubmit={async (values: CustomerPersonaSimpleModel) => {
            if (business)
                if (customerPersona?.id) {
                    return dispatch(updateCustomerPersonaAction(
                        business.id,
                        customerPersona.id,
                        values.name as string,
                        values.age,
                        values.interests,
                        values.gender,
                        values.locations))

                } else {
                    return dispatch(createCustomerPersonaAction(
                        business.id,
                        values.name as string,
                        values.age,
                        values.interests,
                        values.gender,
                        values.locations))
                }
        }}>
        <Container sx={{ pb: 4 }}>
            <Form>
                <Stack spacing={1} mb={3}>
                    <Typography variant="h1">Tell us about your customers</Typography>
                    <Typography>Define who they are and what are their needs/problems.</Typography>
                </Stack>
                <CustomerPersonaBaseFields
                    businessId={business?.id}
                    afterSubmitNext={afterSubmitNext}
                    afterSubmitBack={afterSubmitBack} />
            </Form>
        </Container>
    </Formik>
}

export default OnBoardingCustomerForm