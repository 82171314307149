/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCustomerPersonaLocationsInput = {
  id?: string | null,
  customerPersonaID: string,
  geoLocationID: string,
};

export type ModelCustomerPersonaLocationsConditionInput = {
  customerPersonaID?: ModelIDInput | null,
  geoLocationID?: ModelIDInput | null,
  and?: Array< ModelCustomerPersonaLocationsConditionInput | null > | null,
  or?: Array< ModelCustomerPersonaLocationsConditionInput | null > | null,
  not?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type CustomerPersonaLocations = {
  __typename: "CustomerPersonaLocations",
  id: string,
  customerPersonaID: string,
  geoLocationID: string,
  customerPersona: CustomerPersona,
  geoLocation: GeoLocation,
  createdAt: string,
  updatedAt: string,
};

export type CustomerPersona = {
  __typename: "CustomerPersona",
  id: string,
  businessId: string,
  name?: string | null,
  age?: Array< string | null > | null,
  gender?: Array< string | null > | null,
  interests?: ModelCustomerPersonaInterestsConnection | null,
  locations?: ModelCustomerPersonaLocationsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCustomerPersonaInterestsConnection = {
  __typename: "ModelCustomerPersonaInterestsConnection",
  items:  Array<CustomerPersonaInterests | null >,
  nextToken?: string | null,
};

export type CustomerPersonaInterests = {
  __typename: "CustomerPersonaInterests",
  id: string,
  customerPersonaID: string,
  interestID: string,
  customerPersona: CustomerPersona,
  interest: Interest,
  createdAt: string,
  updatedAt: string,
};

export type Interest = {
  __typename: "Interest",
  name: string,
  googleAffinityAudiences?: Array< string | null > | null,
  googleInMarketAudiences?: Array< string | null > | null,
  targetCustomers?: ModelCustomerPersonaInterestsConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelCustomerPersonaLocationsConnection = {
  __typename: "ModelCustomerPersonaLocationsConnection",
  items:  Array<CustomerPersonaLocations | null >,
  nextToken?: string | null,
};

export type GeoLocation = {
  __typename: "GeoLocation",
  name: string,
  targetType: string,
  countryCode: string,
  googleAdsGeoCriteria: string,
  targetCustomers?: ModelCustomerPersonaLocationsConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type DeleteCustomerPersonaLocationsInput = {
  id: string,
};

export type CreateCustomerPersonaInterestsInput = {
  id?: string | null,
  customerPersonaID: string,
  interestID: string,
};

export type ModelCustomerPersonaInterestsConditionInput = {
  customerPersonaID?: ModelIDInput | null,
  interestID?: ModelIDInput | null,
  and?: Array< ModelCustomerPersonaInterestsConditionInput | null > | null,
  or?: Array< ModelCustomerPersonaInterestsConditionInput | null > | null,
  not?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type DeleteCustomerPersonaInterestsInput = {
  id: string,
};

export type CreateSiteInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  live?: SiteDataInput | null,
  lastDeploymentId?: string | null,
};

export type SiteDataInput = {
  netlifySiteId?: string | null,
  netlifyDeploymentId?: string | null,
  url?: string | null,
  meta?: SiteMetaInput | null,
  functions?: SiteFunctionInput | null,
  gtmTag?: string | null,
  styling?: SiteStylingInput | null,
  layout?: SiteLayoutInput | null,
};

export type SiteMetaInput = {
  googleAdsAccount?: string | null,
  googleAdsConversions?: GoogleAdsConversionsInput | null,
};

export type GoogleAdsConversionsInput = {
  leadRn?: string | null,
  leadConversionId?: string | null,
  leadLabel?: string | null,
  phone?: string | null,
  ecommerce?: string | null,
};

export type SiteFunctionInput = {
  actions?: Array< SiteAction | null > | null,
  form?: SiteFormInput | null,
  link?: LinkFormInput | null,
  calendly?: CalendlyFormInput | null,
  phone?: PhoneFormInput | null,
};

export enum SiteAction {
  Leads = "Leads",
}


export type SiteFormInput = {
  disabled?: boolean | null,
  forwardTo?: string | null,
  buttonText?: string | null,
  bottomHeader?: string | null,
  title?: string | null,
  emailHeader?: string | null,
  emailHeaderEnabled?: boolean | null,
  emailHeaderRequired?: boolean | null,
  formHeader?: string | null,
  formHeaderEnabled?: boolean | null,
  formHeaderRequired?: boolean | null,
  firstNameLabel?: string | null,
  firstNameEnabled?: boolean | null,
  firstNameRequired?: boolean | null,
  lastNameLabel?: string | null,
  lastNameEnabled?: boolean | null,
  lastNameRequired?: boolean | null,
  locationLabel?: string | null,
  locationEnabled?: boolean | null,
  locationRequired?: boolean | null,
  phoneLabel?: string | null,
  phoneEnabled?: boolean | null,
  phoneRequired?: boolean | null,
  dateLabel?: string | null,
  dateEnabled?: boolean | null,
  dateRequired?: boolean | null,
  options?: string | null,
  optionsLabel?: string | null,
  optionsEnabled?: boolean | null,
  optionsRequired?: boolean | null,
  multiOptions?: boolean | null,
  successMessage?: string | null,
  redirectUrl?: string | null,
};

export type LinkFormInput = {
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type CalendlyFormInput = {
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type PhoneFormInput = {
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type SiteStylingInput = {
  primaryText?: string | null,
  buttons?: string | null,
  header?: string | null,
  footerBackground?: string | null,
  footerText?: string | null,
  background?: string | null,
  tilesBackground?: string | null,
};

export type SiteLayoutInput = {
  siteTitle?: string | null,
  sectionTitles?: boolean | null,
  headerType?: string | null,
  contentAlign?: string | null,
  buttonShape?: string | null,
  density?: string | null,
  font?: string | null,
};

export type ModelSiteConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  lastDeploymentId?: ModelIDInput | null,
  and?: Array< ModelSiteConditionInput | null > | null,
  or?: Array< ModelSiteConditionInput | null > | null,
  not?: ModelSiteConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Site = {
  __typename: "Site",
  id: string,
  businessId: string,
  name?: string | null,
  live?: SiteData | null,
  lastDeploymentId?: string | null,
  lastDeployment?: SiteDeployment | null,
  createdAt: string,
  updatedAt: string,
};

export type SiteData = {
  __typename: "SiteData",
  netlifySiteId?: string | null,
  netlifyDeploymentId?: string | null,
  url?: string | null,
  meta?: SiteMeta | null,
  functions?: SiteFunction | null,
  gtmTag?: string | null,
  styling?: SiteStyling | null,
  layout?: SiteLayout | null,
};

export type SiteMeta = {
  __typename: "SiteMeta",
  googleAdsAccount?: string | null,
  googleAdsConversions?: GoogleAdsConversions | null,
};

export type GoogleAdsConversions = {
  __typename: "GoogleAdsConversions",
  leadRn?: string | null,
  leadConversionId?: string | null,
  leadLabel?: string | null,
  phone?: string | null,
  ecommerce?: string | null,
};

export type SiteFunction = {
  __typename: "SiteFunction",
  actions?: Array< SiteAction | null > | null,
  form?: SiteForm | null,
  link?: LinkForm | null,
  calendly?: CalendlyForm | null,
  phone?: PhoneForm | null,
};

export type SiteForm = {
  __typename: "SiteForm",
  disabled?: boolean | null,
  forwardTo?: string | null,
  buttonText?: string | null,
  bottomHeader?: string | null,
  title?: string | null,
  emailHeader?: string | null,
  emailHeaderEnabled?: boolean | null,
  emailHeaderRequired?: boolean | null,
  formHeader?: string | null,
  formHeaderEnabled?: boolean | null,
  formHeaderRequired?: boolean | null,
  firstNameLabel?: string | null,
  firstNameEnabled?: boolean | null,
  firstNameRequired?: boolean | null,
  lastNameLabel?: string | null,
  lastNameEnabled?: boolean | null,
  lastNameRequired?: boolean | null,
  locationLabel?: string | null,
  locationEnabled?: boolean | null,
  locationRequired?: boolean | null,
  phoneLabel?: string | null,
  phoneEnabled?: boolean | null,
  phoneRequired?: boolean | null,
  dateLabel?: string | null,
  dateEnabled?: boolean | null,
  dateRequired?: boolean | null,
  options?: string | null,
  optionsLabel?: string | null,
  optionsEnabled?: boolean | null,
  optionsRequired?: boolean | null,
  multiOptions?: boolean | null,
  successMessage?: string | null,
  redirectUrl?: string | null,
};

export type LinkForm = {
  __typename: "LinkForm",
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type CalendlyForm = {
  __typename: "CalendlyForm",
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type PhoneForm = {
  __typename: "PhoneForm",
  enabled?: boolean | null,
  redirectTo: string,
  buttonText: string,
};

export type SiteStyling = {
  __typename: "SiteStyling",
  primaryText?: string | null,
  buttons?: string | null,
  header?: string | null,
  footerBackground?: string | null,
  footerText?: string | null,
  background?: string | null,
  tilesBackground?: string | null,
};

export type SiteLayout = {
  __typename: "SiteLayout",
  siteTitle?: string | null,
  sectionTitles?: boolean | null,
  headerType?: string | null,
  contentAlign?: string | null,
  buttonShape?: string | null,
  density?: string | null,
  font?: string | null,
};

export type SiteDeployment = {
  __typename: "SiteDeployment",
  id: string,
  siteId: string,
  deploymentStatus: DeploymentStatus,
  createdAt: string,
  updatedAt: string,
};

export enum DeploymentStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  VERIFYING = "VERIFYING",
  WAITING_FOR_HTTPS = "WAITING_FOR_HTTPS",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
}


export type UpdateSiteInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  live?: SiteDataInput | null,
  lastDeploymentId?: string | null,
};

export type ModelCustomerPersonaFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  age?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  and?: Array< ModelCustomerPersonaFilterInput | null > | null,
  or?: Array< ModelCustomerPersonaFilterInput | null > | null,
  not?: ModelCustomerPersonaFilterInput | null,
};

export type ModelCustomerPersonaConnection = {
  __typename: "ModelCustomerPersonaConnection",
  items:  Array<CustomerPersona | null >,
  nextToken?: string | null,
};

export type ModelSiteFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  lastDeploymentId?: ModelIDInput | null,
  and?: Array< ModelSiteFilterInput | null > | null,
  or?: Array< ModelSiteFilterInput | null > | null,
  not?: ModelSiteFilterInput | null,
};

export type ModelSiteConnection = {
  __typename: "ModelSiteConnection",
  items:  Array<Site | null >,
  nextToken?: string | null,
};

export type ModelMarketingCampaignFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  objective?: ModelMarketingCampaignObjectiveInput | null,
  provider?: ModelMarketingCampaignProviderInput | null,
  and?: Array< ModelMarketingCampaignFilterInput | null > | null,
  or?: Array< ModelMarketingCampaignFilterInput | null > | null,
  not?: ModelMarketingCampaignFilterInput | null,
  marketingCampaignCustomerPersonaId?: ModelIDInput | null,
  marketingCampaignSiteId?: ModelIDInput | null,
};

export type ModelMarketingCampaignObjectiveInput = {
  eq?: MarketingCampaignObjective | null,
  ne?: MarketingCampaignObjective | null,
};

export enum MarketingCampaignObjective {
  LEADS = "LEADS",
}


export type ModelMarketingCampaignProviderInput = {
  eq?: MarketingCampaignProvider | null,
  ne?: MarketingCampaignProvider | null,
};

export enum MarketingCampaignProvider {
  GOOGLE_ADS = "GOOGLE_ADS",
}


export type ModelMarketingCampaignConnection = {
  __typename: "ModelMarketingCampaignConnection",
  items:  Array<MarketingCampaign | null >,
  nextToken?: string | null,
};

export type MarketingCampaign = {
  __typename: "MarketingCampaign",
  id: string,
  businessId: string,
  customerPersona: CustomerPersona,
  site: Site,
  objective: MarketingCampaignObjective,
  provider: MarketingCampaignProvider,
  runs?: ModelMarketingCampaignRunConnection | null,
  createdAt: string,
  updatedAt: string,
  marketingCampaignCustomerPersonaId: string,
  marketingCampaignSiteId: string,
};

export type ModelMarketingCampaignRunConnection = {
  __typename: "ModelMarketingCampaignRunConnection",
  items:  Array<MarketingCampaignRun | null >,
  nextToken?: string | null,
};

export type MarketingCampaignRun = {
  __typename: "MarketingCampaignRun",
  id: string,
  marketingCampaignId: string,
  startDate: string,
  endDate?: string | null,
  dailyBudget: number,
  createdAt: string,
  updatedAt: string,
};

export type CreateBusinessInput = {
  id?: string | null,
  name?: string | null,
  onboardingStatus: OnboardingStatus,
  tutorial?: TutorialInput | null,
  editors?: Array< string | null > | null,
};

export enum OnboardingStatus {
  Welcome = "Welcome",
  Brand = "Brand",
  Customers = "Customers",
  Site = "Site",
  Campaign = "Campaign",
  Done = "Done",
}


export type TutorialInput = {
  closed?: boolean | null,
  productsOrSolutions?: string | null,
  steps?: string | null,
  testimonial?: string | null,
  domain?: string | null,
  siteTest?: string | null,
  campaign?: string | null,
};

export type ModelBusinessConditionInput = {
  name?: ModelStringInput | null,
  onboardingStatus?: ModelOnboardingStatusInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelBusinessConditionInput | null > | null,
  or?: Array< ModelBusinessConditionInput | null > | null,
  not?: ModelBusinessConditionInput | null,
};

export type ModelOnboardingStatusInput = {
  eq?: OnboardingStatus | null,
  ne?: OnboardingStatus | null,
};

export type Business = {
  __typename: "Business",
  id: string,
  name?: string | null,
  onboardingStatus: OnboardingStatus,
  brands?: ModelBrandConnection | null,
  customerPersonas?: ModelCustomerPersonaConnection | null,
  sites?: ModelSiteConnection | null,
  products?: ModelProductConnection | null,
  solutions?: ModelBusinessSolutionConnection | null,
  faqs?: ModelBusinessFaqConnection | null,
  features?: ModelBusinessFeatureConnection | null,
  partners?: ModelBusinessPartnerConnection | null,
  businessSteps?: ModelBusinessStepConnection | null,
  testimonials?: ModelBusinessTestimonialConnection | null,
  tutorial?: Tutorial | null,
  editors?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
};

export type Brand = {
  __typename: "Brand",
  id: string,
  businessId: string,
  name?: string | null,
  briefDescription?: string | null,
  longDescription?: string | null,
  keywords?: Array< string | null > | null,
  logo?: string | null,
  icon?: string | null,
  coverImage?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  businessId: string,
  name?: string | null,
  price?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessSolutionConnection = {
  __typename: "ModelBusinessSolutionConnection",
  items:  Array<BusinessSolution | null >,
  nextToken?: string | null,
};

export type BusinessSolution = {
  __typename: "BusinessSolution",
  id: string,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessFaqConnection = {
  __typename: "ModelBusinessFaqConnection",
  items:  Array<BusinessFaq | null >,
  nextToken?: string | null,
};

export type BusinessFaq = {
  __typename: "BusinessFaq",
  id: string,
  businessId: string,
  name?: string | null,
  description?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessFeatureConnection = {
  __typename: "ModelBusinessFeatureConnection",
  items:  Array<BusinessFeature | null >,
  nextToken?: string | null,
};

export type BusinessFeature = {
  __typename: "BusinessFeature",
  id: string,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessPartnerConnection = {
  __typename: "ModelBusinessPartnerConnection",
  items:  Array<BusinessPartner | null >,
  nextToken?: string | null,
};

export type BusinessPartner = {
  __typename: "BusinessPartner",
  id: string,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessStepConnection = {
  __typename: "ModelBusinessStepConnection",
  items:  Array<BusinessStep | null >,
  nextToken?: string | null,
};

export type BusinessStep = {
  __typename: "BusinessStep",
  id: string,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessTestimonialConnection = {
  __typename: "ModelBusinessTestimonialConnection",
  items:  Array<BusinessTestimonial | null >,
  nextToken?: string | null,
};

export type BusinessTestimonial = {
  __typename: "BusinessTestimonial",
  id: string,
  businessId: string,
  who?: string | null,
  testimonial?: string | null,
  avatar?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type Tutorial = {
  __typename: "Tutorial",
  closed?: boolean | null,
  productsOrSolutions?: string | null,
  steps?: string | null,
  testimonial?: string | null,
  domain?: string | null,
  siteTest?: string | null,
  campaign?: string | null,
};

export type UpdateBusinessInput = {
  id: string,
  name?: string | null,
  onboardingStatus?: OnboardingStatus | null,
  tutorial?: TutorialInput | null,
  editors?: Array< string | null > | null,
};

export type DeleteBusinessInput = {
  id: string,
};

export type CreateBrandInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  briefDescription?: string | null,
  longDescription?: string | null,
  keywords?: Array< string | null > | null,
  logo?: string | null,
  icon?: string | null,
  coverImage?: string | null,
};

export type ModelBrandConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  briefDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type UpdateBrandInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  briefDescription?: string | null,
  longDescription?: string | null,
  keywords?: Array< string | null > | null,
  logo?: string | null,
  icon?: string | null,
  coverImage?: string | null,
};

export type DeleteBrandInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  price?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type ModelProductConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateProductInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  price?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateBusinessSolutionInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type ModelBusinessSolutionConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessSolutionConditionInput | null > | null,
  or?: Array< ModelBusinessSolutionConditionInput | null > | null,
  not?: ModelBusinessSolutionConditionInput | null,
};

export type UpdateBusinessSolutionInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type DeleteBusinessSolutionInput = {
  id: string,
};

export type CreateBusinessFaqInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  description?: string | null,
  order?: number | null,
};

export type ModelBusinessFaqConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessFaqConditionInput | null > | null,
  or?: Array< ModelBusinessFaqConditionInput | null > | null,
  not?: ModelBusinessFaqConditionInput | null,
};

export type UpdateBusinessFaqInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  description?: string | null,
  order?: number | null,
};

export type DeleteBusinessFaqInput = {
  id: string,
};

export type CreateBusinessFeatureInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type ModelBusinessFeatureConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessFeatureConditionInput | null > | null,
  or?: Array< ModelBusinessFeatureConditionInput | null > | null,
  not?: ModelBusinessFeatureConditionInput | null,
};

export type UpdateBusinessFeatureInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type DeleteBusinessFeatureInput = {
  id: string,
};

export type CreateBusinessPartnerInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type ModelBusinessPartnerConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessPartnerConditionInput | null > | null,
  or?: Array< ModelBusinessPartnerConditionInput | null > | null,
  not?: ModelBusinessPartnerConditionInput | null,
};

export type UpdateBusinessPartnerInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type DeleteBusinessPartnerInput = {
  id: string,
};

export type CreateBusinessStepInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type ModelBusinessStepConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessStepConditionInput | null > | null,
  or?: Array< ModelBusinessStepConditionInput | null > | null,
  not?: ModelBusinessStepConditionInput | null,
};

export type UpdateBusinessStepInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  description?: string | null,
  coverImage?: string | null,
  order?: number | null,
};

export type DeleteBusinessStepInput = {
  id: string,
};

export type CreateBusinessTestimonialInput = {
  id?: string | null,
  businessId: string,
  who?: string | null,
  testimonial?: string | null,
  avatar?: string | null,
  order?: number | null,
};

export type ModelBusinessTestimonialConditionInput = {
  businessId?: ModelIDInput | null,
  who?: ModelStringInput | null,
  testimonial?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessTestimonialConditionInput | null > | null,
  or?: Array< ModelBusinessTestimonialConditionInput | null > | null,
  not?: ModelBusinessTestimonialConditionInput | null,
};

export type UpdateBusinessTestimonialInput = {
  id: string,
  businessId?: string | null,
  who?: string | null,
  testimonial?: string | null,
  avatar?: string | null,
  order?: number | null,
};

export type DeleteBusinessTestimonialInput = {
  id: string,
};

export type DeleteSiteInput = {
  id: string,
};

export type CreateSiteDeploymentInput = {
  id?: string | null,
  siteId: string,
  deploymentStatus: DeploymentStatus,
};

export type ModelSiteDeploymentConditionInput = {
  siteId?: ModelIDInput | null,
  deploymentStatus?: ModelDeploymentStatusInput | null,
  and?: Array< ModelSiteDeploymentConditionInput | null > | null,
  or?: Array< ModelSiteDeploymentConditionInput | null > | null,
  not?: ModelSiteDeploymentConditionInput | null,
};

export type ModelDeploymentStatusInput = {
  eq?: DeploymentStatus | null,
  ne?: DeploymentStatus | null,
};

export type UpdateSiteDeploymentInput = {
  id: string,
  siteId?: string | null,
  deploymentStatus?: DeploymentStatus | null,
};

export type DeleteSiteDeploymentInput = {
  id: string,
};

export type CreateCustomerPersonaInput = {
  id?: string | null,
  businessId: string,
  name?: string | null,
  age?: Array< string | null > | null,
  gender?: Array< string | null > | null,
};

export type ModelCustomerPersonaConditionInput = {
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  age?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  and?: Array< ModelCustomerPersonaConditionInput | null > | null,
  or?: Array< ModelCustomerPersonaConditionInput | null > | null,
  not?: ModelCustomerPersonaConditionInput | null,
};

export type UpdateCustomerPersonaInput = {
  id: string,
  businessId?: string | null,
  name?: string | null,
  age?: Array< string | null > | null,
  gender?: Array< string | null > | null,
};

export type DeleteCustomerPersonaInput = {
  id: string,
};

export type CreateLeadInput = {
  id?: string | null,
  siteId: string,
  forwardTo?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phone?: string | null,
  location?: string | null,
  fromEmail?: string | null,
  form?: string | null,
};

export type ModelLeadConditionInput = {
  siteId?: ModelIDInput | null,
  forwardTo?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  location?: ModelStringInput | null,
  fromEmail?: ModelStringInput | null,
  form?: ModelStringInput | null,
  and?: Array< ModelLeadConditionInput | null > | null,
  or?: Array< ModelLeadConditionInput | null > | null,
  not?: ModelLeadConditionInput | null,
};

export type Lead = {
  __typename: "Lead",
  id: string,
  siteId: string,
  forwardTo?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phone?: string | null,
  location?: string | null,
  fromEmail?: string | null,
  form?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLeadInput = {
  id: string,
  siteId?: string | null,
  forwardTo?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phone?: string | null,
  location?: string | null,
  fromEmail?: string | null,
  form?: string | null,
};

export type DeleteLeadInput = {
  id: string,
};

export type CreateClosedInfoInput = {
  id?: string | null,
  code: string,
  closed?: string | null,
};

export type ModelClosedInfoConditionInput = {
  code?: ModelStringInput | null,
  closed?: ModelStringInput | null,
  and?: Array< ModelClosedInfoConditionInput | null > | null,
  or?: Array< ModelClosedInfoConditionInput | null > | null,
  not?: ModelClosedInfoConditionInput | null,
};

export type ClosedInfo = {
  __typename: "ClosedInfo",
  id: string,
  code: string,
  closed?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateClosedInfoInput = {
  id: string,
  code?: string | null,
  closed?: string | null,
};

export type DeleteClosedInfoInput = {
  id: string,
};

export type CreateMarketingCampaignInput = {
  id?: string | null,
  businessId: string,
  objective: MarketingCampaignObjective,
  provider: MarketingCampaignProvider,
  marketingCampaignCustomerPersonaId: string,
  marketingCampaignSiteId: string,
};

export type ModelMarketingCampaignConditionInput = {
  businessId?: ModelIDInput | null,
  objective?: ModelMarketingCampaignObjectiveInput | null,
  provider?: ModelMarketingCampaignProviderInput | null,
  and?: Array< ModelMarketingCampaignConditionInput | null > | null,
  or?: Array< ModelMarketingCampaignConditionInput | null > | null,
  not?: ModelMarketingCampaignConditionInput | null,
  marketingCampaignCustomerPersonaId?: ModelIDInput | null,
  marketingCampaignSiteId?: ModelIDInput | null,
};

export type UpdateMarketingCampaignInput = {
  id: string,
  businessId?: string | null,
  objective?: MarketingCampaignObjective | null,
  provider?: MarketingCampaignProvider | null,
  marketingCampaignCustomerPersonaId: string,
  marketingCampaignSiteId: string,
};

export type DeleteMarketingCampaignInput = {
  id: string,
};

export type CreateMarketingCampaignRunInput = {
  id?: string | null,
  marketingCampaignId: string,
  startDate: string,
  endDate?: string | null,
  dailyBudget: number,
};

export type ModelMarketingCampaignRunConditionInput = {
  marketingCampaignId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  dailyBudget?: ModelIntInput | null,
  and?: Array< ModelMarketingCampaignRunConditionInput | null > | null,
  or?: Array< ModelMarketingCampaignRunConditionInput | null > | null,
  not?: ModelMarketingCampaignRunConditionInput | null,
};

export type UpdateMarketingCampaignRunInput = {
  id: string,
  marketingCampaignId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  dailyBudget?: number | null,
};

export type DeleteMarketingCampaignRunInput = {
  id: string,
};

export type CreateBalanceEntryInput = {
  id?: string | null,
  origin: string,
  entryType: BalanceEntryType,
  businessId: string,
  currency: string,
  amount: number,
  sign: number,
};

export enum BalanceEntryType {
  MONEY_IN = "MONEY_IN",
  MONEY_OUT = "MONEY_OUT",
  LOCK = "LOCK",
  RELEASE = "RELEASE",
}


export type ModelBalanceEntryConditionInput = {
  origin?: ModelStringInput | null,
  entryType?: ModelBalanceEntryTypeInput | null,
  businessId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  sign?: ModelIntInput | null,
  and?: Array< ModelBalanceEntryConditionInput | null > | null,
  or?: Array< ModelBalanceEntryConditionInput | null > | null,
  not?: ModelBalanceEntryConditionInput | null,
};

export type ModelBalanceEntryTypeInput = {
  eq?: BalanceEntryType | null,
  ne?: BalanceEntryType | null,
};

export type BalanceEntry = {
  __typename: "BalanceEntry",
  id: string,
  origin: string,
  entryType: BalanceEntryType,
  businessId: string,
  currency: string,
  amount: number,
  sign: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBalanceEntryInput = {
  id: string,
  origin?: string | null,
  entryType?: BalanceEntryType | null,
  businessId?: string | null,
  currency?: string | null,
  amount?: number | null,
  sign?: number | null,
};

export type DeleteBalanceEntryInput = {
  id: string,
};

export type UpdateCustomerPersonaInterestsInput = {
  id: string,
  customerPersonaID?: string | null,
  interestID?: string | null,
};

export type UpdateCustomerPersonaLocationsInput = {
  id: string,
  customerPersonaID?: string | null,
  geoLocationID?: string | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  onboardingStatus?: ModelOnboardingStatusInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelBusinessFilterInput | null > | null,
  or?: Array< ModelBusinessFilterInput | null > | null,
  not?: ModelBusinessFilterInput | null,
};

export type ModelBusinessConnection = {
  __typename: "ModelBusinessConnection",
  items:  Array<Business | null >,
  nextToken?: string | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  briefDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelBusinessSolutionFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessSolutionFilterInput | null > | null,
  or?: Array< ModelBusinessSolutionFilterInput | null > | null,
  not?: ModelBusinessSolutionFilterInput | null,
};

export type ModelBusinessFaqFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessFaqFilterInput | null > | null,
  or?: Array< ModelBusinessFaqFilterInput | null > | null,
  not?: ModelBusinessFaqFilterInput | null,
};

export type ModelBusinessFeatureFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessFeatureFilterInput | null > | null,
  or?: Array< ModelBusinessFeatureFilterInput | null > | null,
  not?: ModelBusinessFeatureFilterInput | null,
};

export type ModelBusinessPartnerFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessPartnerFilterInput | null > | null,
  or?: Array< ModelBusinessPartnerFilterInput | null > | null,
  not?: ModelBusinessPartnerFilterInput | null,
};

export type ModelBusinessStepFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessStepFilterInput | null > | null,
  or?: Array< ModelBusinessStepFilterInput | null > | null,
  not?: ModelBusinessStepFilterInput | null,
};

export type ModelBusinessTestimonialFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  who?: ModelStringInput | null,
  testimonial?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBusinessTestimonialFilterInput | null > | null,
  or?: Array< ModelBusinessTestimonialFilterInput | null > | null,
  not?: ModelBusinessTestimonialFilterInput | null,
};

export type ModelSiteDeploymentFilterInput = {
  id?: ModelIDInput | null,
  siteId?: ModelIDInput | null,
  deploymentStatus?: ModelDeploymentStatusInput | null,
  and?: Array< ModelSiteDeploymentFilterInput | null > | null,
  or?: Array< ModelSiteDeploymentFilterInput | null > | null,
  not?: ModelSiteDeploymentFilterInput | null,
};

export type ModelSiteDeploymentConnection = {
  __typename: "ModelSiteDeploymentConnection",
  items:  Array<SiteDeployment | null >,
  nextToken?: string | null,
};

export type ModelInterestFilterInput = {
  name?: ModelStringInput | null,
  googleAffinityAudiences?: ModelStringInput | null,
  googleInMarketAudiences?: ModelStringInput | null,
  and?: Array< ModelInterestFilterInput | null > | null,
  or?: Array< ModelInterestFilterInput | null > | null,
  not?: ModelInterestFilterInput | null,
};

export type ModelInterestConnection = {
  __typename: "ModelInterestConnection",
  items:  Array<Interest | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGeoLocationFilterInput = {
  name?: ModelStringInput | null,
  targetType?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  googleAdsGeoCriteria?: ModelStringInput | null,
  and?: Array< ModelGeoLocationFilterInput | null > | null,
  or?: Array< ModelGeoLocationFilterInput | null > | null,
  not?: ModelGeoLocationFilterInput | null,
};

export type ModelGeoLocationConnection = {
  __typename: "ModelGeoLocationConnection",
  items:  Array<GeoLocation | null >,
  nextToken?: string | null,
};

export type ModelLeadFilterInput = {
  id?: ModelIDInput | null,
  siteId?: ModelIDInput | null,
  forwardTo?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  location?: ModelStringInput | null,
  fromEmail?: ModelStringInput | null,
  form?: ModelStringInput | null,
  and?: Array< ModelLeadFilterInput | null > | null,
  or?: Array< ModelLeadFilterInput | null > | null,
  not?: ModelLeadFilterInput | null,
};

export type ModelLeadConnection = {
  __typename: "ModelLeadConnection",
  items:  Array<Lead | null >,
  nextToken?: string | null,
};

export type ModelClosedInfoFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  closed?: ModelStringInput | null,
  and?: Array< ModelClosedInfoFilterInput | null > | null,
  or?: Array< ModelClosedInfoFilterInput | null > | null,
  not?: ModelClosedInfoFilterInput | null,
};

export type ModelClosedInfoConnection = {
  __typename: "ModelClosedInfoConnection",
  items:  Array<ClosedInfo | null >,
  nextToken?: string | null,
};

export type ModelMarketingCampaignRunFilterInput = {
  id?: ModelIDInput | null,
  marketingCampaignId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  dailyBudget?: ModelIntInput | null,
  and?: Array< ModelMarketingCampaignRunFilterInput | null > | null,
  or?: Array< ModelMarketingCampaignRunFilterInput | null > | null,
  not?: ModelMarketingCampaignRunFilterInput | null,
};

export type ModelBalanceEntryFilterInput = {
  id?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  entryType?: ModelBalanceEntryTypeInput | null,
  businessId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  sign?: ModelIntInput | null,
  and?: Array< ModelBalanceEntryFilterInput | null > | null,
  or?: Array< ModelBalanceEntryFilterInput | null > | null,
  not?: ModelBalanceEntryFilterInput | null,
};

export type ModelBalanceEntryConnection = {
  __typename: "ModelBalanceEntryConnection",
  items:  Array<BalanceEntry | null >,
  nextToken?: string | null,
};

export type ModelCustomerPersonaInterestsFilterInput = {
  id?: ModelIDInput | null,
  customerPersonaID?: ModelIDInput | null,
  interestID?: ModelIDInput | null,
  and?: Array< ModelCustomerPersonaInterestsFilterInput | null > | null,
  or?: Array< ModelCustomerPersonaInterestsFilterInput | null > | null,
  not?: ModelCustomerPersonaInterestsFilterInput | null,
};

export type ModelCustomerPersonaLocationsFilterInput = {
  id?: ModelIDInput | null,
  customerPersonaID?: ModelIDInput | null,
  geoLocationID?: ModelIDInput | null,
  and?: Array< ModelCustomerPersonaLocationsFilterInput | null > | null,
  or?: Array< ModelCustomerPersonaLocationsFilterInput | null > | null,
  not?: ModelCustomerPersonaLocationsFilterInput | null,
};

export type CreateCustomerPersonaLocationCustomMutationVariables = {
  input: CreateCustomerPersonaLocationsInput,
  condition?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type CreateCustomerPersonaLocationCustomMutation = {
  createCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
  } | null,
};

export type DeleteCustomerPersonaLocationsCustomMutationVariables = {
  input: DeleteCustomerPersonaLocationsInput,
  condition?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type DeleteCustomerPersonaLocationsCustomMutation = {
  deleteCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
  } | null,
};

export type CreateCustomerPersonaInterestsCustomMutationVariables = {
  input: CreateCustomerPersonaInterestsInput,
  condition?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type CreateCustomerPersonaInterestsCustomMutation = {
  createCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
  } | null,
};

export type DeleteCustomerPersonaInterestsCustomMutationVariables = {
  input: DeleteCustomerPersonaInterestsInput,
  condition?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type DeleteCustomerPersonaInterestsCustomMutation = {
  deleteCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
  } | null,
};

export type CreateSiteCustomMutationVariables = {
  input: CreateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type CreateSiteCustomMutation = {
  createSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      gtmTag?: string | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
        form?:  {
          __typename: "SiteForm",
          disabled?: boolean | null,
          buttonText?: string | null,
          bottomHeader?: string | null,
          forwardTo?: string | null,
          title?: string | null,
          emailHeader?: string | null,
          emailHeaderEnabled?: boolean | null,
          emailHeaderRequired?: boolean | null,
          formHeader?: string | null,
          formHeaderEnabled?: boolean | null,
          formHeaderRequired?: boolean | null,
          firstNameLabel?: string | null,
          firstNameEnabled?: boolean | null,
          firstNameRequired?: boolean | null,
          lastNameLabel?: string | null,
          lastNameEnabled?: boolean | null,
          lastNameRequired?: boolean | null,
          locationLabel?: string | null,
          locationEnabled?: boolean | null,
          locationRequired?: boolean | null,
          phoneLabel?: string | null,
          phoneEnabled?: boolean | null,
          phoneRequired?: boolean | null,
          dateLabel?: string | null,
          dateEnabled?: boolean | null,
          dateRequired?: boolean | null,
          options?: string | null,
          optionsLabel?: string | null,
          optionsEnabled?: boolean | null,
          optionsRequired?: boolean | null,
          multiOptions?: boolean | null,
          successMessage?: string | null,
          redirectUrl?: string | null,
        } | null,
        link?:  {
          __typename: "LinkForm",
          enabled?: boolean | null,
          redirectTo: string,
          buttonText: string,
        } | null,
        calendly?:  {
          __typename: "CalendlyForm",
          enabled?: boolean | null,
          redirectTo: string,
          buttonText: string,
        } | null,
        phone?:  {
          __typename: "PhoneForm",
          enabled?: boolean | null,
          buttonText: string,
          redirectTo: string,
        } | null,
      } | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
  } | null,
};

export type UpdateSiteCustomMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSiteCustomMutation = {
  updateSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
        form?:  {
          __typename: "SiteForm",
          disabled?: boolean | null,
          buttonText?: string | null,
          bottomHeader?: string | null,
          forwardTo?: string | null,
          title?: string | null,
          emailHeader?: string | null,
          emailHeaderEnabled?: boolean | null,
          emailHeaderRequired?: boolean | null,
          formHeader?: string | null,
          formHeaderEnabled?: boolean | null,
          formHeaderRequired?: boolean | null,
          firstNameLabel?: string | null,
          firstNameEnabled?: boolean | null,
          firstNameRequired?: boolean | null,
          lastNameLabel?: string | null,
          lastNameEnabled?: boolean | null,
          lastNameRequired?: boolean | null,
          locationLabel?: string | null,
          locationEnabled?: boolean | null,
          locationRequired?: boolean | null,
          phoneLabel?: string | null,
          phoneEnabled?: boolean | null,
          phoneRequired?: boolean | null,
          dateLabel?: string | null,
          dateEnabled?: boolean | null,
          dateRequired?: boolean | null,
          options?: string | null,
          optionsLabel?: string | null,
          optionsEnabled?: boolean | null,
          optionsRequired?: boolean | null,
          multiOptions?: boolean | null,
          successMessage?: string | null,
          redirectUrl?: string | null,
        } | null,
        link?:  {
          __typename: "LinkForm",
          buttonText: string,
          redirectTo: string,
        } | null,
        calendly?:  {
          __typename: "CalendlyForm",
          redirectTo: string,
          buttonText: string,
        } | null,
        phone?:  {
          __typename: "PhoneForm",
          redirectTo: string,
          buttonText: string,
        } | null,
      } | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSitLastDeploymentMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSitLastDeploymentMutation = {
  updateSite?:  {
    __typename: "Site",
    id: string,
    lastDeploymentId?: string | null,
  } | null,
};

export type ListCustomerPersonasEagerQueryVariables = {
  filter?: ModelCustomerPersonaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerPersonasEagerQuery = {
  listCustomerPersonas?:  {
    __typename: "ModelCustomerPersonaConnection",
    items:  Array< {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        items:  Array< {
          __typename: "CustomerPersonaInterests",
          id: string,
          interest:  {
            __typename: "Interest",
            id: string,
            name: string,
            googleAffinityAudiences?: Array< string | null > | null,
            googleInMarketAudiences?: Array< string | null > | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        items:  Array< {
          __typename: "CustomerPersonaLocations",
          id: string,
          geoLocation:  {
            __typename: "GeoLocation",
            id: string,
            name: string,
            countryCode: string,
            targetType: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSitesEagerQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSitesEagerQuery = {
  listSites?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
        meta?:  {
          __typename: "SiteMeta",
          googleAdsAccount?: string | null,
          googleAdsConversions?:  {
            __typename: "GoogleAdsConversions",
            ecommerce?: string | null,
            leadRn?: string | null,
            leadConversionId?: string | null,
            leadLabel?: string | null,
            phone?: string | null,
          } | null,
        } | null,
        functions?:  {
          __typename: "SiteFunction",
          actions?: Array< SiteAction | null > | null,
          form?:  {
            __typename: "SiteForm",
            disabled?: boolean | null,
            buttonText?: string | null,
            bottomHeader?: string | null,
            forwardTo?: string | null,
            title?: string | null,
            emailHeader?: string | null,
            emailHeaderEnabled?: boolean | null,
            emailHeaderRequired?: boolean | null,
            formHeader?: string | null,
            formHeaderEnabled?: boolean | null,
            formHeaderRequired?: boolean | null,
            firstNameLabel?: string | null,
            firstNameEnabled?: boolean | null,
            firstNameRequired?: boolean | null,
            lastNameLabel?: string | null,
            lastNameEnabled?: boolean | null,
            lastNameRequired?: boolean | null,
            locationLabel?: string | null,
            locationEnabled?: boolean | null,
            locationRequired?: boolean | null,
            phoneLabel?: string | null,
            phoneEnabled?: boolean | null,
            phoneRequired?: boolean | null,
            dateLabel?: string | null,
            dateEnabled?: boolean | null,
            dateRequired?: boolean | null,
            options?: string | null,
            optionsLabel?: string | null,
            optionsEnabled?: boolean | null,
            optionsRequired?: boolean | null,
            multiOptions?: boolean | null,
            successMessage?: string | null,
            redirectUrl?: string | null,
          } | null,
          link?:  {
            __typename: "LinkForm",
            enabled?: boolean | null,
            buttonText: string,
            redirectTo: string,
          } | null,
          calendly?:  {
            __typename: "CalendlyForm",
            enabled?: boolean | null,
            buttonText: string,
            redirectTo: string,
          } | null,
          phone?:  {
            __typename: "PhoneForm",
            enabled?: boolean | null,
            buttonText: string,
            redirectTo: string,
          } | null,
        } | null,
        styling?:  {
          __typename: "SiteStyling",
          primaryText?: string | null,
          buttons?: string | null,
          header?: string | null,
          footerBackground?: string | null,
          footerText?: string | null,
          background?: string | null,
          tilesBackground?: string | null,
        } | null,
        layout?:  {
          __typename: "SiteLayout",
          siteTitle?: string | null,
          sectionTitles?: boolean | null,
          headerType?: string | null,
          contentAlign?: string | null,
          buttonShape?: string | null,
          density?: string | null,
        } | null,
      } | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMarketingCampaignsEagerQueryVariables = {
  filter?: ModelMarketingCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketingCampaignsEagerQuery = {
  listMarketingCampaigns?:  {
    __typename: "ModelMarketingCampaignConnection",
    items:  Array< {
      __typename: "MarketingCampaign",
      id: string,
      businessId: string,
      customerPersona:  {
        __typename: "CustomerPersona",
        name?: string | null,
      },
      site:  {
        __typename: "Site",
        id: string,
        name?: string | null,
        live?:  {
          __typename: "SiteData",
          url?: string | null,
        } | null,
      },
      objective: MarketingCampaignObjective,
      provider: MarketingCampaignProvider,
      runs?:  {
        __typename: "ModelMarketingCampaignRunConnection",
        items:  Array< {
          __typename: "MarketingCampaignRun",
          id: string,
          startDate: string,
          endDate?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      marketingCampaignCustomerPersonaId: string,
      marketingCampaignSiteId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateSideDeploymentByIdCustomSubscriptionVariables = {
  id: string,
};

export type OnUpdateSideDeploymentByIdCustomSubscription = {
  onUpdateSideDeploymentById?:  {
    __typename: "SiteDeployment",
    id: string,
    deploymentStatus: DeploymentStatus,
  } | null,
};

export type CreateBusinessMutationVariables = {
  input: CreateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type CreateBusinessMutation = {
  createBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBusinessMutationVariables = {
  input: UpdateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessMutation = {
  updateBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  input: DeleteBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type DeleteBusinessMutation = {
  deleteBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessSolutionMutationVariables = {
  input: CreateBusinessSolutionInput,
  condition?: ModelBusinessSolutionConditionInput | null,
};

export type CreateBusinessSolutionMutation = {
  createBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessSolutionMutationVariables = {
  input: UpdateBusinessSolutionInput,
  condition?: ModelBusinessSolutionConditionInput | null,
};

export type UpdateBusinessSolutionMutation = {
  updateBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessSolutionMutationVariables = {
  input: DeleteBusinessSolutionInput,
  condition?: ModelBusinessSolutionConditionInput | null,
};

export type DeleteBusinessSolutionMutation = {
  deleteBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessFaqMutationVariables = {
  input: CreateBusinessFaqInput,
  condition?: ModelBusinessFaqConditionInput | null,
};

export type CreateBusinessFaqMutation = {
  createBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessFaqMutationVariables = {
  input: UpdateBusinessFaqInput,
  condition?: ModelBusinessFaqConditionInput | null,
};

export type UpdateBusinessFaqMutation = {
  updateBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessFaqMutationVariables = {
  input: DeleteBusinessFaqInput,
  condition?: ModelBusinessFaqConditionInput | null,
};

export type DeleteBusinessFaqMutation = {
  deleteBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessFeatureMutationVariables = {
  input: CreateBusinessFeatureInput,
  condition?: ModelBusinessFeatureConditionInput | null,
};

export type CreateBusinessFeatureMutation = {
  createBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessFeatureMutationVariables = {
  input: UpdateBusinessFeatureInput,
  condition?: ModelBusinessFeatureConditionInput | null,
};

export type UpdateBusinessFeatureMutation = {
  updateBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessFeatureMutationVariables = {
  input: DeleteBusinessFeatureInput,
  condition?: ModelBusinessFeatureConditionInput | null,
};

export type DeleteBusinessFeatureMutation = {
  deleteBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessPartnerMutationVariables = {
  input: CreateBusinessPartnerInput,
  condition?: ModelBusinessPartnerConditionInput | null,
};

export type CreateBusinessPartnerMutation = {
  createBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessPartnerMutationVariables = {
  input: UpdateBusinessPartnerInput,
  condition?: ModelBusinessPartnerConditionInput | null,
};

export type UpdateBusinessPartnerMutation = {
  updateBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessPartnerMutationVariables = {
  input: DeleteBusinessPartnerInput,
  condition?: ModelBusinessPartnerConditionInput | null,
};

export type DeleteBusinessPartnerMutation = {
  deleteBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessStepMutationVariables = {
  input: CreateBusinessStepInput,
  condition?: ModelBusinessStepConditionInput | null,
};

export type CreateBusinessStepMutation = {
  createBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessStepMutationVariables = {
  input: UpdateBusinessStepInput,
  condition?: ModelBusinessStepConditionInput | null,
};

export type UpdateBusinessStepMutation = {
  updateBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessStepMutationVariables = {
  input: DeleteBusinessStepInput,
  condition?: ModelBusinessStepConditionInput | null,
};

export type DeleteBusinessStepMutation = {
  deleteBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessTestimonialMutationVariables = {
  input: CreateBusinessTestimonialInput,
  condition?: ModelBusinessTestimonialConditionInput | null,
};

export type CreateBusinessTestimonialMutation = {
  createBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessTestimonialMutationVariables = {
  input: UpdateBusinessTestimonialInput,
  condition?: ModelBusinessTestimonialConditionInput | null,
};

export type UpdateBusinessTestimonialMutation = {
  updateBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessTestimonialMutationVariables = {
  input: DeleteBusinessTestimonialInput,
  condition?: ModelBusinessTestimonialConditionInput | null,
};

export type DeleteBusinessTestimonialMutation = {
  deleteBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSiteMutationVariables = {
  input: CreateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type CreateSiteMutation = {
  createSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSiteMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSiteMutation = {
  updateSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSiteMutationVariables = {
  input: DeleteSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type DeleteSiteMutation = {
  deleteSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSiteDeploymentMutationVariables = {
  input: CreateSiteDeploymentInput,
  condition?: ModelSiteDeploymentConditionInput | null,
};

export type CreateSiteDeploymentMutation = {
  createSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSiteDeploymentMutationVariables = {
  input: UpdateSiteDeploymentInput,
  condition?: ModelSiteDeploymentConditionInput | null,
};

export type UpdateSiteDeploymentMutation = {
  updateSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSiteDeploymentMutationVariables = {
  input: DeleteSiteDeploymentInput,
  condition?: ModelSiteDeploymentConditionInput | null,
};

export type DeleteSiteDeploymentMutation = {
  deleteSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerPersonaMutationVariables = {
  input: CreateCustomerPersonaInput,
  condition?: ModelCustomerPersonaConditionInput | null,
};

export type CreateCustomerPersonaMutation = {
  createCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerPersonaMutationVariables = {
  input: UpdateCustomerPersonaInput,
  condition?: ModelCustomerPersonaConditionInput | null,
};

export type UpdateCustomerPersonaMutation = {
  updateCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerPersonaMutationVariables = {
  input: DeleteCustomerPersonaInput,
  condition?: ModelCustomerPersonaConditionInput | null,
};

export type DeleteCustomerPersonaMutation = {
  deleteCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLeadMutationVariables = {
  input: CreateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CreateLeadMutation = {
  createLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLeadMutationVariables = {
  input: UpdateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type UpdateLeadMutation = {
  updateLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLeadMutationVariables = {
  input: DeleteLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type DeleteLeadMutation = {
  deleteLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClosedInfoMutationVariables = {
  input: CreateClosedInfoInput,
  condition?: ModelClosedInfoConditionInput | null,
};

export type CreateClosedInfoMutation = {
  createClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateClosedInfoMutationVariables = {
  input: UpdateClosedInfoInput,
  condition?: ModelClosedInfoConditionInput | null,
};

export type UpdateClosedInfoMutation = {
  updateClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteClosedInfoMutationVariables = {
  input: DeleteClosedInfoInput,
  condition?: ModelClosedInfoConditionInput | null,
};

export type DeleteClosedInfoMutation = {
  deleteClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMarketingCampaignMutationVariables = {
  input: CreateMarketingCampaignInput,
  condition?: ModelMarketingCampaignConditionInput | null,
};

export type CreateMarketingCampaignMutation = {
  createMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type UpdateMarketingCampaignMutationVariables = {
  input: UpdateMarketingCampaignInput,
  condition?: ModelMarketingCampaignConditionInput | null,
};

export type UpdateMarketingCampaignMutation = {
  updateMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type DeleteMarketingCampaignMutationVariables = {
  input: DeleteMarketingCampaignInput,
  condition?: ModelMarketingCampaignConditionInput | null,
};

export type DeleteMarketingCampaignMutation = {
  deleteMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type CreateMarketingCampaignRunMutationVariables = {
  input: CreateMarketingCampaignRunInput,
  condition?: ModelMarketingCampaignRunConditionInput | null,
};

export type CreateMarketingCampaignRunMutation = {
  createMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMarketingCampaignRunMutationVariables = {
  input: UpdateMarketingCampaignRunInput,
  condition?: ModelMarketingCampaignRunConditionInput | null,
};

export type UpdateMarketingCampaignRunMutation = {
  updateMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMarketingCampaignRunMutationVariables = {
  input: DeleteMarketingCampaignRunInput,
  condition?: ModelMarketingCampaignRunConditionInput | null,
};

export type DeleteMarketingCampaignRunMutation = {
  deleteMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBalanceEntryMutationVariables = {
  input: CreateBalanceEntryInput,
  condition?: ModelBalanceEntryConditionInput | null,
};

export type CreateBalanceEntryMutation = {
  createBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBalanceEntryMutationVariables = {
  input: UpdateBalanceEntryInput,
  condition?: ModelBalanceEntryConditionInput | null,
};

export type UpdateBalanceEntryMutation = {
  updateBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBalanceEntryMutationVariables = {
  input: DeleteBalanceEntryInput,
  condition?: ModelBalanceEntryConditionInput | null,
};

export type DeleteBalanceEntryMutation = {
  deleteBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerPersonaInterestsMutationVariables = {
  input: CreateCustomerPersonaInterestsInput,
  condition?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type CreateCustomerPersonaInterestsMutation = {
  createCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerPersonaInterestsMutationVariables = {
  input: UpdateCustomerPersonaInterestsInput,
  condition?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type UpdateCustomerPersonaInterestsMutation = {
  updateCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerPersonaInterestsMutationVariables = {
  input: DeleteCustomerPersonaInterestsInput,
  condition?: ModelCustomerPersonaInterestsConditionInput | null,
};

export type DeleteCustomerPersonaInterestsMutation = {
  deleteCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerPersonaLocationsMutationVariables = {
  input: CreateCustomerPersonaLocationsInput,
  condition?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type CreateCustomerPersonaLocationsMutation = {
  createCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerPersonaLocationsMutationVariables = {
  input: UpdateCustomerPersonaLocationsInput,
  condition?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type UpdateCustomerPersonaLocationsMutation = {
  updateCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerPersonaLocationsMutationVariables = {
  input: DeleteCustomerPersonaLocationsInput,
  condition?: ModelCustomerPersonaLocationsConditionInput | null,
};

export type DeleteCustomerPersonaLocationsMutation = {
  deleteCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetBusinessQueryVariables = {
  id: string,
};

export type GetBusinessQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBusinessesQueryVariables = {
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessesQuery = {
  listBusinesses?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      name?: string | null,
      onboardingStatus: OnboardingStatus,
      brands?:  {
        __typename: "ModelBrandConnection",
        nextToken?: string | null,
      } | null,
      customerPersonas?:  {
        __typename: "ModelCustomerPersonaConnection",
        nextToken?: string | null,
      } | null,
      sites?:  {
        __typename: "ModelSiteConnection",
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      solutions?:  {
        __typename: "ModelBusinessSolutionConnection",
        nextToken?: string | null,
      } | null,
      faqs?:  {
        __typename: "ModelBusinessFaqConnection",
        nextToken?: string | null,
      } | null,
      features?:  {
        __typename: "ModelBusinessFeatureConnection",
        nextToken?: string | null,
      } | null,
      partners?:  {
        __typename: "ModelBusinessPartnerConnection",
        nextToken?: string | null,
      } | null,
      businessSteps?:  {
        __typename: "ModelBusinessStepConnection",
        nextToken?: string | null,
      } | null,
      testimonials?:  {
        __typename: "ModelBusinessTestimonialConnection",
        nextToken?: string | null,
      } | null,
      tutorial?:  {
        __typename: "Tutorial",
        closed?: boolean | null,
        productsOrSolutions?: string | null,
        steps?: string | null,
        testimonial?: string | null,
        domain?: string | null,
        siteTest?: string | null,
        campaign?: string | null,
      } | null,
      editors?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      businessId: string,
      name?: string | null,
      briefDescription?: string | null,
      longDescription?: string | null,
      keywords?: Array< string | null > | null,
      logo?: string | null,
      icon?: string | null,
      coverImage?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      businessId: string,
      name?: string | null,
      price?: string | null,
      description?: string | null,
      coverImage?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessSolutionQueryVariables = {
  id: string,
};

export type GetBusinessSolutionQuery = {
  getBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessSolutionsQueryVariables = {
  filter?: ModelBusinessSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessSolutionsQuery = {
  listBusinessSolutions?:  {
    __typename: "ModelBusinessSolutionConnection",
    items:  Array< {
      __typename: "BusinessSolution",
      id: string,
      businessId: string,
      name?: string | null,
      description?: string | null,
      coverImage?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessFaqQueryVariables = {
  id: string,
};

export type GetBusinessFaqQuery = {
  getBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessFaqsQueryVariables = {
  filter?: ModelBusinessFaqFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessFaqsQuery = {
  listBusinessFaqs?:  {
    __typename: "ModelBusinessFaqConnection",
    items:  Array< {
      __typename: "BusinessFaq",
      id: string,
      businessId: string,
      name?: string | null,
      description?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessFeatureQueryVariables = {
  id: string,
};

export type GetBusinessFeatureQuery = {
  getBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessFeaturesQueryVariables = {
  filter?: ModelBusinessFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessFeaturesQuery = {
  listBusinessFeatures?:  {
    __typename: "ModelBusinessFeatureConnection",
    items:  Array< {
      __typename: "BusinessFeature",
      id: string,
      businessId: string,
      name?: string | null,
      description?: string | null,
      coverImage?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessPartnerQueryVariables = {
  id: string,
};

export type GetBusinessPartnerQuery = {
  getBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessPartnersQueryVariables = {
  filter?: ModelBusinessPartnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessPartnersQuery = {
  listBusinessPartners?:  {
    __typename: "ModelBusinessPartnerConnection",
    items:  Array< {
      __typename: "BusinessPartner",
      id: string,
      businessId: string,
      name?: string | null,
      description?: string | null,
      coverImage?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessStepQueryVariables = {
  id: string,
};

export type GetBusinessStepQuery = {
  getBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessStepsQueryVariables = {
  filter?: ModelBusinessStepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessStepsQuery = {
  listBusinessSteps?:  {
    __typename: "ModelBusinessStepConnection",
    items:  Array< {
      __typename: "BusinessStep",
      id: string,
      businessId: string,
      name?: string | null,
      description?: string | null,
      coverImage?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessTestimonialQueryVariables = {
  id: string,
};

export type GetBusinessTestimonialQuery = {
  getBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessTestimonialsQueryVariables = {
  filter?: ModelBusinessTestimonialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessTestimonialsQuery = {
  listBusinessTestimonials?:  {
    __typename: "ModelBusinessTestimonialConnection",
    items:  Array< {
      __typename: "BusinessTestimonial",
      id: string,
      businessId: string,
      who?: string | null,
      testimonial?: string | null,
      avatar?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSiteQueryVariables = {
  id: string,
};

export type GetSiteQuery = {
  getSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSitesQuery = {
  listSites?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSiteDeploymentQueryVariables = {
  id: string,
};

export type GetSiteDeploymentQuery = {
  getSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSiteDeploymentsQueryVariables = {
  filter?: ModelSiteDeploymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSiteDeploymentsQuery = {
  listSiteDeployments?:  {
    __typename: "ModelSiteDeploymentConnection",
    items:  Array< {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerPersonaQueryVariables = {
  id: string,
};

export type GetCustomerPersonaQuery = {
  getCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomerPersonasQueryVariables = {
  filter?: ModelCustomerPersonaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerPersonasQuery = {
  listCustomerPersonas?:  {
    __typename: "ModelCustomerPersonaConnection",
    items:  Array< {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInterestsQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterestsQuery = {
  listInterests?:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InterestsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InterestsByNameQuery = {
  interestsByName?:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGeoLocationsQueryVariables = {
  filter?: ModelGeoLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGeoLocationsQuery = {
  listGeoLocations?:  {
    __typename: "ModelGeoLocationConnection",
    items:  Array< {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GeoLocationsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGeoLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GeoLocationsByNameQuery = {
  geoLocationsByName?:  {
    __typename: "ModelGeoLocationConnection",
    items:  Array< {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLeadQueryVariables = {
  id: string,
};

export type GetLeadQuery = {
  getLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLeadsQueryVariables = {
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeadsQuery = {
  listLeads?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      siteId: string,
      forwardTo?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      phone?: string | null,
      location?: string | null,
      fromEmail?: string | null,
      form?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClosedInfoQueryVariables = {
  id: string,
};

export type GetClosedInfoQuery = {
  getClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListClosedInfosQueryVariables = {
  filter?: ModelClosedInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClosedInfosQuery = {
  listClosedInfos?:  {
    __typename: "ModelClosedInfoConnection",
    items:  Array< {
      __typename: "ClosedInfo",
      id: string,
      code: string,
      closed?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketingCampaignQueryVariables = {
  id: string,
};

export type GetMarketingCampaignQuery = {
  getMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type ListMarketingCampaignsQueryVariables = {
  filter?: ModelMarketingCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketingCampaignsQuery = {
  listMarketingCampaigns?:  {
    __typename: "ModelMarketingCampaignConnection",
    items:  Array< {
      __typename: "MarketingCampaign",
      id: string,
      businessId: string,
      customerPersona:  {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      site:  {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      objective: MarketingCampaignObjective,
      provider: MarketingCampaignProvider,
      runs?:  {
        __typename: "ModelMarketingCampaignRunConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      marketingCampaignCustomerPersonaId: string,
      marketingCampaignSiteId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketingCampaignRunQueryVariables = {
  id: string,
};

export type GetMarketingCampaignRunQuery = {
  getMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMarketingCampaignRunsQueryVariables = {
  filter?: ModelMarketingCampaignRunFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketingCampaignRunsQuery = {
  listMarketingCampaignRuns?:  {
    __typename: "ModelMarketingCampaignRunConnection",
    items:  Array< {
      __typename: "MarketingCampaignRun",
      id: string,
      marketingCampaignId: string,
      startDate: string,
      endDate?: string | null,
      dailyBudget: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBalanceEntryQueryVariables = {
  id: string,
};

export type GetBalanceEntryQuery = {
  getBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBalanceEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelBalanceEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBalanceEntriesQuery = {
  listBalanceEntries?:  {
    __typename: "ModelBalanceEntryConnection",
    items:  Array< {
      __typename: "BalanceEntry",
      id: string,
      origin: string,
      entryType: BalanceEntryType,
      businessId: string,
      currency: string,
      amount: number,
      sign: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerPersonaInterestsQueryVariables = {
  id: string,
};

export type GetCustomerPersonaInterestsQuery = {
  getCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomerPersonaInterestsQueryVariables = {
  filter?: ModelCustomerPersonaInterestsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerPersonaInterestsQuery = {
  listCustomerPersonaInterests?:  {
    __typename: "ModelCustomerPersonaInterestsConnection",
    items:  Array< {
      __typename: "CustomerPersonaInterests",
      id: string,
      customerPersonaID: string,
      interestID: string,
      customerPersona:  {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      interest:  {
        __typename: "Interest",
        name: string,
        googleAffinityAudiences?: Array< string | null > | null,
        googleInMarketAudiences?: Array< string | null > | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerPersonaLocationsQueryVariables = {
  id: string,
};

export type GetCustomerPersonaLocationsQuery = {
  getCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomerPersonaLocationsQueryVariables = {
  filter?: ModelCustomerPersonaLocationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerPersonaLocationsQuery = {
  listCustomerPersonaLocations?:  {
    __typename: "ModelCustomerPersonaLocationsConnection",
    items:  Array< {
      __typename: "CustomerPersonaLocations",
      id: string,
      customerPersonaID: string,
      geoLocationID: string,
      customerPersona:  {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      },
      geoLocation:  {
        __typename: "GeoLocation",
        name: string,
        targetType: string,
        countryCode: string,
        googleAdsGeoCriteria: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateSideDeploymentByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateSideDeploymentByIdSubscription = {
  onUpdateSideDeploymentById?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateBusinessSubscription = {
  onCreateBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateBusinessSubscription = {
  onUpdateBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBusinessSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteBusinessSubscription = {
  onDeleteBusiness?:  {
    __typename: "Business",
    id: string,
    name?: string | null,
    onboardingStatus: OnboardingStatus,
    brands?:  {
      __typename: "ModelBrandConnection",
      items:  Array< {
        __typename: "Brand",
        id: string,
        businessId: string,
        name?: string | null,
        briefDescription?: string | null,
        longDescription?: string | null,
        keywords?: Array< string | null > | null,
        logo?: string | null,
        icon?: string | null,
        coverImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerPersonas?:  {
      __typename: "ModelCustomerPersonaConnection",
      items:  Array< {
        __typename: "CustomerPersona",
        id: string,
        businessId: string,
        name?: string | null,
        age?: Array< string | null > | null,
        gender?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sites?:  {
      __typename: "ModelSiteConnection",
      items:  Array< {
        __typename: "Site",
        id: string,
        businessId: string,
        name?: string | null,
        lastDeploymentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        businessId: string,
        name?: string | null,
        price?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    solutions?:  {
      __typename: "ModelBusinessSolutionConnection",
      items:  Array< {
        __typename: "BusinessSolution",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    faqs?:  {
      __typename: "ModelBusinessFaqConnection",
      items:  Array< {
        __typename: "BusinessFaq",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    features?:  {
      __typename: "ModelBusinessFeatureConnection",
      items:  Array< {
        __typename: "BusinessFeature",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    partners?:  {
      __typename: "ModelBusinessPartnerConnection",
      items:  Array< {
        __typename: "BusinessPartner",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    businessSteps?:  {
      __typename: "ModelBusinessStepConnection",
      items:  Array< {
        __typename: "BusinessStep",
        id: string,
        businessId: string,
        name?: string | null,
        description?: string | null,
        coverImage?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    testimonials?:  {
      __typename: "ModelBusinessTestimonialConnection",
      items:  Array< {
        __typename: "BusinessTestimonial",
        id: string,
        businessId: string,
        who?: string | null,
        testimonial?: string | null,
        avatar?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tutorial?:  {
      __typename: "Tutorial",
      closed?: boolean | null,
      productsOrSolutions?: string | null,
      steps?: string | null,
      testimonial?: string | null,
      domain?: string | null,
      siteTest?: string | null,
      campaign?: string | null,
    } | null,
    editors?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    businessId: string,
    name?: string | null,
    briefDescription?: string | null,
    longDescription?: string | null,
    keywords?: Array< string | null > | null,
    logo?: string | null,
    icon?: string | null,
    coverImage?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    name?: string | null,
    price?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessSolutionSubscription = {
  onCreateBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessSolutionSubscription = {
  onUpdateBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessSolutionSubscription = {
  onDeleteBusinessSolution?:  {
    __typename: "BusinessSolution",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessFaqSubscription = {
  onCreateBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessFaqSubscription = {
  onUpdateBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessFaqSubscription = {
  onDeleteBusinessFaq?:  {
    __typename: "BusinessFaq",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessFeatureSubscription = {
  onCreateBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessFeatureSubscription = {
  onUpdateBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessFeatureSubscription = {
  onDeleteBusinessFeature?:  {
    __typename: "BusinessFeature",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessPartnerSubscription = {
  onCreateBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessPartnerSubscription = {
  onUpdateBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessPartnerSubscription = {
  onDeleteBusinessPartner?:  {
    __typename: "BusinessPartner",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessStepSubscription = {
  onCreateBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessStepSubscription = {
  onUpdateBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessStepSubscription = {
  onDeleteBusinessStep?:  {
    __typename: "BusinessStep",
    id: string,
    businessId: string,
    name?: string | null,
    description?: string | null,
    coverImage?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessTestimonialSubscription = {
  onCreateBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessTestimonialSubscription = {
  onUpdateBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessTestimonialSubscription = {
  onDeleteBusinessTestimonial?:  {
    __typename: "BusinessTestimonial",
    id: string,
    businessId: string,
    who?: string | null,
    testimonial?: string | null,
    avatar?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSiteSubscription = {
  onCreateSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSiteSubscription = {
  onUpdateSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSiteSubscription = {
  onDeleteSite?:  {
    __typename: "Site",
    id: string,
    businessId: string,
    name?: string | null,
    live?:  {
      __typename: "SiteData",
      netlifySiteId?: string | null,
      netlifyDeploymentId?: string | null,
      url?: string | null,
      meta?:  {
        __typename: "SiteMeta",
        googleAdsAccount?: string | null,
      } | null,
      functions?:  {
        __typename: "SiteFunction",
        actions?: Array< SiteAction | null > | null,
      } | null,
      gtmTag?: string | null,
      styling?:  {
        __typename: "SiteStyling",
        primaryText?: string | null,
        buttons?: string | null,
        header?: string | null,
        footerBackground?: string | null,
        footerText?: string | null,
        background?: string | null,
        tilesBackground?: string | null,
      } | null,
      layout?:  {
        __typename: "SiteLayout",
        siteTitle?: string | null,
        sectionTitles?: boolean | null,
        headerType?: string | null,
        contentAlign?: string | null,
        buttonShape?: string | null,
        density?: string | null,
        font?: string | null,
      } | null,
    } | null,
    lastDeploymentId?: string | null,
    lastDeployment?:  {
      __typename: "SiteDeployment",
      id: string,
      siteId: string,
      deploymentStatus: DeploymentStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSiteDeploymentSubscription = {
  onCreateSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSiteDeploymentSubscription = {
  onUpdateSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSiteDeploymentSubscription = {
  onDeleteSiteDeployment?:  {
    __typename: "SiteDeployment",
    id: string,
    siteId: string,
    deploymentStatus: DeploymentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerPersonaSubscription = {
  onCreateCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerPersonaSubscription = {
  onUpdateCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerPersonaSubscription = {
  onDeleteCustomerPersona?:  {
    __typename: "CustomerPersona",
    id: string,
    businessId: string,
    name?: string | null,
    age?: Array< string | null > | null,
    gender?: Array< string | null > | null,
    interests?:  {
      __typename: "ModelCustomerPersonaInterestsConnection",
      items:  Array< {
        __typename: "CustomerPersonaInterests",
        id: string,
        customerPersonaID: string,
        interestID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelCustomerPersonaLocationsConnection",
      items:  Array< {
        __typename: "CustomerPersonaLocations",
        id: string,
        customerPersonaID: string,
        geoLocationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLeadSubscription = {
  onCreateLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLeadSubscription = {
  onUpdateLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLeadSubscription = {
  onDeleteLead?:  {
    __typename: "Lead",
    id: string,
    siteId: string,
    forwardTo?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phone?: string | null,
    location?: string | null,
    fromEmail?: string | null,
    form?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClosedInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateClosedInfoSubscription = {
  onCreateClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateClosedInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateClosedInfoSubscription = {
  onUpdateClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteClosedInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteClosedInfoSubscription = {
  onDeleteClosedInfo?:  {
    __typename: "ClosedInfo",
    id: string,
    code: string,
    closed?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMarketingCampaignSubscription = {
  onCreateMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type OnUpdateMarketingCampaignSubscription = {
  onUpdateMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type OnDeleteMarketingCampaignSubscription = {
  onDeleteMarketingCampaign?:  {
    __typename: "MarketingCampaign",
    id: string,
    businessId: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    site:  {
      __typename: "Site",
      id: string,
      businessId: string,
      name?: string | null,
      live?:  {
        __typename: "SiteData",
        netlifySiteId?: string | null,
        netlifyDeploymentId?: string | null,
        url?: string | null,
        gtmTag?: string | null,
      } | null,
      lastDeploymentId?: string | null,
      lastDeployment?:  {
        __typename: "SiteDeployment",
        id: string,
        siteId: string,
        deploymentStatus: DeploymentStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    objective: MarketingCampaignObjective,
    provider: MarketingCampaignProvider,
    runs?:  {
      __typename: "ModelMarketingCampaignRunConnection",
      items:  Array< {
        __typename: "MarketingCampaignRun",
        id: string,
        marketingCampaignId: string,
        startDate: string,
        endDate?: string | null,
        dailyBudget: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    marketingCampaignCustomerPersonaId: string,
    marketingCampaignSiteId: string,
  } | null,
};

export type OnCreateMarketingCampaignRunSubscription = {
  onCreateMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMarketingCampaignRunSubscription = {
  onUpdateMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMarketingCampaignRunSubscription = {
  onDeleteMarketingCampaignRun?:  {
    __typename: "MarketingCampaignRun",
    id: string,
    marketingCampaignId: string,
    startDate: string,
    endDate?: string | null,
    dailyBudget: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBalanceEntrySubscription = {
  onCreateBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBalanceEntrySubscription = {
  onUpdateBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBalanceEntrySubscription = {
  onDeleteBalanceEntry?:  {
    __typename: "BalanceEntry",
    id: string,
    origin: string,
    entryType: BalanceEntryType,
    businessId: string,
    currency: string,
    amount: number,
    sign: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerPersonaInterestsSubscription = {
  onCreateCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerPersonaInterestsSubscription = {
  onUpdateCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerPersonaInterestsSubscription = {
  onDeleteCustomerPersonaInterests?:  {
    __typename: "CustomerPersonaInterests",
    id: string,
    customerPersonaID: string,
    interestID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    interest:  {
      __typename: "Interest",
      name: string,
      googleAffinityAudiences?: Array< string | null > | null,
      googleInMarketAudiences?: Array< string | null > | null,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerPersonaLocationsSubscription = {
  onCreateCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerPersonaLocationsSubscription = {
  onUpdateCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerPersonaLocationsSubscription = {
  onDeleteCustomerPersonaLocations?:  {
    __typename: "CustomerPersonaLocations",
    id: string,
    customerPersonaID: string,
    geoLocationID: string,
    customerPersona:  {
      __typename: "CustomerPersona",
      id: string,
      businessId: string,
      name?: string | null,
      age?: Array< string | null > | null,
      gender?: Array< string | null > | null,
      interests?:  {
        __typename: "ModelCustomerPersonaInterestsConnection",
        nextToken?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    geoLocation:  {
      __typename: "GeoLocation",
      name: string,
      targetType: string,
      countryCode: string,
      googleAdsGeoCriteria: string,
      targetCustomers?:  {
        __typename: "ModelCustomerPersonaLocationsConnection",
        nextToken?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
