import {Box} from "@mui/material";
import * as React from 'react';
import Modal from '@mui/material/Modal';
import ButtonHolder from "../button-holder/ButtonHolder";
import style from "./ModalStyle";


const DeleteModal = (props: {
    open: boolean,
    text?: string,
    deleteSubmit: () => void,
    closeModal: () => void
}) => {
    return <Modal
        open={props.open}
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Box sx={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <Box id="modal-modal-description" sx={{
                        mt: 2,
                        fontSize: 'h6.fontSize',
                        fontFamily: 'h6.fontFamily',
                        textAlign: 'center'
                    }}>
                        {props.text || 'Are you sure you want to delete?'}
                    </Box>
                </Box>
                <Box>
                    <Box sx={{mb: 2}}/>
                    <ButtonHolder
                        justify={'center'}
                        textRight={"Delete"}
                        textLeft={"Close"}
                        submitRight={() => {
                            props.deleteSubmit();
                        }}
                        submitLeft={props.closeModal}
                    /></Box>
            </Box>
        </Box>
    </Modal>
}

export default DeleteModal