import {configureStore} from '@reduxjs/toolkit'
import business from "./features/business/business";
import brand from "./features/brand/brand";
import site from "./features/site/site";
import product from "./features/product/product";
import businessStep from "./features/businessStep/businessStep";
import businessSolution from "./features/businessSolution/businessSolution";
import businessPartner from "./features/businessPartner/businessPartner";
import businessFaq from "./features/businessFaq/businessFaq";
import businessFeature from "./features/businessFeature/businessFeature";
import businessTestimonial from "./features/businessTestimonial/businessTestimonial";
import customerPersona from "./features/customerPersona/customerPersona";
import closedInfo from "./features/closeInfo/closeInfo";
import marketingCampaign from "./features/marketingCampaign/marketingCampaign";
import budget from "./features/budget/budget";
import leadStatistics from "./features/leads/leads";

const store = configureStore({
    reducer: {
        business,
        brand,
        customerPersona,
        site,
        product,
        businessStep,
        businessSolution,
        businessPartner,
        businessFaq,
        businessFeature,
        businessTestimonial,
        closedInfo,
        marketingCampaign,
        budget,
        leadStatistics
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;