import Card from "@mui/material/Card";
import * as React from "react";
import {Box, Button, CardActionArea, CardContent, CardHeader, Divider, Menu, Typography} from "@mui/material";
import {MarketingCampaign, MarketingCampaignRun} from "../../../API";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";

type Props = {
    marketingCampaign: MarketingCampaign,
    onStart?: (id: string) => void,
    onStop?: (id: string) => void,
};


const MarketingCard = ({marketingCampaign, onStart, onStop}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const latestRun = () => {
        return marketingCampaign.runs?.items?.sort((a: MarketingCampaignRun | null, b: MarketingCampaignRun | null) => {
            if (a?.startDate && b?.startDate) {
                return a.startDate.localeCompare(b.startDate)
            }
            return 0
        })?.[0]
    }

    return <CardActionArea sx={{
        borderRadius: '16px'
    }}>
        <Card sx={{
            minWidth: '232px',
            height: {
                xs: '180px',
                lg: '224px',
            },

            borderRadius: "16px",
            border: '1px',
            borderStyle: 'solid',
            borderColor: '#D4D4D8',
            boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)',
            boxSizing: 'border-box',
            textAlign: 'left'

        }}

        >
            <CardHeader
                title={'Leads'}
                action={<div>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{p: 0, m: 0}}
                    >
                        <MoreVertIcon sx={{color: 'common.black',}}/>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {onStart && <MenuItem onClick={() => {
                            onStart(marketingCampaign.id);
                            handleClose();
                        }
                        }>Start
                        </MenuItem>}
                        {onStop && <MenuItem onClick={() => {
                            onStop(marketingCampaign.id);
                            handleClose();
                        }
                        }>Stop
                        </MenuItem>}
                    </Menu>
                </div>}
            />
            <CardContent sx={{
                height: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    mb: 1
                }}>
                    <Box>
                        <Typography variant="subtitle2">
                            {"Sending " +
                                marketingCampaign?.customerPersona?.name +
                                " to " +
                                marketingCampaign?.site?.live?.url
                            }
                        </Typography>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    columnGap: 3,
                    rowGap: 2,
                    mt: 2
                }}>
                    <Box>
                        <Typography variant="h5">
                            Starts
                        </Typography>
                        <Typography variant="h6">
                            {latestRun()?.startDate || "-"}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">
                            Ends
                        </Typography>
                        <Typography variant="h6">
                            {latestRun()?.endDate || "-"}
                        </Typography>
                    </Box>

                </Box>
            </CardContent>
        </Card>
    </CardActionArea>

}

export default MarketingCard