import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton';
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
    title: string,
    backTo?: string,
    underTitle?: JSX.Element,
    actionSection?: JSX.Element,
};

const DashboardHeader = ({ title, backTo, underTitle, actionSection }: Props) => {
    const navigate = useNavigate();
    return <Stack mb={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between"
        >
            <Stack justifyContent="flex-start" alignItems="flex-start" textAlign='left'>
                {backTo ?
                    <IconButton onClick={() => navigate(backTo)} size="large" edge='start' color="primary">
                        <ArrowBackIcon />
                    </IconButton>
                    : null
                }
                <Typography variant="h1">
                    {title}
                </Typography>


            </Stack>
            <Stack>
                {actionSection}
            </Stack>

        </Stack>

        <Typography variant="h5">
            {underTitle}
        </Typography>
    </Stack>
}

export default DashboardHeader