import moment from "moment";
import Typography from "@mui/material/Typography";

type CostSummaryProps = {
    startDate?: string,
    endDate?: string | null,
    dailyBudget?: number
}
const CampaignCost = ({startDate, endDate, dailyBudget}: CostSummaryProps) => {
    const cost = () => {
        if (startDate && endDate && dailyBudget)
            return moment(endDate).diff(moment(startDate), 'days') * dailyBudget
        return '...'
    }

    return <Typography
        variant={'subtitle1'}
        sx={{mb: 2}}
    >
        Approximate cost of the campaign is: {cost()}$
    </Typography>
}
export default CampaignCost