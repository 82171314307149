import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {updateBusinessAction} from "../../../../redux/features/business/businessActions";
import {OnboardingStatus} from "../../../../API";
import {selectedSite} from "../../../../redux/features/site/site";
import {getSites} from "../../../../redux/features/site/siteActions";
import OnboardingSiteForm from "../../../molecules/sites-form/OnboardingSiteForm";

const OnBoardingSites = () => {
    const navigate = useNavigate();
    const site = useSelector(selectedSite);
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business)
            dispatch(getSites(business.id))
    }, [dispatch, business])

    const afterSubmitNext = () => {
        // dispatch(deployAction(business,
        //     selected,
        //     'live'
        // ))
        // dispatch(updateBusinessAction(business.id, OnboardingStatus.Campaign))
        navigate('/onboarding/campaign')
    }

    const afterSubmitBack = () => {
        if (business) {
            dispatch(updateBusinessAction(business.id, OnboardingStatus.Customers))
            navigate('/onboarding/customers')
        }
    }

    return <OnboardingSiteForm site={site}
                               onboarding={true}
                               afterSubmitNext={afterSubmitNext}
                               afterSubmitBack={afterSubmitBack}
                               defaultName={'Primary'}/>
}

export default OnBoardingSites;