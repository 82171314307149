import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ClosedInfo} from "../../../API";
import {RootState} from "../../store";

export interface ClosedInfoState {
    isFetching: boolean,
    closedInfos?: ClosedInfo[]
}

const initialState: ClosedInfoState = {
    isFetching: false
}


export const closedInfoSlice = createSlice({
    name: 'closedInfo',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<ClosedInfo[]>) => {
            state.closedInfos = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, isFetching, fetched} = closedInfoSlice.actions
export const allClosedInfos = (state: RootState) => state.closedInfo.closedInfos

export default closedInfoSlice.reducer