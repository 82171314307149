import {API, graphqlOperation} from "aws-amplify";
import {memoize} from "lodash";
import {listGeoLocations, listInterests} from "../../../graphql/queries";

const fetchLocations = async (filter: string) => {
    let nextToken: string | null = null;
    // @ts-ignore
    let items = [];
    do {
        // @ts-ignore
        let [newItems, newNextToken] = await fetchLocationsPage(filter, nextToken)
        nextToken = newNextToken;
        // @ts-ignore
        items.push(...newItems)
    } while (nextToken)
    return items
}

const fetchLocationsPage = async (filter: string, nextToken: string | null) => {
    const options = await API.graphql(graphqlOperation(listGeoLocations, {
        filter: {
            name: {contains: filter.toLowerCase()}
        },
        limit: 1000,
        nextToken
    }))
    // @ts-ignore
    const newItems = options?.data?.listGeoLocations.items;
    // @ts-ignore
    let newNextToken = options?.data?.listGeoLocations.nextToken as string | null;
    return [newItems, newNextToken]
}

const fetchInterests = async (filter: string) => {
    const options = await API.graphql(graphqlOperation(listInterests, {
        filter: {
            name: {beginsWith: filter}
        },
        sort: {
            direction: 'asc',
            field: 'name'
        }
    }))
    // @ts-ignore
    return options?.data?.listInterests.items
}

export const memorizedInterests = memoize(fetchInterests)
export const memorizedLocations = memoize(fetchLocations)