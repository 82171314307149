import Card from "@mui/material/Card";
import * as React from "react";
import { Chip, CardActionArea, CardContent, Typography } from "@mui/material";
import Stack from "@mui/material/Stack"

export enum CardState {
    Blank = "Blank",
    New = "New",
    ComingSoon = "Coming Soon",
}

type Props = {
    title: string,
    text: string,
    color?: string,
    status: CardState,
    onClick?: () => void
};


const ClickableCard = ({ title, text, status, color, onClick }: Props) => {

    const style = {
        clickableCard: {
            minWidth: '200px',
            height: {
                xs: '160px',
                lg: '196px',
            },
            backgroundColor: color || "white",
            border: '1px',
            borderRadius: 4,
            borderStyle: 'solid',
            borderColor: '#DADADA',
            boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)',
            boxSizing: 'border-box',
        },
        unclickableCard: {
            minWidth: '200px',
            height: {
                xs: '160px',
                lg: '196px',
            },
            borderRadius: 4,
            border: 1,
            backgroundColor: '#f5f5f5',
            borderStyle: 'solid',
            borderColor: '#DADADA',
            boxShadow: '2px 2px 16px rgba(0, 0, 0, 0.03)',
            boxSizing: 'border-box',
        }
    }

    return <CardActionArea sx={{
        borderRadius: 4,
    }}>
        <Card sx={status === CardState.ComingSoon ? style.unclickableCard : style.clickableCard}
            onClick={onClick}
        >
            <CardContent sx={{
                height: '100%',
            }}>
                <Stack
                    spacing={1}
                    sx={{ textAlign: "left" }}
                >
                    <Stack direction={"row"} justifyContent="space-between">
                        <Typography variant="h3">{title}</Typography>
                        {(status === CardState.Blank && <></>) ||
                            <Chip label={status} size="small" color={status === CardState.New ? "success" : "secondary"} variant="filled" />
                        }
                    </Stack>
                    <Typography variant="body1">{text}</Typography>
                </Stack>
            </CardContent>
        </Card>
    </CardActionArea>

}

export default ClickableCard