export const createCustomerPersonaLocationCustom = /* GraphQL */ `
    mutation CreateCustomerPersonaLocationCustom(
        $input: CreateCustomerPersonaLocationsInput!
        $condition: ModelCustomerPersonaLocationsConditionInput
    ) {
        createCustomerPersonaLocations(input: $input, condition: $condition) {
            id
            customerPersonaID
            geoLocationID
        }
    }
`;


export const deleteCustomerPersonaLocationsCustom = /* GraphQL */ `
    mutation DeleteCustomerPersonaLocationsCustom(
        $input: DeleteCustomerPersonaLocationsInput!
        $condition: ModelCustomerPersonaLocationsConditionInput
    ) {
        deleteCustomerPersonaLocations(input: $input, condition: $condition) {
            id
        }
    }
`;

export const createCustomerPersonaInterestsCustom = /* GraphQL */ `
    mutation CreateCustomerPersonaInterestsCustom(
        $input: CreateCustomerPersonaInterestsInput!
        $condition: ModelCustomerPersonaInterestsConditionInput
    ) {
        createCustomerPersonaInterests(input: $input, condition: $condition) {
            id
            customerPersonaID
            interestID
        }
    }
`;

export const deleteCustomerPersonaInterestsCustom = /* GraphQL */ `
    mutation DeleteCustomerPersonaInterestsCustom(
        $input: DeleteCustomerPersonaInterestsInput!
        $condition: ModelCustomerPersonaInterestsConditionInput
    ) {
        deleteCustomerPersonaInterests(input: $input, condition: $condition) {
            id
        }
    }
`;

export const createSiteCustom = /* GraphQL */ `
    mutation CreateSiteCustom(
        $input: CreateSiteInput!
        $condition: ModelSiteConditionInput
    ) {
        createSite(input: $input, condition: $condition) {
            id
            businessId
            name
            live {
                netlifySiteId
                netlifyDeploymentId
                url
                gtmTag
                functions {
                    actions
                    form {
                        disabled
                        buttonText
                        bottomHeader
                        forwardTo
                        title
                        emailHeader
                        emailHeaderEnabled
                        emailHeaderRequired
                        formHeader
                        formHeaderEnabled
                        formHeaderRequired
                        firstNameLabel
                        firstNameEnabled
                        firstNameRequired
                        lastNameLabel
                        lastNameEnabled
                        lastNameRequired
                        locationLabel
                        locationEnabled
                        locationRequired
                        phoneLabel
                        phoneEnabled
                        phoneRequired
                        dateLabel
                        dateEnabled
                        dateRequired
                        options
                        optionsLabel
                        optionsEnabled
                        optionsRequired
                        multiOptions
                        successMessage
                        redirectUrl
                        url
                    }
                    link {
                        enabled
                        redirectTo
                        buttonText
                    }
                    calendly {
                        enabled
                        redirectTo
                        buttonText
                    }
                    phone {
                            enabled
                            buttonText
                            redirectTo
                        }
                }
                styling {
                    primaryText
                    buttons
                    header
                    footerBackground
                    footerText
                    background
                    tilesBackground
                }
                layout {
                    siteTitle
                    sectionTitles
                    headerType
                    contentAlign
                    buttonShape
                    density
                }
            }
            lastDeploymentId
        }
    }
`;

export const updateSiteCustom = /* GraphQL */ `
    mutation UpdateSiteCustom(
        $input: UpdateSiteInput!
        $condition: ModelSiteConditionInput
    ) {
        updateSite(input: $input, condition: $condition) {
            id
            businessId
            name
            live {
                netlifySiteId
                netlifyDeploymentId
                url
                functions {
                    actions
                    form {
                        disabled
                        buttonText
                        bottomHeader
                        forwardTo
                        title
                        emailHeader
                        emailHeaderEnabled
                        emailHeaderRequired
                        formHeader
                        formHeaderEnabled
                        formHeaderRequired
                        firstNameLabel
                        firstNameEnabled
                        firstNameRequired
                        lastNameLabel
                        lastNameEnabled
                        lastNameRequired
                        locationLabel
                        locationEnabled
                        locationRequired
                        phoneLabel
                        phoneEnabled
                        phoneRequired
                        dateLabel
                        dateEnabled
                        dateRequired
                        options
                        optionsLabel
                        optionsEnabled
                        optionsRequired
                        multiOptions
                        successMessage
                        redirectUrl
                        url
                    }
                    link {
                        buttonText
                        redirectTo
                    }
                    calendly {
                        redirectTo
                        buttonText
                    }
                    phone {
                        redirectTo
                        buttonText
                    }
                }
                styling {
                    primaryText
                    buttons
                    header
                    footerBackground
                    footerText
                    background
                    tilesBackground
                }
                layout {
                    siteTitle
                    sectionTitles
                    headerType
                    contentAlign
                    buttonShape
                    density
                }
            }
            lastDeploymentId
            createdAt
            updatedAt
        }
    }
`;

export const updateSitLastDeployment = /* GraphQL */ `
    mutation UpdateSitLastDeployment(
        $input: UpdateSiteInput!
        $condition: ModelSiteConditionInput
    ) {
        updateSite(input: $input, condition: $condition) {
            id
            lastDeploymentId
        }
    }
`;