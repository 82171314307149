import Box from '@mui/material/Box';
import {ErrorMessage} from "formik";
import {ReactChild, ReactFragment, ReactPortal} from "react";
import {Stack} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
const FieldHorizontalWrapper = (props: {
    name: string,
    text: string,
    children: JSX.Element,
    noPaddingBottom?: boolean
    onDelete?: () => {}
}) => {

    return <Box sx={{
        mb: 2,
    }}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1} >
            <Box sx={{
                fontWeight: 'bold',
                color: 'primary.text',
                textAlign: {
                    xs: 'center',
                    sm: 'left'
                },
            }}>
                {props.text}
            </Box>
            {props.onDelete && <ClearIcon onClick={props.onDelete}/>}
        </Stack>
        <Box sx={{display: 'flex'}}>
            <Box sx={{flexGrow: 1}}>
                {props.children}

                <ErrorMessage name={props.name}>
                    {(message: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined) =>
                        <Box typography={'subtitle2'} sx={{
                            color: 'error.main'
                        }}>{message}</Box>}
                </ErrorMessage>
            </Box>
        </Box>
    </Box>

}

export default FieldHorizontalWrapper;