import { Site } from "../../../API";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedBusiness } from "../../../redux/features/business/business";
import { FormikErrors } from "formik/dist/types";
import ButtonHolder from "../../atoms/button-holder/ButtonHolder";
import { updateSiteAction } from "../../../redux/features/site/siteActions";
import { useState } from "react";
import WaitingModal from "../../atoms/modal/WaitingModal";
import { initialValues } from "../../../redux/features/site/initialValues";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ColorPickerField from "../../atoms/color-picker/ColorPickerField";
import LabeledCheckboxField from "../../atoms/labeled-field/LabeledCheckboxField";
import Divider from '@mui/material/Divider';
import LabeledRadioField from "../../atoms/labeled-field/LabeledRadioField"
import LabeledRadioFieldbuttonShape from "../../atoms/labeled-field/LabeledRadioFieldbuttonShape"
import LabeledRadioFieldLayoutDensity from "../../atoms/labeled-field/LabeledRadioFieldLayoutDensity"


const validationSchema = Yup.object({
    live: Yup.object().shape({
        styling: Yup.object().shape({
            primaryText: Yup.string().required(),
            buttons: Yup.string().required(),
            header: Yup.string().required(),
            footerBackground: Yup.string().required(),
            footerText: Yup.string().required(),
            background: Yup.string().required(),
            tilesBackground: Yup.string().required(),
        })
    })
})

type Props = {
    site?: Site,
    afterSubmit: () => void
};


const SiteStylingFields = (props: { businessId?: string, afterSubmit: () => void }) => {

    const { submitForm, setFieldTouched, validateForm } = useFormikContext();

    const handleSubmit = (): Promise<any | FormikErrors<any>> => {
        ['live',].forEach((field) => {
            setFieldTouched(field, true, true)
        })
        return validateForm()
            .then((errors) => {
                if (errors && Object.keys(errors).length === 0) {
                    return submitForm();
                }
                return errors
            });
    }
    return <Stack spacing={2} divider={<Divider flexItem />} textAlign="left">
        <Stack>
            <Typography variant="h3" textAlign="left">Layout</Typography>
            <Typography variant="body1" textAlign="left" color="gray">
                Customise how different elements appear on your site.</Typography>
            <Box sx={{ width: '100%', maxWidth: '380px', margin: 'auto', pt: 2 }}>
                <LabeledCheckboxField
                    key='sectionTitles'
                    name='live.layout.sectionTitles' text='Show/hide section titles'
                    placeholder=''
                />
                {/*Radio field components must be refactored into one.*/}
            <LabeledRadioFieldLayoutDensity
                    key='density'
                    name='live.layout.density' text='Select layout density'
                    placeholder=''
                />
                <LabeledRadioField
                    key='siteTitle'
                    name='live.layout.siteTitle' text='Select logo variant'
                    placeholder=''
                />
                <LabeledRadioFieldbuttonShape
                    key='buttonShape'
                    name='live.layout.buttonShape' text='Select button shape'
                    placeholder=''
                />
            </Box>
        </Stack>
        <Stack>
            <Typography variant="h3" textAlign="left">Colors</Typography>
            <Typography variant="body1" textAlign="left" color="gray">
                Customise the colors on your site.</Typography>
            <Box sx={{ width: '100%', maxWidth: '380px', margin: 'auto', py: 2 }}>
                <ColorPickerField
                    key='header'
                    name='live.styling.header' text='Header background' />
                <ColorPickerField
                    key='primaryText'
                    name='live.styling.primaryText' text='Primary text' />
                <ColorPickerField
                    key='buttons'
                    name='live.styling.buttons' text='Buttons' />
                <ColorPickerField
                    key='footerBackground'
                    name='live.styling.footerBackground' text='Footer background' />
                <ColorPickerField
                    key='footerText'
                    name='live.styling.footerText' text='Footer text' />
                <ColorPickerField
                    key='background'
                    name='live.styling.background' text='Background' />
                <ColorPickerField
                    key='tilesBackground'
                    name='live.styling.tilesBackground' text='Tile Background' />

            </Box>
            <ButtonHolder
                textRight={"Save"}
                textLeft={"Delete"}
                justify={'right'}
                submitRight={
                    () => {
                        handleSubmit()
                            .then((errors) => {
                                if (!errors)
                                    props.afterSubmit()
                            })
                    }} />
        </Stack>
    </Stack>

}


const SiteStylingForm = ({ site, afterSubmit }: Props) => {

    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => {
        setOpen(false);
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={site || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values: Site) => {
                openModal()
                if (business) {
                    try {
                        await dispatch(updateSiteAction(business.id, values))
                    } finally {
                        closeModal()
                        afterSubmit()
                    }
                }
            }}>
            <Form>
                <SiteStylingFields businessId={business?.id} afterSubmit={afterSubmit} />
            </Form>
        </Formik>
        {open && <WaitingModal text={'Please wait we are updating the site'} />}
    </>
}

export default SiteStylingForm