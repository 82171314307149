import * as React from 'react';
import {useEffect} from 'react';
import {Box} from "@mui/system";
import {Divider, Stack} from "@mui/material";
import OverviewCard from "../card/OverviewCard";
import {getBudget} from "../../../redux/features/budget/budgetAction";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../redux/features/business/business";
import {currentBudget} from "../../../redux/features/budget/budget";


type CellProps = {
    label: string,
    value: string | number
}


function Row({label, value}: CellProps) {
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 1
        }}
    >
        <Box sx={{
            fontSize: "body1.fontSize",
            fontFamily: "body1.fontFamily",
            color: '#71717A',
            textAlign: 'left',
            opacity: '100%'
        }}>
            {label}
        </Box>
        <Box sx={{
            fontSize: "body1.fontSize",
            fontFamily: "body1.fontFamily",
            color: 'common.black',
            textAlign: 'left',
            opacity: '100%'
        }}>
            {value}
        </Box>
    </Box>;
}

const BudgetCard = () => {
    const business = useSelector(selectedBusiness);
    const budget = useSelector(currentBudget);
    const dispatch = useDispatch();

    useEffect(() => {
            if (business)
                dispatch(getBudget(business.id))
        }
        , [business, dispatch])

    function getBalance() {
        if (budget)
            return "" + budget?.currency + " " + budget?.balance;
        return ""
    }

    return <OverviewCard
        title={'Wallet'}
        subtitle={`Check your budget and keep track of spending.`}
        link={"https://buy.stripe.com/5kAaGfaiP9aw0jS9AE"}
        child={
            <Stack>
                <Row label={'Balance'} value={getBalance()}/>
                <Divider/>
                <Row label={'Daily spend'} value={'-'}/>
                <Divider/>
                <Row label={'Approx. runway'} value={'-'}/>
            </Stack>}/>

}


export default BudgetCard;
