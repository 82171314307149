import * as React from 'react';
import {useEffect} from 'react';
import {Container} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectedBusiness} from "../../../../redux/features/business/business";
import {getBusinessAction} from "../../../../redux/features/business/businessActions";
import ClickableCard, {CardState} from "../../../atoms/card/ClickableCard";
import DashboardHeader from "../../../atoms/dashboard/DashboardHeader";
import {useNavigate} from "react-router-dom";
import CardContainer from "../../../atoms/card/CardContainer";
import {getCustomerPersonasAction} from "../../../../redux/features/customerPersona/customerPersonaActions";
import ClosableInfoCard from "../../../molecules/info-card/ClosableInfoCard";
import image from "../../../../images/illustrations/campaigns.png";

const tips = ['Bring customers to your website by launching a campaign in Google Ads.',
]

const CustomersPage = () => {
    const navigate = useNavigate();
    const business = useSelector(selectedBusiness)
    const dispatch = useDispatch();

    useEffect(() => {
        if (business) {
            dispatch(getCustomerPersonasAction(business.id))
        } else {
            dispatch(getBusinessAction())
        }
    }, [dispatch, business])


    return <Container>
        <DashboardHeader
            title={"Customers"}
            underTitle={
                <ClosableInfoCard
                    code={'CUSTOMER'}
                    header={"Getting more customers starts here."}
                    items={tips}
                    image={image}
                    buttonLink={'/dashboard/marketing-campaign'}
                    buttonText={'Create a campaign'}
                    textColor="#9f1239"
                    backgroundColor='#fecdd3'
                />}
        />
        <CardContainer
            xsColumns={1}
            smColumns={2}
            mdColumns={3}
            lgColumns={3}
            cards={
                [
                    <ClickableCard
                        title={"🧑‍🤝‍🧑 Customer personas"}
                        text={"Define audience signals to help your campaigns target the right people."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/customer-personas')}
                    />,
                    <ClickableCard
                        title={"📣 Campaigns"}
                        text={"Manage how you bring new visitors to your website."}
                        status={CardState.Blank}
                        onClick={() => navigate('/dashboard/marketing-campaign')}
                    />
                ]
            }/>

    </Container>
}


export default CustomersPage