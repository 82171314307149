import React from 'react';
import './App.css';
import {Amplify} from 'aws-amplify';
import {withAuthenticator} from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import EvergrowRouter from "./components/router/Router";
import LoginHeader from "./components/atoms/login-header/LoginHeader";
import EvergrowTheme from "./components/theme/EvergrowTheme";
import {CssBaseline} from "@mui/material";

import { hotjar } from 'react-hotjar';
Amplify.configure(awsExports);

function App() {
    if(process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_VERSION)
        hotjar.initialize(process.env.REACT_APP_HOTJAR_ID as unknown as number, process.env.REACT_APP_HOTJAR_VERSION as unknown as number);

    return (
        <div className="App">
            <EvergrowTheme>
                <CssBaseline />
                <EvergrowRouter/>
            </EvergrowTheme>
        </div>
    );
}

export default withAuthenticator(App, {
    components: {
        Header: LoginHeader,
    }
});