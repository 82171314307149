// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BusinessFeature} from "../../../API";
import {RootState} from "../../store";

interface BusinessFeatureState {
    isFetching: boolean,
    businessFeatures?: [BusinessFeature],
    selected?: string
}

const initialState: BusinessFeatureState = {
    isFetching: false,
}

const sort = (first, second) => first.order - second.order

export const businessFeatureSlice = createSlice({
    name: 'businessFeature',
    initialState,
    reducers: {
        updateAll: (state, action: PayloadAction<[BusinessFeature]>) => {
            state.businessFeatures = action.payload
                .sort(sort) || []
        },
        updateOne: (state, action: PayloadAction<BusinessFeature>) => {
            state.businessFeatures = state.businessFeatures
                .map((entry) => {
                    if (entry.id !== action.payload.id) {
                        return entry
                    } else {
                        return action.payload
                    }
                }).sort(sort)
        },
        addOne: (state, action: PayloadAction<BusinessFeature>) => {
            state.businessFeatures.push(action.payload)
            state.businessFeatures.sort(sort)
        },
        remove: (state, action: PayloadAction<string>) => {
            state.businessFeatures = state.businessFeatures
                .filter((entry) =>
                    entry.id !== action.payload
                ).sort(sort)
        },
        select: (state, action: PayloadAction<string>) => {
            state.selected = action.payload
        },
        isFetching: (state) => {
            state.isFetching = true
        },
        fetched: (state) => {
            state.isFetching = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {updateAll, updateOne, addOne, remove, select, isFetching, fetched} = businessFeatureSlice.actions
export const selectedBusinessFeature = (state: RootState) => state.businessFeature.selected
export const allBusinessFeatures = (state: RootState) => state.businessFeature.businessFeatures
export const businessFeaturesIsFetching = (state: RootState) => state.businessFeature.isFetching

export default businessFeatureSlice.reducer
